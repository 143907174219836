<template>
  <div>
    <div id="title" class="flex justify-between mt-4 mb-4">
      <p class="text-base text-blue-600"><slot name="title" /></p>
      <slot name="actions" />
    </div>

    <div id="content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeputationSection',
};
</script>
