<template>
  <view-wrapper>
    <template #headline>{{ $t('views.profiles-overview.headline') }}</template>
    <error-banner />
    <profiles-list-toolbar
      :user-can-trigger-import="userCanTriggerImport"
      :user-can-create-profiles="userCanCreateProfiles"
    />
    <profiles-list
      v-model:name="name"
      v-model:email="email"
      v-model:teachingLevels="teachingLevels"
      v-model:employmentType="employmentType"
      v-model:brands="brands"
      v-model:units="units"
      v-model:planningStatus="planningStatus"
      v-model:preferences="preferences"
      v-model:pagination="currentPagination"
      :is-loading="isLoading"
      :is-valid-search-query="isValidSearchQuery"
      :profiles="results"
      @update:name="onSubmit"
      @update:email="onSubmit"
      @update:teaching-levels="onSubmit"
      @update:employment-type="onSubmit"
      @update:brands="onSubmit"
      @update:units="onSubmit"
      @update:planning-status="onSubmit"
      @update:preferences="onSubmit"
      @update:pagination="updatePagination"
    />
    <confirmation-modal
      :is-open="$route.meta.showDeletionModal"
      data-test="deletion-modal"
      :description="$t('views.profiles.delete-question')"
      :subject="$t('views.profiles.delete-note')"
      @close="closeModals"
      @confirm="deleteProfileById($route.params.profileId)"
    />
  </view-wrapper>
</template>

<script>
import { mapActions } from 'vuex';
import { Api } from '@/api';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import ErrorBanner from '@/components/common/ErrorBanner';
import { hasPermissionFor } from '@/utils/permissionUtil';
import ViewWrapper from '@/components/common/ViewWrapper.vue';
import ProfilesListToolbar from '@/components/profiles/ProfilesList/ProfilesListToolbar.vue';
import ProfilesList from '@/components/profiles/ProfilesList/ProfilesList.vue';
import { debounce } from 'lodash';

const getSearchQuery = (searchQuery) => {
  const {
    offset,
    limit,
    teachingLevels,
    employmentType,
    brands,
    preferences,
    units,
    planningStatus,
    ...query
  } = searchQuery;

  return {
    offset,
    limit,
    teachingLevels,
    employmentType,
    brands,
    preferences,
    units,
    planningStatus,
    ...(Object.keys(query).length ? query : {}),
  };
};

export default {
  name: 'Profiles',
  components: {
    ProfilesList,
    ProfilesListToolbar,
    ViewWrapper,
    ConfirmationModal,
    ErrorBanner,
  },

  data() {
    return {
      cancelCurrentRequest: null,
      isLoading: false,
      results: [],
      name: null,
      email: null,
      teachingLevels: [],
      employmentType: [],
      brands: [],
      units: [],
      planningStatus: [],
      preferences: [],
      pagination: null,
      debouncedSearch: debounce(this.searchProfiles, 500),
    };
  },
  computed: {
    searchQuery() {
      return getSearchQuery(this.$route.query);
    },
    isValidSearchQuery() {
      return Object.values(this.searchQuery).some((param) => !!param);
    },
    userCanCreateProfiles() {
      return hasPermissionFor('academic-staff', ['c']);
    },
    userCanTriggerImport() {
      return hasPermissionFor('academic-staff-import', ['x']);
    },
    currentPagination: {
      get() {
        const standardPagination = {
          limit: 20,
          offset: 0,
          totalCount: 0,
        };
        return this.pagination
          ? {
              ...standardPagination,
              ...this.pagination,
            }
          : standardPagination;
      },
      set(value) {
        this.pagination = value;
      },
    },
  },

  watch: {
    searchQuery: {
      immediate: true,
      handler(query) {
        this.debouncedSearch(query);
      },
    },
    $route: {
      immediate: true,
      async handler() {
        if (this.isValidSearchQuery) {
          this.updatePagination(this.searchQuery);
        } else {
          this.pagination = null;
          this.updatePagination(this.currentPagination);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      updateSnackbar: 'snackbar/update',
    }),
    async searchProfiles(query) {
      this.isLoading = true;

      await this.$nextTick();

      const queryParams = this.isValidSearchQuery
        ? query
        : this.currentPagination;
      try {
        await this.getProfiles(queryParams);
      } finally {
        this.isLoading = false;
      }
    },
    closeModals() {
      this.$router.push({ name: 'profiles', query: this.$route.query });
    },
    goToDelete(profile) {
      this.$router.push({
        name: 'profile-delete',
        query: this.$route.query,
        params: {
          profileId: profile.id,
          profile,
        },
      });
    },
    async deleteProfileById(id) {
      this.closeModals();

      await Api.profiles.deleteById(id);

      await this.updateSnackbar({ message: 'Profile successfully deleted' });
    },
    async getProfiles(paginationData) {
      if (this.cancelCurrentRequest) {
        this.cancelCurrentRequest();
      }

      const {
        limit,
        offset,
        name,
        email,
        teachingLevels,
        employmentType,
        brands,
        units,
        planningStatus,
        preferences,
      } = paginationData;

      this.name = name;
      this.email = email;
      this.teachingLevels = this.parseArray(teachingLevels);
      this.employmentType = this.parseArray(employmentType);

      this.brands = this.parseArray(brands);

      this.units = this.parseArray(units);
      this.planningStatus = this.parseArray(planningStatus);

      this.preferences = this.parseArray(preferences);

      const searchParams = {
        ...(this.name ? { name: this.name } : {}),
        ...(this.email ? { email: this.email } : {}),
        ...(this.teachingLevels?.length
          ? { teachingLevels: this.teachingLevels }
          : {}),
        ...(this.employmentType?.length
          ? { employmentType: this.employmentType }
          : {}),
        ...(this.brands?.length ? { brands: this.brands } : {}),
        ...(this.units?.length ? { units: this.units } : {}),
        ...(this.planningStatus?.length
          ? { planningStatus: this.planningStatus }
          : {}),
        ...(this.preferences?.length ? { preferences: this.preferences } : {}),
        limit,
        offset,
      };

      const { request, cancel } = await Api.profiles.get(searchParams);

      this.cancelCurrentRequest = cancel;

      const result = await request;

      if (!result) {
        return;
      }

      const { data } = result;

      this.currentPagination = data.pagination;
      this.results = data.data;
    },
    updatePagination(pagination) {
      // eslint-disable-next-line no-unused-vars
      const { totalCount, ...newPagination } = pagination;

      this.$router.push({
        query: { ...this.searchQuery, ...newPagination },
      });
    },
    onSubmit: function () {
      const submitQuery = {
        ...this.getQueryWithoutFilters(),
        offset: 0,
        ...(this.email ? { email: this.email } : null),
        ...(this.name ? { name: this.name } : null),
        ...(this.teachingLevels?.length
          ? { teachingLevels: this.teachingLevels }
          : null),
        ...(this.employmentType?.length
          ? { employmentType: this.employmentType }
          : null),
        ...(this.brands?.length ? { brands: this.brands } : null),
        ...(this.units?.length ? { units: this.units } : null),
        ...(this.planningStatus?.length
          ? { planningStatus: this.planningStatus }
          : null),
        ...(this.preferences?.length
          ? { preferences: this.preferences }
          : null),
      };

      this.$router.push({
        query: { ...submitQuery },
      });
    },
    resetSearch() {
      this.$router.push({ name: 'profiles' });
    },
    parseArray(value) {
      return Array.isArray(value) ? value : value ? [value] : [];
    },
    getQueryWithoutFilters() {
      const searchQuery = Object.assign({}, { ...this.searchQuery });
      delete searchQuery.email;
      delete searchQuery.name;
      delete searchQuery.teachingLevels;
      delete searchQuery.employmentType;
      delete searchQuery.brands;
      delete searchQuery.units;
      delete searchQuery.planningStatus;
      delete searchQuery.preferences;
      return searchQuery;
    },
  },
};
</script>
