<template>
  <n-modal
    :is-open="isOpen"
    :title="$t('views.location.update-main-unit-and-campus-modal.title')"
    @close="$emit('close')"
  >
    <validation-form
      v-slot="{ meta }"
      ref="observer"
      :initial-values="initialValues"
    >
      <div class="m-3">
        <validation-provider
          v-slot="{ errors, field }"
          rules="required"
          name="unit"
          :label="$t('common.labels.unit')"
        >
          <n-select
            v-model="initialAssignedUnit"
            :label="$t('common.labels.unit')"
            :options="mainLocationPossibleUnits"
            :errors="errors"
            v-bind="field"
            required
            @update:model-value="onAssignedMainUnitChanged"
          />
        </validation-provider>
      </div>
      <div class="m-3">
        <validation-provider
          v-slot="{ errors, field }"
          :rules="!isCampusDropdownDisabled && 'required'"
          name="campus"
          :label="$t('common.labels.campus')"
        >
          <n-select
            v-model="initialAssignedCampus"
            :label="$t('common.labels.campus')"
            :options="mainLocationPossibleCampuses"
            :errors="errors"
            v-bind="field"
            :disabled="isCampusDropdownDisabled"
            required
          />
        </validation-provider>
      </div>
      <div class="flex justify-center h-[250px] mt-6">
        <img :src="IMAGE_URLS.CAMPUS" class="h-full" />
      </div>
      <div class="my-3">
        <p class="text-xs text-gray-700">
          {{ $t('common.labels.mandatory-fields') }}
        </p>
      </div>
      <div class="flex justify-end">
        <n-button
          variant="primary"
          :disabled="!meta.valid || !meta.dirty || !hasChanged"
          @click="saveMainLocation"
        >
          {{ $t('common.labels.save') }}
        </n-button>
      </div>
    </validation-form>
  </n-modal>
</template>

<script>
import { mapSingleUnitName } from '@/utils/units-name-mapper';
import { mapActions, mapGetters } from 'vuex';
import { IMAGE_URLS } from '@/constants/images';

export default {
  name: 'UpdateMainLocationModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    assignedMainUnit: {
      type: [String, null],
      required: true,
    },
    assignedMainCampus: {
      type: Array,
      required: true,
    },
  },
  emits: ['close', 'updateMainLocation'],
  data() {
    return {
      IMAGE_URLS,
      initialAssignedUnit: null,
      initialAssignedCampus: null,
      initialValues: {
        unit: this.assignedMainUnit,
        campus: this.assignedMainCampus,
      },
    };
  },
  computed: {
    ...mapGetters('mainLocation', [
      'isLoadingMainLocation',
      'mainLocationPossibleUnits',
      'mainLocationPossibleCampuses',
    ]),
    hasChanged() {
      return (
        !_.isEqual(this.initialAssignedUnit, this.assignedMainUnit) ||
        !_.isEqual(this.initialAssignedCampus, this.assignedMainCampus)
      );
    },
    isCampusDropdownDisabled() {
      if (this.isLoadingMainLocation) return true;

      if (!this.mainLocationPossibleCampuses) {
        return true;
      }

      if (this.initialAssignedCampus) {
        return false;
      }

      return this.mainLocationPossibleCampuses.length === 0;
    },
  },
  watch: {
    async isOpen(open) {
      if (open) {
        this.initialAssignedUnit = _.cloneDeep(this.assignedMainUnit);
        this.initialAssignedCampus = _.cloneDeep(this.assignedMainCampus);
        if (this.initialAssignedUnit) {
          await this.fetchPossibleCampuses(this.initialAssignedUnit);
        }
      }
    },
  },
  methods: {
    ...mapActions('mainLocation', [
      'fetchPossibleCampuses',
      'fetchMainLocationOfProfile',
      'updateMainLocation',
    ]),
    mapSingleUnitName,
    async onAssignedMainUnitChanged(unit) {
      this.initialAssignedCampus = null;
      await this.fetchPossibleCampuses(unit);
    },
    async saveMainLocation() {
      await this.updateMainLocation({
        unit: this.initialAssignedUnit,
        campus: this.initialAssignedCampus,
      });

      await this.fetchMainLocationOfProfile();
    },
  },
};
</script>
