export const endDateInputDisabled = (endDate) => {
  const startOfToday = new Date().setHours(0, 0, 0);

  return startOfToday > endDate;
};

export const startDateInputDisabled = (previousStartDate) => {
  const endOfToday = new Date().setHours(23, 60, 60);

  return new Date(previousStartDate) <= endOfToday;
};
