import { isAfter, isEqual } from 'date-fns';
import { parseDate as parse } from '@/utils/date';

const emptyOrAfter = (
  value,
  [target],
  { inclusion = true, format = 'yyyy-MM-dd' } = {}
) => {
  if (!value || !target) {
    return true;
  }

  value = parse(value, format);
  target = parse(target, format);

  const valid = isAfter(value, target) || (inclusion && isEqual(value, target));

  const message = `Das Datum muss größer ${
    inclusion ? 'oder gleich ' : ''
  } ${target.toLocaleDateString()} sein.`;

  return valid || message;
};

export { emptyOrAfter };
