const truncateText = (text, maximum = 30) => {
  if (!text) {
    return null;
  }

  if (text.length > maximum) {
    return `${text.substring(0, maximum)}...`;
  }

  return text;
};

export { truncateText };
