import { store } from '@/store';

export const successHandler = (response) => ({ ...response, success: true });
export const errorHandler = async (error) => {
  if (error.name === 'CanceledError') {
    return Promise.reject(error);
  }

  const errorData = error.response?.data || error;

  if (error.config?.url && error.config.url.match(/^\/cms\//)) {
    return Promise.reject(errorData);
  }

  await store.dispatch('errors/pushError', errorData);

  return Promise.reject(errorData);
};
