export const AVAILABLE_CAPABILITY_KEYS = [
  'canTeachOnline',
  'canTeachOnsite',
  'canCorrect',
  'canAuditExams',
  'canSuperviseThesis',
  'canManageMedia',
];

export const UNIVERSITY_CAPABILITIES_BY_PRESETS = Object.freeze([
  {
    key: 'professor',
    capabilities: [
      'canTeachOnline',
      'canCorrect',
      'canAuditExams',
      'canSuperviseThesis',
      'canManageMedia',
    ],
  },
  {
    key: 'academic-lecturer-internal',
    capabilities: [
      'canTeachOnline',
      'canCorrect',
      'canManageMedia',
      'canAuditExams',
    ],
  },
  {
    key: 'academic-lecturer-external',
    capabilities: ['canTeachOnline', 'canCorrect', 'canManageMedia'],
  },
]);
