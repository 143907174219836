export const IMAGE_URLS = {
  TRASH:
    'https://res.cloudinary.com/iubh/image/upload/v1633071257/IT%20-%20Epos%20/illustrations/epos-delete.svg',
  SEARCH_STAFF:
    'https://res.cloudinary.com/iubh/image/upload/v1633071960/IT%20-%20Epos%20/illustrations/epos-academic-staff-search.svg',
  ERROR_DEFAULT:
    'https://res.cloudinary.com/iubh/image/upload/v1625224956/IT%20-%20Epos%20/illustrations/epos-error.svg',
  ERROR_NOT_FOUND:
    'https://res.cloudinary.com/iubh/image/upload/v1655448179/IT%20-%20Epos%20/illustrations/epos-404.svg',
  ERROR_FORBIDDEN:
    'https://res.cloudinary.com/iubh/image/upload/v1655448781/IT%20-%20Epos%20/illustrations/epos-access-denied-403.svg',
  EMPTY_STATE:
    'https://res.cloudinary.com/iubh/image/upload/v1662720914/IT%20-%20Epos%20/illustrations/epos-empty-state.svg',
  NO_SEARCH_RESULTS:
    'https://res.cloudinary.com/iubh/image/upload/v1633072017/IT%20-%20Epos%20/illustrations/epos-no-search-results.svg',
  CAMPUS:
    'https://res.cloudinary.com/iubh/image/upload/v1699883696/epos-customer-profile-statistic-parallel.svg',
};
