import { ApiClient } from '@/api/ApiClient';
import { PROFILES_BASE_ROUTE } from '@/api/profiles';
import { pluckData } from '@/utils/pluckData';

export const COURSE_CAPABILITIES_BASE_ROUTE = 'course-capabilities';
export const STUDY_PROGRAMS_BASE_ROUTE = 'study-programs';

export const courseCapabilities = {
  getByProfileId: (profileId, { limit, offset, locale }) => {
    return ApiClient.instance.get(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${COURSE_CAPABILITIES_BASE_ROUTE}`,
      {
        params: {
          limit,
          offset,
          locale,
        },
      }
    );
  },
  getLastUpdatedPreferencesMetadata: (profileId) =>
    ApiClient.instance.get(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${COURSE_CAPABILITIES_BASE_ROUTE}/preferences/last-updated-metadata`
    ),
  save: (profileId, payload) =>
    ApiClient.instance.post(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${COURSE_CAPABILITIES_BASE_ROUTE}`,
      {
        courseIds: payload,
      }
    ),
  delete: (courseCapabilityId) =>
    ApiClient.instance.delete(
      `/${COURSE_CAPABILITIES_BASE_ROUTE}/${courseCapabilityId}`
    ),
  setPreferences: (profileId, payload) =>
    ApiClient.instance.patch(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${COURSE_CAPABILITIES_BASE_ROUTE}/preferences`,
      payload
    ),
  searchCapabilities: (profileId, payload) =>
    ApiClient.instance
      .post(
        `/${PROFILES_BASE_ROUTE}/${profileId}/${COURSE_CAPABILITIES_BASE_ROUTE}/search`,
        payload
      )
      .then(pluckData),
  getStudyPrograms: (locale) =>
    ApiClient.instance.get(`${STUDY_PROGRAMS_BASE_ROUTE}`, {
      params: { locale },
    }),
};
