import { t } from '@/plugins/translations';

export const BREADCRUMBS = {
  profiles: {
    get label() {
      return t('navigation.breadcrumbs.academic-staff-management');
    },
    to: {
      name: 'profiles',
    },
  },
};

export const SEARCH_META = Object.freeze({
  breadcrumbLinks: [BREADCRUMBS.profiles],
});

export const PROFILE_META = Object.freeze({
  breadcrumbLinks: [BREADCRUMBS.profiles],
});
