<template>
  <item-unavailable
    :route-to="{
      name: 'profile-capabilities',
      params: { profileId: $route.params.profileId },
    }"
  >
    <template #heading>{{ $t('navigation.tabs.deputation') }}</template>
    <template #image>
      <img
        :src="imageUrl"
        :alt="$t('views.deputation.common.deputation-unavailable')"
        aria-hidden="true"
        height="128"
      />
    </template>
    <template #subheading>{{
      $t('views.deputation.common.deputation-unavailable')
    }}</template>
    <template #description>{{
      $t('views.deputation.common.description')
    }}</template>
    <template #routerLinkText>{{
      $t('views.deputation.common.configure-here')
    }}</template>
  </item-unavailable>
</template>
<script>
import { IMAGE_URLS } from '@/constants/images';
import ItemUnavailable from '@/components/deputation/Common/ItemUnavailable.vue';

export default {
  components: {
    ItemUnavailable,
  },
  computed: {
    imageUrl() {
      return IMAGE_URLS.NO_SEARCH_RESULTS;
    },
  },
};
</script>
