import { Api } from '@/api';
import { AVAILABLE_CAPABILITY_KEYS } from '@/constants/universityCapabilitiesByPresets';

export default {
  namespaced: true,
  state: {
    assignedCapabilities: [],
    initialCapabilities: [],
    availableCapabilityKeys: AVAILABLE_CAPABILITY_KEYS,
    currentlySelectedProfileId: null,
  },
  getters: {
    manuallyAssignableBudget: (state) => {
      const assignedCapabilitiesWithBudget = state.assignedCapabilities.filter(
        (capability) => capability.budgetSetManually
      );

      const currentlyAssigned = assignedCapabilitiesWithBudget.reduce(
        (acc, capability) => acc + capability.budgetInPercent,
        0
      );

      return 100 - currentlyAssigned;
    },
    isCapChangedOrBudgetChanged:
      () => (assignedCapability, initialCapability) => {
        const isAssigned = Boolean(assignedCapability);

        const hasChanged = isAssigned !== Boolean(initialCapability);

        const hasBudgetChanged = isAssigned
          ? assignedCapability.budgetInPercent !==
              initialCapability?.budgetInPercent ||
            assignedCapability.budgetSetManually !==
              initialCapability?.budgetSetManually
          : false;

        return { hasChanged, hasBudgetChanged };
      },
    haveCapabilitiesChanged: (state, getters) => {
      const { isCapChangedOrBudgetChanged } = getters;
      return state.availableCapabilityKeys.some((capabilityKey) => {
        const assignedCapability = state.assignedCapabilities.find(
          (capability) => capability?.capability === capabilityKey
        );
        const initialCapability = state.initialCapabilities.find(
          (capability) => capability?.capability === capabilityKey
        );
        return (
          isCapChangedOrBudgetChanged(assignedCapability, initialCapability)
            .hasChanged ||
          isCapChangedOrBudgetChanged(assignedCapability, initialCapability)
            .hasBudgetChanged
        );
      });
    },
    isCapabilityAssigned: (state) => (capabilityKey) =>
      state.assignedCapabilities.some(
        (capability) => capability?.capability === capabilityKey
      ),
    assignedCapabilities: (state) => state.assignedCapabilities,
    getCapability: (state) => (capabilityKey) =>
      state.assignedCapabilities.find(
        (capability) => capability?.capability === capabilityKey
      ),
    getDataForCapabilityTable: (state, getters) => {
      const { isCapChangedOrBudgetChanged } = getters;
      return state.availableCapabilityKeys.map((capabilityKey) => {
        const assignedCapability = state.assignedCapabilities.find(
          (capability) => capability?.capability === capabilityKey
        );
        const initialCapability = state.initialCapabilities.find(
          (capability) => capability?.capability === capabilityKey
        );
        const isAssigned = Boolean(assignedCapability);

        const { hasChanged, hasBudgetChanged } = isCapChangedOrBudgetChanged(
          assignedCapability,
          initialCapability
        );

        return {
          capability: capabilityKey,
          isAssigned,
          hasChanged,
          hasBudgetChanged,
          budgetInPercent: isAssigned
            ? assignedCapability.budgetInPercent
            : null,
          budgetSetManually: isAssigned
            ? assignedCapability.budgetSetManually
            : null,
        };
      });
    },
  },
  actions: {
    resetState({ commit }) {
      commit('clearState');
    },
    async updateCurrentlySelectedProfileId({ commit, dispatch }, profileId) {
      commit('updateCurrentlySelectedProfileId', profileId);

      await dispatch('loadCapabilities');
    },
    updateInitialCapabilities({ commit }, capabilities) {
      commit('updateInitialCapabilities', capabilities);
    },
    updateAvailableCapabilityKeys({ commit }, capabilityKeys) {
      commit('updateAvailableCapabilityKeys', capabilityKeys);
    },
    async saveCapabilities({ state, commit }) {
      const { currentlySelectedProfileId, assignedCapabilities } = state;

      const payload = {
        profileId: currentlySelectedProfileId,
        universityCapabilities: assignedCapabilities,
      };

      await Api.universityCapabilities.post(
        currentlySelectedProfileId,
        payload
      );

      commit('updateInitialCapabilities', assignedCapabilities);
    },
    async loadCapabilities({ commit, state }) {
      const currentlyAssignedProfileId = state.currentlySelectedProfileId;

      const response = await Api.universityCapabilities.getById(
        currentlyAssignedProfileId
      );

      commit('updateCapabilities', response.data);

      commit('updateInitialCapabilities', response.data);
    },
    async setInitialCapabilities({ commit, state }) {
      commit('updateCapabilities', state.initialCapabilities);
    },

    async assignCapabilities({ commit, state }, capabilityKeys) {
      const currentlyAssignedProfileId = state.currentlySelectedProfileId;

      const capabilities = capabilityKeys.map((capabilityKey) => ({
        capability: capabilityKey,
        budgetInPercent: 0,
        budgetSetManually: false,
      }));

      const response = await Api.universityCapabilities.calculateBudgets(
        currentlyAssignedProfileId,
        [...capabilities]
      );

      commit('updateCapabilities', response.data);
    },
    async assignCapability({ commit, state }, capabilityKey) {
      const currentlyAssignedProfileId = state.currentlySelectedProfileId;

      const currentlyAssignedCapabilities = state.assignedCapabilities;

      const capability = {
        capability: capabilityKey,
        budgetInPercent: 0,
        budgetSetManually: false,
      };

      const response = await Api.universityCapabilities.calculateBudgets(
        currentlyAssignedProfileId,
        [...currentlyAssignedCapabilities, capability]
      );

      commit('updateCapabilities', response.data);
    },
    async unassignCapability({ commit, state }, capabilityKey) {
      const currentlyAssignedProfileId = state.currentlySelectedProfileId;

      const currentlyAssignedCapabilities = state.assignedCapabilities;

      const response = await Api.universityCapabilities.calculateBudgets(
        currentlyAssignedProfileId,
        currentlyAssignedCapabilities.filter(
          (capability) => capability?.capability !== capabilityKey
        )
      );

      commit('updateCapabilities', response.data);
    },
    async updateBudgetForCapability({ commit, state }, updatedCapability) {
      const currentlyAssignedProfileId = state.currentlySelectedProfileId;

      const currentlyAssignedCapabilities = state.assignedCapabilities;

      const response = await Api.universityCapabilities.calculateBudgets(
        currentlyAssignedProfileId,
        [
          ...currentlyAssignedCapabilities.filter(
            (capability) =>
              capability?.capability !== updatedCapability?.capability
          ),
          {
            ...updatedCapability,
            budgetInPercent: parseInt(updatedCapability.budgetInPercent, 10),
          },
        ]
      );

      commit('updateCapabilities', response.data);
    },
  },
  mutations: {
    clearState(state) {
      state.assignedCapabilities = [];
      state.initialCapabilities = [];
      state.currentlySelectedProfileId = null;
    },
    updateCurrentlySelectedProfileId(state, profileId) {
      state.currentlySelectedProfileId = profileId;
    },
    updateCapabilities(state, capabilities) {
      state.assignedCapabilities = capabilities;
    },
    updateInitialCapabilities(state, capabilities) {
      state.initialCapabilities = capabilities;
    },
    updateAvailableCapabilityKeys(state, capabilityKeys) {
      state.availableCapabilityKeys = capabilityKeys;
    },
    updateCapability(state, capability) {
      const index = state.assignedCapabilities.findIndex(
        (c) => c?.capability === capability?.capability
      );
      state.assignedCapabilities[index] = capability;
    },
    updateBudgetForCapability(state, capability) {
      const index = state.assignedCapabilities.findIndex(
        (c) => c?.capability === capability?.capability
      );

      state.assignedCapabilities[index].budgetInPercent =
        capability.budgetInPercent;
    },
    updateBudgetManuallySetForCapability(state, capability) {
      const index = state.assignedCapabilities.findIndex(
        (c) => c?.capability === capability?.capability
      );
      state.assignedCapabilities[index].budgetSetManually =
        capability.budgetSetManually;
    },
  },
};
