import { createRouter, createWebHistory } from 'vue-router';
import { store } from '@/store';

export const useRouter = (app, routes) => {
  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(_to, _from, savedPosition) {
      return savedPosition ?? { left: 0, top: 0 };
    },
  });

  router.beforeEach(async (to, from, next) => {
    if (to.meta && to.meta.title) {
      document.title = `${to.meta.title} | EPOS | IU`;
    }

    await store.dispatch('errors/clearErrors');
    document.getElementById('epos__core-user')?.scrollIntoView();

    next();
  });

  app.use(router);
};
