<template>
  <div>
    <div
      v-for="capability in deputationData"
      :key="capability?.capability"
      class="flex justify-between w-full items-center pt-2"
    >
      <div
        class="w-7/12 overflow-ellipsis overflow-hidden whitespace-nowrap text-blue-600"
        :title="getCapabilityLabel(capability)"
      >
        {{ getCapabilityLabel(capability) }}
      </div>

      <MinimalDeputationBarSegment
        class="w-3/12"
        :capability="capability"
        :deputation-status="capability.deputationStatus"
        :current-budget-in-percent="getCapabilityBudget(capability)"
        :desired-budget-in-percent="capability.plannedBudgetInPercent"
      ></MinimalDeputationBarSegment>
    </div>
  </div>
</template>
<script>
import MinimalDeputationBarSegment from '@/components/deputation/Deputation/Detail/MinimalDeputationBarSegment.vue';
import { getTextByCapability } from '@/constants/picklists/capabilities';

export default {
  name: 'DeputationVerticalBarGroup',
  components: { MinimalDeputationBarSegment },
  props: {
    deputationData: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    getCapabilityLabel(capability) {
      return getTextByCapability(capability);
    },
    getCapabilityBudget(assignedCapability) {
      if (this.zeroPlannedAndNonZeroCurrentBudget(assignedCapability)) {
        return 200;
      }

      if (this.zeroPlannedAndZeroCurrentBudget(assignedCapability)) {
        return 0;
      }

      return this.computeBudget(assignedCapability);
    },
    zeroPlannedAndNonZeroCurrentBudget(assignedCapability) {
      return (
        assignedCapability.plannedBudgetInPercent === 0 &&
        assignedCapability.currentBudgetInPercent > 0
      );
    },
    zeroPlannedAndZeroCurrentBudget(assignedCapability) {
      return (
        assignedCapability.currentBudgetInPercent === 0 &&
        assignedCapability.plannedBudgetInPercent === 0
      );
    },
    computeBudget(assignedCapability) {
      if (assignedCapability) {
        return Math.round(
          (assignedCapability.currentBudgetInPercent /
            assignedCapability.plannedBudgetInPercent) *
            100,
          2
        );
      }
      return 0;
    },
  },
};
</script>
