<template>
  <deputation-section>
    <template #title>{{ $t('common.labels.overall') }}</template>
    <template #content>
      <div v-if="summaryLoading" class="flex flex-col items-center mt-12">
        <n-simple-loader />
        <p class="text-cta">
          {{ $t('views.deputation.deputation-summary-section.loading') }}.
        </p>
      </div>
      <div v-else class="bg-white elevated rounded p-8">
        <SummarizedDeputationPercentage />
        <DeputationBar class="pt-4"></DeputationBar>
      </div>
    </template>
  </deputation-section>
</template>
<script>
import DeputationSection from '@/components/deputation/Common/DeputationSection.vue';
import SummarizedDeputationPercentage from '@/components/deputation/Deputation/Summary/SummarizedDeputationPercentage.vue';
import DeputationBar from '@/components/deputation/Deputation/Summary/DeputationBar.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'DeputationSummarySection',
  components: {
    SummarizedDeputationPercentage,
    DeputationBar,
    DeputationSection,
  },
  computed: {
    ...mapGetters('deputation', ['summaryLoading']),
  },
};
</script>
