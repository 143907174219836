<template>
  <div class="mb-14">
    <div>
      <h4 class="text-sm font-bold">
        {{ sectionTitle }}{{ mandatory ? '*' : '' }}
      </h4>
      <n-card-group
        :mandatory="mandatory"
        :warning-text="mandatory ? emptyText : ''"
        :info-text="!mandatory ? emptyText : ''"
      >
        <n-card
          v-for="(contact, index) in sortedContacts"
          :key="index"
          :is-primary="isPrimaryContact(contact)"
          data-test="contact-group-item"
          class="bg-blue-100"
        >
          <template #header>
            {{ getContactTypeText(contact.type) }}
            <n-chip
              v-for="tag in tags(contact)"
              :key="tag"
              class="bg-blue-600 text-white"
              data-test="contact-tag"
            >
              {{ tag }}
            </n-chip>
          </template>
          <template #content>
            <slot name="data" :contact="contact" />
          </template>
          <template #footer>
            <n-menu
              :id="contact.id"
              :actions="actions(contact)"
              align-right
              data-test="contact-actions"
              @click:click-update="
                handleClickEvent('click-update', { ...contact })
              "
              @click:click-set-as-primary="
                handleClickEvent('click-set-as-primary', { ...contact })
              "
              @click:click-delete="
                handleClickEvent('click-delete', { ...contact })
              "
            >
              <template #activator="{ toggle }">
                <n-button
                  :disabled="disabled"
                  variant="quaternary"
                  data-test="contact-manage"
                  @click="toggle"
                >
                  <n-icon icon="mdi-chevron-down" />{{
                    $t('common.labels.manage')
                  }}
                </n-button>
              </template>
            </n-menu>
          </template>
        </n-card>
      </n-card-group>
      <n-button
        variant="tertiary"
        class="mt-4"
        data-test="contact-add"
        :disabled="disabled"
        @click="onClickAdd"
      >
        <n-icon icon="mdi-plus" />
        {{ addButtonLabel }}
      </n-button>
    </div>
  </div>
</template>

<script>
import { getTextByKey } from '@/constants/picklists';

export default {
  name: 'ContactsBox',
  props: {
    sectionTitle: {
      type: String,
      required: true,
    },
    addButtonLabel: {
      type: String,
      required: true,
    },
    emptyText: {
      type: String,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    contactTypes: {
      type: Array,
      required: true,
    },
    actions: {
      type: Function,
      required: true,
    },
    tags: {
      type: Function,
      default: () => [],
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'click-create',
    'click-update',
    'click-delete',
    'click-set-as-primary',
  ],
  computed: {
    sortedContacts() {
      return [...this.contacts].sort((contact) => (contact.primary ? -1 : 1));
    },
  },
  methods: {
    getContactTypeText(type) {
      return getTextByKey(this.contactTypes, type);
    },
    isPrimaryContact(contact) {
      return !!contact.primary;
    },
    onClickAdd() {
      this.$emit('click-create');
    },
    handleClickEvent(key, contact) {
      return this.$emit(key, contact);
    },
  },
};
</script>
