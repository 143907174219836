<template>
  <view-wrapper>
    <profile-form
      :model-value="initialValue"
      :previous-profile="initialValue"
      :disabled="!userCanUpdateProfiles"
      @submit="updateProfile"
      @toggle-planning-status="toggleProfilePlanningStatus"
      @soft-delete="goToProfileDelete"
      @restore="restoreProfile"
    >
      <template #contacts>
        <profile-contacts
          :addresses="addresses"
          :emails="emails"
          :phones="phones"
          :emails-disabled="!userCanUpdateEmails"
          :disabled="!userCanUpdateProfiles"
          @create-contact="createContact"
          @update-contact="updateContact"
          @delete-contact="deleteContact"
          @set-as-primary-contact="setAsPrimaryContact"
          @open-contact-create="goToCreate"
          @open-contact-update="goToUpdate"
          @open-contact-delete="goToDelete"
          @close="closeModals"
        />
      </template>
    </profile-form>
    <n-modal
      ref="modal"
      :is-open="$route.meta.showModal"
      :title="$route.meta.modalTitle"
      size="lg"
      display-close-button
      white
      auto-height
      @close="closeModals"
    >
      <router-view name="modal" />
    </n-modal>
    <confirmation-modal
      :is-open="$route.meta.showSoftDeleteProfileModal"
      :subject="$t('views.profiles.delete-question')"
      :description="$t('views.profiles.delete-note')"
      @close="closeModals"
      @confirm="softDeleteProfile"
    />
    <confirmation-modal
      :is-open="$route.meta.showDeletionModal"
      :subject="`Bist Du sicher, dass Du diese ${resourceNameDisplayName} löschen möchtest??`"
      :description="`Diese ${resourceNameDisplayName} wird archiviert und kann nur mit Admin Rechten wiederhergestellt werden.`"
      :extra-data="dataToDelete"
      @close="closeModals"
      @confirm="
        deleteContact($route.meta.resourceName, $route.params.contactId)
      "
    />
  </view-wrapper>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
import { Api } from '@/api';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import ProfileContacts from '@/components/profiles/ProfileContacts';
import ProfileForm from '@/components/profiles/ProfileForm';
import { hasPermissionFor } from '@/utils/permissionUtil';
import ViewWrapper from '@/components/common/ViewWrapper.vue';
const { mapGetters } = createNamespacedHelpers('puffer');
import _ from '@/plugins/lodash';
import { format } from 'date-fns';

export default {
  name: 'ProfileUpdate',
  components: {
    ViewWrapper,
    ConfirmationModal,
    ProfileContacts,
    ProfileForm,
  },
  data() {
    return {
      initialValue: null,
      addresses: [],
      emails: [],
      phones: [],
      isLoading: true,
    };
  },
  computed: {
    userCanUpdateProfiles() {
      return hasPermissionFor('academic-staff', ['u']);
    },
    userCanUpdateEmails() {
      return this.initialValue?.academyId && this.userCanUpdateProfiles;
    },
    resourceNameDisplayName() {
      switch (this.$route.meta.resourceName) {
        case 'emails':
          return 'E-Mail';
        case 'addresses':
          return 'Adresse';
        case 'phones':
          return 'Telefonnummer';
      }
      return '';
    },
    dataToDelete() {
      switch (this.$route.meta.resourceName) {
        case 'emails':
          return this.currentContact.email;
        case 'addresses':
          return `${this.currentContact.street} ${this.currentContact.houseNumber}
          ${this.currentContact.zip} ${this.currentContact.city}
          ${this.currentContact.country}`;
        case 'phones':
          return this.currentContact.phonenumber;
      }
      return '';
    },
    ...mapGetters(['currentContact', 'currentProfile']),
  },
  watch: {
    currentProfile: {
      immediate: true,
      deep: true,
      async handler(value) {
        if (!value) {
          return;
        }

        this.initialValue = this.mapProfileInitialValues(value);

        await this.loadContacts();

        this.tryRefreshAcademyIdRecursively();
      },
    },
  },
  methods: {
    ...mapActions({
      updateSnackbar: 'snackbar/update',
      updateStoreContact: 'puffer/updateStoreContact',
      updateStoreProfile: 'puffer/updateStoreProfile',
    }),
    async loadContacts() {
      const [{ data: addresses }, { data: emails }, { data: phones }] =
        await Promise.all([
          Api.profiles.getAddresses(this.currentProfile.id),
          Api.profiles.getEmails(this.currentProfile.id),
          Api.profiles.getPhones(this.currentProfile.id),
        ]);

      addresses && (this.addresses = addresses);
      emails && (this.emails = emails);
      phones && (this.phones = phones);
    },
    tryRefreshAcademyIdRecursively(attempts = 1) {
      const maxAttempts = 5;

      if (attempts > maxAttempts) {
        this.isLoading = false;

        return;
      }

      if (this.initialValue?.academyId) {
        // eslint-disable-next-line no-console
        console.info('Academy ID is set, refreshing contacts...', {
          attempts,
          maxAttempts,
          profile: this.currentProfile,
        });

        this.isLoading = false;

        if (attempts > 1) {
          this.updateSnackbar({
            message: 'The profile has been successfully synced with Care',
            color: 'success',
          });
        }
      } else {
        setTimeout(
          () => {
            this.refreshContacts();

            this.tryRefreshAcademyIdRecursively(attempts + 1);
          },
          Math.pow(2, attempts) * 1000
        );
      }
    },
    closeModals() {
      this.$router.push({
        name: 'profile-update',
      });
    },
    goToCreate(resourceName) {
      this.$router.push({
        name: `profile-update.${resourceName}.create`,
      });
    },
    goToUpdate(resourceName, contact) {
      this.$router.push({
        name: `profile-update.${resourceName}.update`,
        params: {
          contactId: contact.id,
        },
      });
    },
    goToProfileDelete() {
      const id = this.currentProfile?.id;

      if (!id) {
        return;
      }

      this.$router.push({
        name: 'profile-soft-delete',
        params: {
          profileId: id,
          profile: this.currentProfile,
        },
      });
    },
    goToDelete(resourceName, contact) {
      this.$router.push({
        name: `profile-update.${resourceName}.delete`,
        params: {
          contactId: contact.id,
        },
      });
    },

    async refreshContacts() {
      if (!this.currentProfile) {
        return;
      }

      const { data } = await Api.profiles.getById(this.currentProfile.id)
        .request;

      this.loadContacts();

      this.initialValue = {
        ...this.initialValue,
        academyId: data?.academyId,
      };

      // eslint-disable-next-line no-console
      console.info('Contacts have been refreshed', {
        profileId: this.currentProfile.id,
        data,
      });
    },
    async createContact(resourceName, payload) {
      if (!this.currentProfile) {
        return;
      }

      this.closeModals();

      await Api.profiles[resourceName](this.currentProfile.id, payload);

      await this.updateSnackbar({
        message: 'The contact has been successfully created',
      });

      await this.refreshContacts();
    },
    async updateContact(resourceName, payload) {
      payload = { ...this.currentContact, ...payload };
      this.closeModals();

      await Api[resourceName].updateById(payload.id, payload);

      await this.updateSnackbar({
        message: 'The contact has been successfully updated',
      });

      await this.refreshContacts();
    },
    async deleteContact(resourceName, contactId) {
      this.closeModals();

      await Api[resourceName].deleteById(contactId);

      await this.updateSnackbar({
        message: 'The contact has been successfully deleted',
      });

      await this.refreshContacts();
    },
    async setAsPrimaryContact(resourceName, payload) {
      await Api[resourceName].updateById(payload.id, {
        ...payload,
        primary: true,
      });

      await this.updateSnackbar({
        message: 'The contact has been successfully updated',
      });

      await this.refreshContacts();
    },
    async updateProfile(profile) {
      if (!this.currentProfile) {
        return;
      }

      this.isLoading = true;

      try {
        const { data } = await Api.profiles.updateById(
          this.currentProfile.id,
          profile
        );

        await this.updateStoreProfile(data);

        await this.updateSnackbar({
          message: 'The profile has been successfully updated',
        });
      } finally {
        this.isLoading = false;
      }
    },
    async toggleProfilePlanningStatus() {
      if (!this.currentProfile) {
        return;
      }

      this.isLoading = true;

      try {
        const { data } = await Api.profiles.togglePlanningStatus(
          this.currentProfile.id
        );

        this.initialValue = {
          ...this.initialValue,
          planningStatus: data.planningStatus,
        };

        await this.updateSnackbar({
          message: 'The profile has been successfully updated',
        });
      } finally {
        this.isLoading = false;
      }
    },
    async restoreProfile() {
      if (!this.currentProfile) {
        return;
      }

      this.isLoading = true;

      const updatedProfile = {
        ...this.currentProfile,
      };

      delete updatedProfile.deletedAt;

      try {
        await Api.profiles.restore(this.currentProfile.id);

        await this.updateStoreProfile(updatedProfile);

        await this.updateSnackbar({
          message: 'The profile has been successfully restored',
        });
      } finally {
        this.isLoading = false;

        this.initialValue = { ...this.initialValue, ...updatedProfile };
      }
    },
    async softDeleteProfile() {
      if (!this.currentProfile) {
        return;
      }

      this.isLoading = true;

      const updatedProfile = {
        ...this.currentProfile,
        deletedAt: new Date().toISOString(),
      };

      try {
        await Api.profiles.deleteById(this.currentProfile.id);

        await this.updateStoreProfile(updatedProfile);

        await this.updateSnackbar({
          message: 'The profile has been successfully deleted',
        });
      } finally {
        this.isLoading = false;

        await this.closeModals();

        this.initialValue = { ...this.initialValue, ...updatedProfile };
      }
    },
    mapProfileInitialValues(profile) {
      const newProfile = _.cloneDeep(profile);
      return {
        ...newProfile,
        contractStartDate:
          newProfile.contractStartDate &&
          format(new Date(newProfile.contractStartDate), 'yyyy-MM-dd'),
        contractEndDate:
          newProfile.contractEndDate &&
          format(new Date(newProfile.contractEndDate), 'yyyy-MM-dd'),
      };
    },
  },
};
</script>
