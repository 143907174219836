export const DEGREES = Object.freeze([
  {
    text: 'Habilitation',
    value: 'habilitation',
  },
  {
    text: 'Promotion',
    value: 'promotion',
  },
  {
    text: 'Diplom',
    value: 'diploma',
  },
  {
    text: 'Staatsexamen',
    value: 'state-exam',
  },
  {
    text: 'Magister',
    value: 'magister',
  },
  {
    text: 'Master',
    value: 'master',
  },
  {
    text: 'Bachelor',
    value: 'bachelor',
  },
]);
