import { deputation } from '@/api/deputation';
import { DeputationGroupType } from '@/constants/deputationGroupType';

export default {
  namespaced: true,
  state: {
    currentlySelectedProfileId: null,
    selectedYear: new Date().getFullYear(),
    summary: null,
    summaryLoading: false,
    deputationAvailableLoading: false,
    detailLoading: false,
    isDeputationAvailable: true,
    selectedDetailView: DeputationGroupType.MONTH,
    detailViewData: null,
  },
  getters: {
    startOfSelectedYear: (state) => new Date(state.selectedYear, 0, 1),
    endOfSelectedYear: (state) => new Date(state.selectedYear, 11, 31),
    selectedYear: (state) => state.selectedYear,
    selectedProfileId: (state) => state.currentlySelectedProfileId,
    summaryCapabilities: (state) => state.summary?.capabilities,
    summaryOverallDeputationInPercent: (state) =>
      state.summary?.overallDeputationInPercent,
    summaryOverallDeputationStatus: (state) =>
      state.summary?.overallDeputationStatus,
    summaryLoading: (state) =>
      state.summaryLoading || state.deputationAvailableLoading,
    isDeputationAvailable: (state) => state.isDeputationAvailable,
    deputationAvailableLoading: (state) => state.deputationAvailableLoading,
    selectedDetailView: (state) => state.selectedDetailView,
    detailViewData: (state) => state.detailViewData,
    detailLoading: (state) => state.detailLoading,
  },
  actions: {
    resetState({ commit }) {
      commit('setSelectedYear', new Date().getFullYear());
      commit('setSummary', null);
      commit('setSummaryLoading', false);
      commit('setIsDeputationAvailable', true);
      commit('setDeputationAvailableLoading', false);
      commit('setProfile', null);
    },
    async setSelectedYear({ commit, getters, dispatch }, year) {
      const currentlySelectedYear = getters.selectedYear;

      if (currentlySelectedYear === year) {
        return;
      }

      commit('setSelectedYear', year);

      await Promise.all([
        dispatch('fetchSummary'),
        dispatch('fetchDetailData'),
      ]);
    },
    setProfile({ commit }, profileId) {
      commit('setProfile', profileId);
    },
    async fetchSummary({ commit, getters }) {
      if (!getters.isDeputationAvailable) {
        return;
      }

      commit('setSummaryLoading', true);

      const payload = {
        startDate: getters.startOfSelectedYear,
        endDate: getters.endOfSelectedYear,
        profileId: getters.selectedProfileId,
      };

      try {
        const { data } = await deputation.getOverview(
          getters.selectedProfileId,
          payload
        );

        commit('setSummary', data);
        commit('setSummaryLoading', false);
      } catch (e) {
        commit('setSummary', null);
        commit('setSummaryLoading', false);
      }
    },
    async fetchDeputationAvailable({ commit, dispatch, getters }) {
      const profileId = getters.selectedProfileId;

      commit('setDeputationAvailableLoading', true);

      try {
        const { data } = await deputation.isAvailable(profileId);

        const deputationAvailable = data?.available;

        commit('setIsDeputationAvailable', deputationAvailable);
        commit('setDeputationAvailableLoading', false);

        if (deputationAvailable === true) {
          await dispatch('fetchSummary', profileId);
        }
      } catch (e) {
        commit('setIsDeputationAvailable', false);
        commit('setDeputationAvailableLoading', false);
      }
    },
    async fetchDetailData({ commit, getters, state }) {
      if (!getters.isDeputationAvailable) {
        return;
      }

      commit('setDetailViewData', null);
      commit('setDetailLoading', true);

      let detailViewData = null;

      const detailView = state.selectedDetailView;

      const payload = {
        year: getters.selectedYear,
        profileId: getters.selectedProfileId,
      };

      try {
        if (detailView === DeputationGroupType.SEMESTER) {
          detailViewData = await deputation.getSemester(
            getters.selectedProfileId,
            payload
          );
        } else if (detailView === DeputationGroupType.MONTH) {
          detailViewData = await deputation.getMonthly(
            getters.selectedProfileId,
            payload
          );
        } else if (detailView === DeputationGroupType.QUARTER) {
          detailViewData = await deputation.getQuarterly(
            getters.selectedProfileId,
            payload
          );
        }

        commit('setDetailViewData', detailViewData?.data);
        commit('setDetailLoading', false);
      } catch (e) {
        commit('setDetailViewData', null);
        commit('setDetailLoading', false);
      }
    },
    async setSelectedDetailView({ commit, getters }, detailView) {
      const currentlySelectedDetailView = getters.selectedDetailView;

      if (currentlySelectedDetailView === detailView) {
        return;
      }

      commit('setSelectedDetailView', detailView);

      await this.dispatch('deputation/fetchDetailData');
    },
  },
  mutations: {
    setSelectedYear(state, year) {
      state.selectedYear = year;
    },
    setSummary(state, summary) {
      state.summary = summary;
    },
    setSummaryLoading(state, loading) {
      state.summaryLoading = loading;
    },
    setIsDeputationAvailable(state, isDeputationAvailable) {
      state.isDeputationAvailable = isDeputationAvailable;
    },
    setDeputationAvailableLoading(state, loading) {
      state.deputationAvailableLoading = loading;
    },
    setProfile(state, profileId) {
      state.currentlySelectedProfileId = profileId;
    },
    setSelectedDetailView(state, detailView) {
      state.selectedDetailView = detailView;
    },
    setDetailViewData(state, detailViewData) {
      state.detailViewData = detailViewData;
    },
    setDetailLoading(state, loading) {
      state.detailLoading = loading;
    },
  },
};
