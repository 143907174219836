<template>
  <div
    class="flex flex-col justify-center items-center border-dashed border rounded border-gray-600 h-full min-h-[200px]"
  >
    <n-button
      variant="primary"
      :disabled="!hasPermissionToAssignLocations"
      @click="$emit('showAssignUnitModal')"
    >
      <n-icon icon="plus" class="mr-2" />
      {{ $t('views.location.assign-unit') }}
    </n-button>
  </div>
</template>
<script>
import { hasPermissionFor } from '@/utils/permissionUtil';
const RESOURCE_PATH = 'academic-staff';
export default {
  name: 'LocationEmptyGridItem',
  emits: ['showAssignUnitModal'],
  computed: {
    hasPermissionToAssignLocations() {
      return hasPermissionFor(RESOURCE_PATH, ['u']);
    },
  },
};
</script>
