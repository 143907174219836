export function convertTranslatedString(translations) {
  if (!Array.isArray(translations)) {
    return;
  }
  const fallback = translations[0]?.value;
  if (!window.locale) {
    return fallback;
  }
  const german =
    translations.find((t) => t.language === 'de')?.value ?? fallback;
  const english =
    translations.find((t) => t.language === 'en')?.value ?? fallback;

  return (window.locale === 'de' ? german : english) || fallback;
}
