<template>
  <n-applet-page
    aria-label="epos-academic-staff"
    data-test="staff-view-container"
  >
    <template #header>
      <n-view-navigation
        v-if="!$route.params.errorType"
        :links="viewTabs"
        :breadcrumb-links="$route.meta.breadcrumbLinks"
      >
        <template #headline>
          <slot v-if="$slots.headline" name="headline" />
          <template v-else>
            <div class="flex">
              {{ heading }}
              <n-chip
                v-if="isProfileArchivedChipActive"
                class="ml-2 bg-red-500 text-white"
              >
                Archiviert
              </n-chip>
            </div>
            <div
              v-if="currentProfile"
              class="flex items-center space-x-1 text-sm text-[#575757] capitalize"
            >
              <div>
                {{ currentProfile.teachingLevel }}
              </div>
              <div>&middot;</div>
              <div>
                {{ currentEmploymentType }}
              </div>
              <div>&middot;</div>
              <div>
                {{ currentPlanningStatus }}
              </div>
            </div>
          </template>
        </template>
      </n-view-navigation>
    </template>
    <template #content>
      <n-error-page
        v-if="$route.params.errorType"
        :error-type="$route.params.errorType"
        :error="$route.params.error"
        @reload="reload"
        @go-back="goBack"
      />
      <slot v-else />
      <Snackbar />
    </template>
  </n-applet-page>
</template>

<script>
import { t } from '@/plugins/translations';
import { createNamespacedHelpers, mapActions } from 'vuex';
import Snackbar from '@/components/common/Snackbar';
import { Routes } from '@epos/core-applets';
import { NAppletPage } from '@careerpartner/nitro';
const { mapGetters } = createNamespacedHelpers('puffer');
export default {
  name: 'ViewWrapper',
  components: { Snackbar, NAppletPage },
  computed: {
    heading() {
      return this.currentProfile && this.$route.params?.profileId
        ? `${this.currentProfile.givenName} ${this.currentProfile.familyName}`
        : this.$route.meta.headline;
    },
    isProfileArchivedChipActive() {
      return this.$route.params?.profileId && this.isProfileSoftDeleted;
    },
    viewTabs() {
      const profileId = this.$route.params?.profileId;

      const tabs = [
        {
          label: t('navigation.tabs.profile'),
          to: { name: 'profile-update' },
        },
        {
          label: t('navigation.tabs.university-capabilities'),
          to: { name: 'profile-capabilities', params: { profileId } },
        },
        {
          label: t('navigation.tabs.course-capabilities'),
          to: { name: 'profile-course-capabilities', params: { profileId } },
        },
        {
          label: t('navigation.tabs.deputation'),
          to: { name: 'profile-deputation', params: { profileId } },
        },
        {
          label: t('navigation.tabs.units-campus'),
          to: { name: 'profile-locations', params: { profileId } },
        },
        {
          label: t('navigation.tabs.availability'),
          to: { name: 'availability-preferences', params: { profileId } },
        },
        {
          label: t('navigation.tabs.roles-permissions'),
          external: true,
          icon: 'open-in-new',
          href: `${Routes.IAM_SERVICES.USERS}/${profileId}`,
        },
      ];

      return profileId ? tabs : [];
    },
    ...mapGetters(['currentProfile', 'isProfileSoftDeleted']),
    currentEmploymentType() {
      if (this.currentProfile.employmentType === 'internal') {
        return t('common.enums.employment-type.internal');
      } else if (this.currentProfile.employmentType === 'external') {
        return t('common.enums.employment-type.external');
      }

      return this.currentProfile.employmentType;
    },
    currentPlanningStatus() {
      return this.currentProfile.planningStatus
        ? t('common.enums.planning-status.active')
        : t('common.enums.planning-status.inactive');
    },
  },
  mounted() {
    this.setCurrentUser();
  },
  methods: {
    ...mapActions({
      setCurrentUser: 'currentUser/setCurrentUser',
    }),
    reload() {
      window.location.reload();
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>
