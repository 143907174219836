import { coursesApi } from '@/api/courses';
import { courseCapabilities } from '@/api/courseCapabilities';
import { t } from '@/plugins/translations';

const DEFAULT_PAGINATION = {
  limit: 4,
  offset: 0,
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isSaving: false,
    courseSearchResult: [],
    pagination: DEFAULT_PAGINATION,
    selectedStudyPrograms: [],
    searchTerm: '',
  },
  getters: {
    courseSearchResult: (state) => state.courseSearchResult,
    pagination: (state) => state.pagination,
    searchParams: (state) => ({
      studyPrograms: state.selectedStudyPrograms,
      searchTerm: state.searchTerm,
      limit: state.pagination?.limit || DEFAULT_PAGINATION.limit,
      offset: state.pagination?.offset || DEFAULT_PAGINATION.offset,
    }),
    searchTerm: (state) => state.searchTerm,
    selectedStudyPrograms: (state) => state.selectedStudyPrograms,
    currentProfileId: (state, getters, rootState, rootGetters) =>
      rootGetters['puffer/currentProfile']?.id,
    isSaving: (state) => state.isSaving,
    isLoading: (state) => state.isLoading,
    appliedSearchFilters: (state) => {
      const hasSearchTerm =
        state.searchTerm !== '' && state.searchTerm !== null;
      const hasSelectedStudyPrograms = state.selectedStudyPrograms.length > 0;

      const filters = [];

      if (hasSearchTerm) {
        filters.push({
          label: t('common.labels.name'),
          value: state.searchTerm,
          key: 'searchTerm',
        });
      }

      if (hasSelectedStudyPrograms) {
        filters.push({
          label: t('common.labels.study-program'),
          value: state.selectedStudyPrograms,
          key: 'studyProgramsFilter',
        });
      }

      return filters;
    },
  },
  actions: {
    async triggerSearchForCourses({ commit, dispatch, getters }) {
      commit('isLoading', true);

      try {
        const { searchParams, currentProfileId } = getters;

        const resp = await coursesApi.searchForCourses(
          currentProfileId,
          searchParams
        );

        commit('setCourseSearchResult', resp.data);
        commit('setPagination', resp.pagination);
      } catch (e) {
        await dispatch('errors/pushError', e, { root: true });
      } finally {
        commit('isLoading', false);
      }
    },
    async updateSearchTerm({ commit, dispatch }, searchTerm) {
      commit('setSearchTerm', searchTerm);

      await dispatch('triggerSearchForCourses');
    },
    async updateStudyProgramsFilter({ commit, dispatch }, studyPrograms) {
      commit('setSelectedStudyPrograms', studyPrograms);

      await dispatch('triggerSearchForCourses');
    },
    async updatePagination({ commit, dispatch }, pagination) {
      commit('setPagination', pagination);

      await dispatch('triggerSearchForCourses');
    },

    async resetModal({ commit }) {
      commit('setCourseSearchResult', []);
      commit('setPagination', DEFAULT_PAGINATION);
      commit('setSearchTerm', '');
      commit('setSelectedStudyPrograms', []);
    },
    async resetSearch({ commit, dispatch }) {
      commit('setSelectedStudyPrograms', []);
      commit('setPagination', DEFAULT_PAGINATION);

      await dispatch('triggerSearchForCourses');
    },

    async saveCourseCapabilityPreferencesForProfile(
      { commit, dispatch, getters },
      { courseIds = [] }
    ) {
      commit('setIsSaving', true);

      try {
        const { currentProfileId } = getters;

        await courseCapabilities.save(currentProfileId, courseIds);
      } catch (e) {
        await dispatch('errors/pushError', e, { root: true });
      } finally {
        commit('setIsSaving', false);
      }
    },
  },

  mutations: {
    isLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSaving(state, isSaving) {
      state.isSaving = isSaving;
    },
    setCourseSearchResult(state, courses) {
      state.courseSearchResult = courses;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setSearchTerm(state, searchTerm) {
      state.searchTerm = searchTerm;
    },
    setSelectedStudyPrograms(state, studyPrograms) {
      state.selectedStudyPrograms = studyPrograms;
    },
  },
};
