import { t } from '@/plugins/translations';

export const EMAIL_TYPES = Object.freeze([
  {
    key: 'iu',
    get text() {
      return t('common.enums.contact-type.iu');
    },
  },
  {
    key: 'private',
    get text() {
      return t('common.enums.contact-type.private');
    },
  },
  {
    key: 'business',
    get text() {
      return t('common.enums.contact-type.business');
    },
  },
  {
    key: 'vacation',
    get text() {
      return t('common.enums.contact-type.vacation');
    },
  },
  {
    key: 'emergency',
    get text() {
      return t('common.enums.contact-type.emergency');
    },
  },
]);
