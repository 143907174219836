import { t } from '@/plugins/translations';
import { BRANDS } from '@/constants/picklists/brands';

export default {
  data() {
    return {
      teachingLevelOptions: [
        {
          value: 'Professor',
          get label() {
            return t('common.enums.teaching-level.professor');
          },
        },
        {
          value: 'Academic Lecturer',
          get label() {
            return t('common.enums.teaching-level.lecturer');
          },
        },
        {
          value: 'Wissenschaftlicher Mitarbeiter',
          get label() {
            return t('common.enums.teaching-level.scientific-employee');
          },
        },
        {
          value: 'Undefiniert',
          get label() {
            return t('common.enums.teaching-level.undefined');
          },
        },
      ],
      employmentTypeOptions: [
        {
          value: 'external',
          get label() {
            return t('common.enums.employment-type.external');
          },
        },
        {
          value: 'internal',
          get label() {
            return t('common.enums.employment-type.internal');
          },
        },
        {
          value: 'unset',
          get label() {
            return t('common.enums.teaching-level.undefined');
          },
        },
      ],
      brandOptions: BRANDS,
      unitsOptions: [],
      planningStatusOptions: [
        {
          value: 'active',
          get label() {
            return t('common.enums.planning-status.active');
          },
        },
        {
          value: 'inactive',
          get label() {
            return t('common.enums.planning-status.inactive');
          },
        },
      ],
      preferenceOptions: [
        {
          value: 'complete',
          get label() {
            return t('common.enums.preference-state.complete');
          },
        },
        {
          value: 'incomplete',
          get label() {
            return t('common.enums.preference-state.incomplete');
          },
        },
      ],
      headers: [
        {
          class: 'w-3/12 px-3',
          get label() {
            return t('common.labels.name');
          },
          key: 'name',
        },
        {
          class: 'w-2/12 px-3',
          get label() {
            return t('common.labels.teaching-level');
          },
          key: 'teachingLevel',
        },
        {
          class: 'w-2/12 px-3',
          get label() {
            return t('common.labels.employment-type');
          },
          key: 'employmentType',
        },
        {
          class: 'w-1/12 px-3',
          get label() {
            return t('common.labels.brand');
          },
          key: 'brand',
        },
        {
          class: 'w-2/12 px-3',
          get label() {
            return t('common.labels.units');
          },
          key: 'units',
        },
        {
          class: 'w-2/12 px-3',
          get label() {
            return t('common.labels.planning-status');
          },
          key: 'planningStatus',
        },
        {
          class: 'w-2/12 px-3',
          get label() {
            return t('common.labels.preference-state');
          },
          key: 'preferenceComplete',
        },
      ],
    };
  },
};
