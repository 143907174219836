import { t } from '@/plugins/translations';
import {
  createContactGuard,
  makeNestedRoutes,
} from '@/routes/make-nested-routes';
import EmailCreate from '@/views/profiles/emails/EmailCreate';
import EmailUpdate from '@/views/profiles/emails/EmailUpdate';

export const createEmails = makeNestedRoutes(
  { path: 'emails', name: 'emails' },
  [
    {
      path: 'create',
      name: 'create',
      components: {
        modal: EmailCreate,
      },
      props: {
        default: true,
        modal: true,
      },
      meta: {
        showModal: true,
        get modalTitle() {
          return t('views.profiles.emails.modal-title-add');
        },
      },
    },
    {
      path: 'update',
      name: 'create-update',
      components: {
        modal: EmailUpdate,
      },
      props: {
        default: true,
        modal: true,
      },
      meta: {
        showModal: true,
        get modalTitle() {
          return t('views.profiles.emails.modal-title-edit');
        },
      },
      beforeEnter: createContactGuard,
    },
    {
      path: 'delete',
      name: 'create-delete',
      meta: {
        showDeletionModal: true,
        resourceName: 'emails',
      },
      beforeEnter: createContactGuard,
    },
  ]
);
