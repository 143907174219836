<template>
  <view-wrapper v-if="isLoading">
    <ErrorBanner />
    <div class="flex flex-col items-center mt-16">
      <n-simple-loader />
      <p class="text-cta">{{ $t('common.labels.loading') }}</p>
    </div>
  </view-wrapper>

  <view-wrapper v-else>
    <CapabilitiesToolbar
      :has-capabilities="hasCapabilitiesAssigned"
      @open-search-modal="openSearchModal"
      @open-preferences-modal="openPreferencesModal"
    />
    <course-capabilities-table-banner class="mt-10" />

    <template v-if="hasCapabilitiesAssigned">
      <course-capabilities-preferences-last-edited
        class="mt-6 mb-10"
        :updated-by="lastUpdatedPreferencesMetadata?.lastUpdatedBy"
        :updated-at="lastUpdatedPreferencesMetadata?.lastUpdatedAt"
      ></course-capabilities-preferences-last-edited>

      <AssignedCourseCapabilitiesTable
        :data="capabilities"
        @delete-course-capability="handleDeleteCourseCapability"
      />
    </template>

    <empty-state v-else class="mt-10">
      <template #subheading>{{
        $t('views.course-capabilities.manage-course-capabilities.subheading')
      }}</template>
      <template #description>
        <span class="flex text-center">{{
          $t('common.course-capabilities.description-capabilities')
        }}</span>
      </template>
      <template #actions>
        <n-tooltip :content="assignCapabilitiesButtonTooltipText">
          <n-button
            class="mt-6"
            variant="primary"
            :disabled="!hasPermissionToAssignCapabilities"
            @click="openSearchModal"
          >
            <n-icon icon="plus" class="mr-2" />
            {{ $t('views.course-capabilities.manage-course-capabilities.add') }}
          </n-button>
        </n-tooltip>
      </template>
    </empty-state>
  </view-wrapper>

  <delete-course-capability-confirmation-modal
    v-if="selectedCapabilityToDelete"
    :course-capability-id="selectedCapabilityToDelete"
    :is-open="isModalOpen"
    @close="closeDeleteModal"
    @on-delete-confirmed="deleteAssignedCapability"
  />
  <course-search-modal ref="courseSearchModal" @saved="reloadAssignedCourses" />
  <set-preferences-modal
    ref="preferencesModal"
    @preferences-updated="handlePreferencesUpdated"
  />
</template>

<script>
import ErrorBanner from '@/components/common/ErrorBanner.vue';
import ViewWrapper from '@/components/common/ViewWrapper.vue';
import { mapActions, mapGetters } from 'vuex';
import DeleteCourseCapabilityConfirmationModal from '@/components/course-capabilities/Capabilities/DeleteCourseCapabilityConfirmationModal.vue';
import CourseSearchModal from '@/components/course-capabilities/Capabilities/CourseSearchModal.vue';
import SetPreferencesModal from '@/components/course-capabilities/Preferences/SetPreferencesModal.vue';
import CourseCapabilitiesTableBanner from '@/components/course-capabilities/Capabilities/CourseCapabilitiesTableBanner.vue';
import CourseCapabilitiesPreferencesLastEdited from '@/components/course-capabilities/Common/CourseCapabilitiesPreferencesLastEdited.vue';
import AssignedCourseCapabilitiesTable from '@/components/course-capabilities/Capabilities/AssignedCourseCapabilitiesTable.vue';
import CapabilitiesToolbar from '@/components/course-capabilities/Capabilities/CapabilitiesToolbar.vue';
import EmptyState from '@/components/common/EmptyState.vue';
import { hasPermissionFor } from '@/utils/permissionUtil';

export default {
  name: 'ManageCourseCapabilities',
  components: {
    ViewWrapper,
    ErrorBanner,
    DeleteCourseCapabilityConfirmationModal,
    CourseSearchModal,
    SetPreferencesModal,
    EmptyState,
    CourseCapabilitiesTableBanner,
    CourseCapabilitiesPreferencesLastEdited,
    AssignedCourseCapabilitiesTable,
    CapabilitiesToolbar,
  },
  props: {
    profileId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    selectedCapabilityToDelete: null,
  }),

  computed: {
    ...mapGetters('courseCapabilities', [
      'capabilities',
      'pagination',
      'isLoading',
      'lastUpdatedPreferencesMetadata',
      'isMetadataLoaded',
      'preferencesPristine',
    ]),
    routeQuery() {
      return this.$route.query;
    },
    isModalOpen() {
      return !!this.selectedCapabilityToDelete;
    },
    hasCapabilitiesAssigned() {
      return this.pagination?.totalCount > 0;
    },
    hasPermissionToAssignCapabilities() {
      return hasPermissionFor('academic-staff', ['u']);
    },
    assignCapabilitiesButtonTooltipText() {
      return !this.hasPermissionToAssignCapabilities
        ? 'Sie haben keine Berechtigung, Kurskompetenzen hinzufügen.'
        : '';
    },
  },

  watch: {
    routeQuery: {
      deep: true,
      immediate: true,
      handler: 'handleRouteQuery',
    },
  },

  methods: {
    ...mapActions('courseSearchModal', [
      'triggerSearchForCourses',
      'saveCourseCapabilityPreferencesForProfile',
    ]),
    ...mapActions('courseCapabilities', [
      'getLastUpdatedPreferencesMetadata',
      'getProfileCourseCapabilities',
      'deleteCourseCapability',
    ]),

    handleRouteQuery(query) {
      const { limit, offset } = query;

      if (!limit || !offset) {
        this.$router.replace({
          ...this.$route,
          query: {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          },
        });

        return;
      }

      this.getLastUpdatedPreferencesMetadata(this.profileId);

      this.getProfileCourseCapabilities({
        profileId: this.profileId,
        query: {
          ...query,
          locale: this.$i18n.locale,
        },
      });
    },

    handleDeleteCourseCapability(capabilityId) {
      this.selectedCapabilityToDelete = capabilityId;
    },
    openPreferencesModal() {
      this.$refs.preferencesModal.openModal(
        this.lastUpdatedPreferencesMetadata,
        this.preferencesPristine
      );
    },
    openSearchModal() {
      this.$refs.courseSearchModal.openModal(
        this.lastUpdatedPreferencesMetadata
      );
    },
    closeDeleteModal() {
      this.selectedCapabilityToDelete = null;
    },

    async deleteAssignedCapability(capability) {
      this.closeDeleteModal();
      await this.deleteCourseCapability(capability.id);
      await this.reloadAssignedCourses();
    },
    async reloadAssignedCourses() {
      await this.getProfileCourseCapabilities({
        profileId: this.profileId,
        query: { ...this.routeQuery, locale: this.$i18n.locale },
      });

      this.resetPaginationIfNecessary();
    },
    resetPaginationIfNecessary() {
      const emptyCapabilities = this.capabilities.length === 0;
      const hasEntries = this.pagination?.totalCount > 0;

      if (!(emptyCapabilities && hasEntries)) {
        return;
      }

      this.$router.replace({
        query: {
          offset: 0,
        },
      });
    },
    handlePreferencesUpdated() {
      this.reloadAssignedCourses();

      this.getLastUpdatedPreferencesMetadata(this.profileId);
    },
  },
};
</script>
