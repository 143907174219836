<template>
  <view-wrapper>
    <template #headline>{{
      $t('views.orchestrate-data-import.headline')
    }}</template>
    <ImportBanner class="mt-6 mb-4"></ImportBanner>
    <hr class="h-px my-12 bg-gray-200 border-0 dark:bg-gray-700" />
    <section class="mt-6">
      <div class="font-bold text-blue-600">
        <span class="uppercase">{{
          $t('views.orchestrate-data-import.fileupload')
        }}</span>
      </div>
      <FileImport class="mt-6" @file-uploaded="onFileUploaded" />
    </section>

    <hr class="h-px my-12 bg-gray-200 border-0 dark:bg-gray-700" />
    <section class="mt-6">
      <div class="font-bold text-blue-600">
        <span class="uppercase">{{
          $t('views.orchestrate-data-import.import-overview')
        }}</span>
      </div>
      <div class="mt-6">
        <ImportHistoryOverview />
      </div>
    </section>
  </view-wrapper>
</template>

<script>
import ViewWrapper from '@/components/common/ViewWrapper.vue';
import FileImport from '@/components/data-import/FileImport.vue';
import ImportBanner from '@/components/data-import/ImportBanner.vue';
import ImportHistoryOverview from '@/components/data-import/ImportHistoryOverviewTable.vue';
import { mapActions } from 'vuex';

export default {
  name: 'OrchestrateDataImport',
  components: {
    ImportHistoryOverview,
    ImportBanner,
    FileImport,
    ViewWrapper,
  },

  methods: {
    ...mapActions('dataImport', ['loadJobOverview']),
    onFileUploaded() {
      this.loadJobOverview();
    },
  },
};
</script>
