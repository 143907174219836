import { Api } from '@/api';

const performApiJobAndCommitToStore = async (
  context,
  apiMethod,
  mutation,
  payload = null
) => {
  context.commit('setLoading', true);
  try {
    const response = await apiMethod(payload);
    context.commit(mutation, response);
  } finally {
    context.commit('setLoading', false);
  }
};

export default {
  namespaced: true,
  state: {
    importOverview: [],
    detail: {
      unsuccessfulImports: [],
      errorCount: 0,
      updatedRowsCount: 0,
      skippedRowsCount: 0,
    },
    loading: false,
  },
  getters: {
    hasUnsuccessfulImports(state) {
      return state.detail.unsuccessfulImports?.length > 0;
    },
    getImportOverview(state) {
      return state.importOverview;
    },
    getJobDetail(state) {
      return state.detail;
    },
    getLoading(state) {
      return state.loading;
    },
    getUnsuccessfulImports(state) {
      return state.detail.unsuccessfulImports;
    },
    getErrorCount(state) {
      return state.detail.errorCount;
    },
    getUpdatedRowsCount(state) {
      return state.detail.updatedRowsCount;
    },
    getSkippedRowsCount(state) {
      return state.detail.skippedRowsCount;
    },
  },
  actions: {
    uploadFile(context, payload) {
      return Api.dataImport.uploadFile(payload);
    },
    async loadJobOverview(context) {
      await performApiJobAndCommitToStore(
        context,
        Api.dataImport.getJobOverview,
        'saveImportOverview'
      );
    },
    async loadJobDetail(context, id) {
      await performApiJobAndCommitToStore(
        context,
        Api.dataImport.getJobDetail,
        'saveJobDetail',
        id
      );
    },
  },
  mutations: {
    saveImportOverview(state, payload) {
      state.importOverview = payload;
    },
    saveJobDetail(state, payload) {
      state.detail = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
  },
};
