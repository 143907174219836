<template>
  <n-toolbar>
    <n-toolbar-button
      :disabled="!userCanCreateProfiles"
      :to="{ name: 'profile-create' }"
      :type="userCanCreateProfiles ? 'routerLink' : 'button'"
      :text="$t('views.profiles-overview.toolbar.add-lecturer')"
      class="epos-button--small mr-4"
      variant="tertiary"
      data-test="add-staff"
      icon="mdi-account-multiple-plus-outline"
      small
    />
    <n-toolbar-button
      v-if="userCanTriggerImport"
      :to="{ name: 'data-import' }"
      :type="'routerLink'"
      :text="$t('views.profiles-overview.toolbar.lecturer-import')"
      class="epos-button--small"
      variant="tertiary"
      data-test="import-staff"
      icon="mdi-file-import-outline"
      small
    />
  </n-toolbar>
</template>
<script>
export default {
  name: 'ProfilesListToolbar',
  props: {
    userCanCreateProfiles: {
      type: Boolean,
      required: true,
    },
    userCanTriggerImport: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
