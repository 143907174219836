import { isValidPhoneNumber } from 'libphonenumber-js';
import { required, regex, email, max, numeric } from '@vee-validate/rules';
import { after } from '@/plugins/veeValidate/after';
import { emptyOrAfter } from '@/plugins/veeValidate/EmptyOrAfter';
import { requiredIfTargetFilled } from '@/plugins/veeValidate/requiredIfTargetFilled';
export const ALPHA_SEPARATORS_REGEX = /[A-Za-zÀ-ȕ .,-]+$/;
export const IU_DOMAIN = 'iu.org';

const rulesMap = new Map();
rulesMap.set('required', required);
rulesMap.set('max', max);
rulesMap.set('regex', regex);
rulesMap.set('email', (value) => value && email(value));
rulesMap.set('iu_email', (value) => value && email(`${value}@${IU_DOMAIN}`));
rulesMap.set('phone', (value) => value && isValidPhoneNumber(value, 'DE'));
rulesMap.set('after', after);
rulesMap.set('emptyOrAfter', emptyOrAfter);
rulesMap.set('requiredIfTargetFilled', requiredIfTargetFilled);
rulesMap.set('numeric', numeric);
rulesMap.set('between', (value, { min, max }) => {
  const hasValue = value !== null;

  if (!hasValue) {
    return false;
  }

  const parsedValue = parseInt(value, 10);

  return parsedValue >= min && parsedValue <= max;
});
export { rulesMap };
