<template>
  <validation-form v-slot="{ meta }" :initial-values="initialValues">
    <div class="grid grid-cols-12 gap-x-6 gap-y-7 px-4">
      <div class="col-span-6">
        <validation-provider
          v-slot="{ errors, field }"
          name="Adresstyp"
          rules="required"
        >
          <n-select
            id="address-form__type"
            v-model="address.type"
            :label="$t('views.profiles.addresses.address-form.address-type')"
            name="Adresstyp"
            :errors="errors"
            v-bind="field"
            option-label="text"
            track-by="key"
            :options="addressTypes"
            required
          />
        </validation-provider>
      </div>
      <div class="col-span-12 flex space-x-2 gap-3">
        <validation-provider
          v-slot="{ errors, field }"
          name="Adresszusatz"
          rules="max:250"
        >
          <n-input
            id="address-form__addition"
            v-model.trim="address.additionToAddress"
            v-bind="field"
            :errors="errors"
            :label="
              $t('views.profiles.addresses.address-form.address-form-addition')
            "
            class="flex-grow"
            data-test="address-form-addition"
          />
        </validation-provider>
        <n-tooltip
          :content="
            $t(
              'views.profiles.addresses.address-form.address-form-addition-tooltip'
            )
          "
          class="items-center justify-center text-cta"
        >
          <n-icon icon="mdi-information-outline" class="icon-2xl" />
        </n-tooltip>
      </div>
      <div class="col-span-8">
        <validation-provider
          v-slot="{ errors, field }"
          name="Straße"
          rules="required|max:175"
        >
          <n-input
            id="address-form__street"
            v-model.trim="address.street"
            name="Straße"
            :errors="errors"
            v-bind="field"
            :label="$t('views.profiles.addresses.address-form.street')"
            data-test="address-form-street"
            required
          />
        </validation-provider>
      </div>
      <div class="col-span-4">
        <validation-provider
          v-slot="{ errors, field }"
          name="Hausnummer"
          rules="required|max:15"
        >
          <n-input
            id="address-form__house-number"
            v-model.trim="address.houseNumber"
            :errors="errors"
            v-bind="field"
            name="Hausnummer"
            :label="$t('views.profiles.addresses.address-form.house-number')"
            data-test="address-form-house-number"
            required
          />
        </validation-provider>
      </div>
      <div class="col-span-4">
        <validation-provider
          v-slot="{ errors, field }"
          name="Postleitzahl"
          rules="required|max:15"
        >
          <n-input
            id="address-form__zip"
            v-model.trim="address.zip"
            :errors="errors"
            v-bind="field"
            name="Postleitzahl"
            :label="$t('views.profiles.addresses.address-form.zip')"
            data-test="address-form-zip"
            required
          />
        </validation-provider>
      </div>
      <div class="col-span-8">
        <validation-provider
          v-slot="{ errors, field }"
          name="Ort"
          rules="required|max:100"
        >
          <n-input
            id="address-form__city"
            v-model.trim="address.city"
            :errors="errors"
            v-bind="field"
            :label="$t('views.profiles.addresses.address-form.city')"
            data-test="address-form-city"
            required
          />
        </validation-provider>
      </div>
      <div class="col-span-full">
        <validation-provider
          v-slot="{ errors, field }"
          name="Land"
          rules="required"
        >
          <n-select
            id="address-form__country"
            v-model="address.country"
            :label="$t('views.profiles.addresses.address-form.country')"
            name="Land"
            :errors="errors"
            v-bind="field"
            option-label="name"
            track-by="countryIso"
            :options="countries"
            required
          />
        </validation-provider>
      </div>
      <div class="col-span-full">
        <div class="text-caption italic text-gray-700">
          * {{ $t('common.labels.required') }}
        </div>
      </div>
      <div v-if="address.updatedAt" class="col-span-full">
        <p class="italic text-gray-700">
          {{ $t('common.labels.last-edited') }}
          {{ formatTimeStampToLocale(address.updatedAt) }}
          {{ $t('common.labels.by-user') }}
          {{ address.updatedBy }}
        </p>
      </div>
      <div class="col-span-full flex h-9">
        <n-button
          :disabled="!meta.valid || !meta.dirty || !hasChanged"
          variant="primary"
          class="mt-18 ml-auto"
          data-test="address-form-submit"
          @click="submit"
        >
          <n-icon icon="mdi-content-save-outline" class="mr-2" />
          {{ $t('common.labels.save') }}
        </n-button>
      </div>
    </div>
  </validation-form>
</template>

<script>
import { ADDRESS_TYPES } from '@/constants/picklists';
import { formatTimeStampToLocale } from '@/utils/formatters';
import _ from '@/plugins/lodash';
import { CMSListings } from '@epos/core-applets';

export default {
  name: 'AddressForm',
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'submit'],
  data() {
    return {
      address: {
        type: null,
        additionToAddress: '',
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
        country: 'DE',
      },
      countries: [],
    };
  },
  computed: {
    addressTypes() {
      // TODO: https://careerpartner.atlassian.net/browse/EP-1510
      return ADDRESS_TYPES;
    },
    hasChanged() {
      return !_.isEqual(this.modelValue, this.address);
    },
    initialValues() {
      if (!this.modelValue || Object.keys(this.modelValue).length <= 1) {
        return {
          Adresstyp: '',
          Adresszusatz: '',
          Straße: '',
          Hausnummer: '',
          Postleitzahl: '',
          Ort: '',
          Land: 'DE',
        };
      }

      return {
        Adresstyp: this.modelValue.type,
        Adresszusatz: this.modelValue.additionToAddress,
        Straße: this.modelValue.street,
        Hausnummer: this.modelValue.houseNumber,
        Postleitzahl: this.modelValue.zip,
        Ort: this.modelValue.city,
        Land: this.modelValue.country,
      };
    },
  },
  mounted() {
    this.address = { ...this.address, ...this.modelValue };
  },
  async created() {
    this.countries = await CMSListings.getCountries();
  },
  methods: {
    formatTimeStampToLocale,
    submit() {
      this.$emit('submit', this.address);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
