import { t } from '@/plugins/translations';
import {
  makeNestedRoutes,
  createContactGuard,
} from '@/routes/make-nested-routes';
import PhoneCreate from '@/views/profiles/phones/PhoneCreate';
import PhoneUpdate from '@/views/profiles/phones/PhoneUpdate';

export const createPhones = makeNestedRoutes(
  {
    path: 'phones',
    name: 'phones',
  },
  [
    {
      path: 'create',
      name: 'create',
      components: {
        modal: PhoneCreate,
      },
      props: {
        default: true,
        modal: true,
      },
      meta: {
        showModal: true,
        get modalTitle() {
          return t('views.profiles.phones.modal-title-add');
        },
      },
    },
    {
      path: 'update',
      name: 'create-update',
      components: {
        modal: PhoneUpdate,
      },
      props: {
        default: true,
        modal: true,
      },
      meta: {
        showModal: true,
        get modalTitle() {
          return t('views.profiles.phones.modal-title-edit');
        },
      },
      beforeEnter: createContactGuard,
    },
    {
      path: 'delete',
      name: 'create-delete',
      meta: {
        showDeletionModal: true,
        resourceName: 'phones',
      },
      beforeEnter: createContactGuard,
    },
  ]
);
