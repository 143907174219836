import { parse, isValid, format as formatDate } from 'date-fns';

export function parseDate(date, format) {
  if (typeof date !== 'string') {
    return isValid(date) ? date : null;
  }

  const parsed = parse(date, format, new Date());

  if (!isValid(parsed) || formatDate(parsed, format) !== date) {
    return null;
  }

  return parsed;
}
