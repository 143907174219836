import { ApiClient } from '@/api/ApiClient';
import { PROFILES_BASE_ROUTE } from '@/api/profiles';

const DEPUTATION_BASE_ROUTE = 'deputation';

export const deputation = {
  getOverview: (profileId, payload) =>
    ApiClient.instance.post(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${DEPUTATION_BASE_ROUTE}/overview`,
      payload
    ),
  getMonthly: (profileId, payload) =>
    ApiClient.instance.post(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${DEPUTATION_BASE_ROUTE}/monthly`,
      payload
    ),
  getQuarterly: (profileId, payload) =>
    ApiClient.instance.post(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${DEPUTATION_BASE_ROUTE}/quarterly`,
      payload
    ),
  getSemester: (profileId, payload) =>
    ApiClient.instance.post(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${DEPUTATION_BASE_ROUTE}/semester`,
      payload
    ),
  isAvailable: (profileId) =>
    ApiClient.instance.get(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${DEPUTATION_BASE_ROUTE}/available`
    ),
};
