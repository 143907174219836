<template>
  <div class="flex">
    <div class="w-3/4 text-blue-600">
      <p class="text-base mb-4">
        {{ $t('views.data-import-import-banner.head-sub') }}
      </p>
      <h4 class="text-l font-bold mb-4">
        {{ $t('views.data-import-import-banner.lecturer') }}
      </h4>
      <!-- eslint-disable vue/no-v-html -->
      <p class="text-base mb-4" v-html="sanitizedLecturerFormat"></p>
      <!-- eslint-enable vue/no-v-html -->
      <h4 class="text-l font-bold mb-4">
        {{ $t('views.data-import-import-banner.course-whitelist') }}
      </h4>
      <p class="text-base">
        {{ $t('views.data-import-import-banner.course-whitelist-format') }}
      </p>
    </div>
  </div>
</template>
<script>
import DOMPurify from 'dompurify';

export default {
  name: 'ImportBanner',
  computed: {
    sanitizedLecturerFormat() {
      const rawContent = this.$t(
        'views.data-import-import-banner.lecturer-format'
      );

      return DOMPurify.sanitize(rawContent);
    },
  },
};
</script>
