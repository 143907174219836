<template>
  <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
    <div v-for="(value, key) in deputationData" :key="key">
      <DeputationDetailCard
        :key="key"
        :deputation-data="value"
        :group-type="groupType"
        :group-value="key"
      >
      </DeputationDetailCard>
    </div>
  </div>
</template>
<script>
import DeputationDetailCard from '@/components/deputation/Deputation/Detail/DeputationDetailCard.vue';

export default {
  name: 'DeputationDetailGrid',
  components: { DeputationDetailCard },
  props: {
    groupType: {
      type: String,
      required: true,
    },
    deputationData: {
      type: Object,
      required: true,
    },
  },
};
</script>
