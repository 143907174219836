export default {
  namespaced: true,
  state: {
    contacts: [],
  },
  getters: {
    isContact: (state) => (contactId) => {
      return state.contacts.some((contact) => contact.id === contactId);
    },
    getContact: (state) => (contactId) => {
      return state.contacts.find((contact) => contact.id === contactId);
    },
  },
  actions: {
    insertContact({ commit }, contact) {
      commit('insertContact', contact);
    },
    updateContact({ commit }, contact) {
      commit('updateContact', contact);
    },
    deleteContact({ commit }, contactId) {
      commit('deleteContact', contactId);
    },
  },
  mutations: {
    insertContact(state, contact) {
      state.contacts.push(contact);
    },
    updateContact(state, contact) {
      const index = state.contacts.findIndex((c) => c.id === contact.id);
      state.contacts[index] = contact;
    },
    deleteContact(state, contactId) {
      state.contacts = state.contacts.filter(
        (contact) => contact.id !== contactId
      );
    },
  },
};
