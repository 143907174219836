<template>
  <div class="flex items-center">
    <icon-with-rounded-background icon="clock-outline" class="mr-2" />
    <p class="text-blue-600 text-base">
      {{ $t('views.location.campus-preferences-hint.hint') }}
    </p>
  </div>
</template>

<script>
import IconWithRoundedBackground from '@/components/common/IconWithRoundedBackground.vue';

export default {
  name: 'LocationPreferencesHint',
  components: { IconWithRoundedBackground },
};
</script>
