<template>
  <n-modal
    :is-open="isModalOpen"
    :title="$t('views.course-capabilities.edit-course-preferences')"
    class="relative"
    :large-deprecated="true"
    @close="closeModal"
  >
    <div class="mb-9">
      <course-capabilities-preferences-last-edited
        v-if="!isPreferencesPristine"
        :updated-by="lastUpdatedPreferencesMetadata?.lastUpdatedBy"
        :updated-at="lastUpdatedPreferencesMetadata?.lastUpdatedAt"
      ></course-capabilities-preferences-last-edited>
      <div v-else class="flex items-center">
        <icon-with-rounded-background icon="information-outline" class="mr-2" />
        {{
          $t('views.course-capabilities.preferences.set-preferences-modal.info')
        }}
      </div>
    </div>
    <div class="mb-9">
      <n-input
        v-model="localSearchTerm"
        icon="magnify"
        :label="$t('views.course-capabilities.search-for-course')"
        @update:model-value="handleSearch"
      />
    </div>
    <div class="mb-9">
      <SearchFilter
        :filters="appliedSearchFilters"
        @reset="resetSearchFilter"
        @reset-all="resetAllSearchFilters"
      />
    </div>
    <div class="mb-9">
      <select-preferences-table
        :study-programs-filter="selectedStudyPrograms"
        :is-loading="isLoading"
        :data="assignedCapabilities"
        :study-programs="studyPrograms"
        @preferences-changed="updatePreferences"
        @update:study-programs-filter="updateStudyProgramsFilter"
      />
    </div>
    <div class="mb-9">
      <n-pagination
        v-model="localPagination"
        :limit-options="[]"
        @update:model-value="paginate"
      />
    </div>

    <div class="flex justify-end">
      <n-button
        variant="primary"
        :disabled="isSaveDisabled"
        @click="handleSavePreferences"
      >
        {{
          $t('views.course-capabilities.preferences.set-preferences-modal.save')
        }}
      </n-button>
    </div>
  </n-modal>
</template>
<script>
import SelectPreferencesTable from '@/components/course-capabilities/Preferences/SelectPreferencesTable.vue';
import SearchFilter from '@/components/common/SearchFilter.vue';
import CourseCapabilitiesPreferencesLastEdited from '@/components/course-capabilities/Common/CourseCapabilitiesPreferencesLastEdited.vue';

import { createNamespacedHelpers } from 'vuex';
import IconWithRoundedBackground from '@/components/common/IconWithRoundedBackground.vue';

const { mapActions, mapGetters } = createNamespacedHelpers(
  'setPreferencesModal'
);

const { mapGetters: studyProgramsGetter, mapActions: studyProgramsAction } =
  createNamespacedHelpers('studyPrograms');

export default {
  name: 'SetPreferencesModal',
  components: {
    IconWithRoundedBackground,
    CourseCapabilitiesPreferencesLastEdited,
    SelectPreferencesTable,
    SearchFilter,
  },
  emits: ['preferences-updated'],
  data() {
    return {
      isModalOpen: false,
      filters: [],
      selectedPreferences: [],
      localPagination: {},
      localSearchTerm: '',
      lastUpdatedPreferencesMetadata: null,
      isPreferencesPristine: false,
      queryParams: { searchTerm: '', studyProgramsFilter: [] },
    };
  },
  computed: {
    ...mapGetters([
      'assignedCapabilities',
      'isSaving',
      'isLoading',
      'pagination',
      'searchTerm',
      'selectedStudyPrograms',
      'appliedSearchFilters',
    ]),
    ...studyProgramsGetter(['studyPrograms']),
    isSaveDisabled() {
      return this.isSaving || this.isLoading || !this.hasChanged;
    },
    hasChanged() {
      return this.selectedPreferences.length > 0;
    },
  },
  watch: {
    pagination: {
      deep: true,
      handler(newVal) {
        this.localPagination = newVal;
      },
    },
    searchTerm: {
      handler(newVal) {
        this.localSearchTerm = newVal;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      triggerSearch: 'searchAssignedCapabilities',
      saveCapabilities: 'savePreferenceUpdates',
      updateSearchTerm: 'updateSearchTerm',
      updateStudyProgramsFilter: 'updateStudyProgramsFilter',
      updatePagination: 'updatePagination',
      resetSearchFilters: 'resetSearch',
      resetModal: 'resetModal',
    }),
    ...studyProgramsAction(['getStudyPrograms']),
    async openModal(lastUpdatedPreferencesMetadata, isPreferencesPristine) {
      this.isModalOpen = true;
      this.isPreferencesPristine = isPreferencesPristine;
      this.lastUpdatedPreferencesMetadata = lastUpdatedPreferencesMetadata;
      this.selectedPreferences = [];

      await this.triggerSearch();
      await this.getStudyPrograms(this.$i18n.locale);
    },
    closeModal() {
      this.resetModal();

      this.isModalOpen = false;
    },

    paginate({ limit, offset }) {
      if (
        this.pagination.limit === limit &&
        this.pagination.offset === offset
      ) {
        return;
      }

      this.updatePagination({ limit, offset });
    },
    resetSearchFilter(key) {
      if (key === 'searchTerm') {
        this.updateSearchTerm('');
      } else if (key === 'studyProgramsFilter') {
        this.resetSearchFilters();
      }
    },

    async resetAllSearchFilters() {
      await this.resetModal();

      await this.triggerSearch();
    },

    updatePreferences(newPreferences) {
      this.selectedPreferences = newPreferences;
    },
    async handleSavePreferences() {
      await this.saveCapabilities(this.selectedPreferences);

      this.closeModal();

      this.$emit('preferences-updated');
    },
    handleSearch() {
      this.updateSearchTerm(this.localSearchTerm);
    },
  },
};
</script>
