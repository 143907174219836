<template>
  <div class="flex items-center">
    <icon-with-rounded-background icon="clock-outline" class="mr-2" />
    <p class="text-blue-600 text-base">
      Campuspräferenzset zuletzt geändert von
      <span class="font-bold">
        <a
          v-if="userFullName"
          :href="`/services/iam/users/${profileId}/profile`"
        >
          {{ userFullName }}
        </a>
        <template v-else> Unbekannt </template>
      </span>
      am
      {{ formattedUpdatedAt }}
    </p>
  </div>
</template>
<script>
import { iamApi } from '@/api/iam';
import IconWithRoundedBackground from '@/components/common/IconWithRoundedBackground.vue';
export default {
  name: 'LocationPreferencesLastEdited',
  components: { IconWithRoundedBackground },
  props: {
    updatedBy: {
      type: String,
      default: null,
    },
    updatedAt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      profileId: null,
      userFullName: null,
    };
  },
  computed: {
    formattedUpdatedAt() {
      return new Intl.DateTimeFormat('default', { dateStyle: 'short' }).format(
        new Date(this.updatedAt)
      );
    },
  },
  watch: {
    updatedBy: {
      immediate: true,
      async handler(profileId) {
        this.userFullName = null;
        this.profileId = profileId;

        if (profileId) {
          this.userFullName = await this.fetchUser(profileId);
        }
      },
    },
  },
  methods: {
    async fetchUser(profileId) {
      try {
        const user = await iamApi.getUser(profileId);
        return `${user.givenName} ${user.familyName}`;
      } catch (e) {
        return 'Unbekannt';
      }
    },
  },
};
</script>
