import { Api } from '@/api';

export default {
  namespaced: true,
  state: {
    currentProfileId: null,
    isLoading: false,
    capabilities: [],
    pagination: {
      limit: 20,
      offset: 0,
      totalCount: 0,
    },
    lastUpdatedPreferencesMetadata: null,
    isMetadataLoaded: false,
  },
  getters: {
    currentProfileId(state) {
      return state.currentProfileId;
    },
    isLoading(state) {
      return state.isLoading;
    },
    capabilities(state) {
      return state.capabilities;
    },
    pagination(state) {
      return state.pagination;
    },
    lastUpdatedPreferencesMetadata(state) {
      return state.lastUpdatedPreferencesMetadata;
    },
    isMetadataLoaded(state) {
      return state.isMetadataLoaded;
    },
    preferencesPristine(state) {
      return (
        state.lastUpdatedPreferencesMetadata === null && state.isMetadataLoaded
      );
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    updateCurrentProfileId({ commit }, profileId) {
      commit('updateCurrentProfileId', profileId);
    },
    async getProfileCourseCapabilities(
      { commit, dispatch },
      { profileId, query }
    ) {
      const { limit, offset, locale } = query;

      commit('isLoading', true);
      try {
        const response = await Api.courseCapabilities.getByProfileId(
          profileId,
          { limit, offset, locale }
        );
        commit('updateCourseCapabilities', response.data.data);

        commit('updatePagination', {
          limit,
          offset,
          totalCount: response?.data?.pagination?.totalCount ?? 0,
        });
      } catch (e) {
        commit('updateCourseCapabilities', []);

        await dispatch('errors/pushError', e, { root: true });
      } finally {
        commit('isLoading', false);
      }
    },
    async deleteCourseCapability({ commit, dispatch }, capabilityId) {
      commit('isLoading', true);
      try {
        await Api.courseCapabilities.delete(capabilityId);
      } catch (e) {
        await dispatch('errors/pushError', e, { root: true });
      } finally {
        commit('isLoading', false);
      }
    },
    updatePagination({ commit, state }, pagination) {
      commit('updatePagination', {
        ...state.pagination,
        ...pagination,
      });
    },
    async getLastUpdatedPreferencesMetadata({ commit }, profileId) {
      try {
        const response =
          await Api.courseCapabilities.getLastUpdatedPreferencesMetadata(
            profileId
          );

        const mappedResponse =
          !response.data || response.data === '' ? null : response.data;

        commit('lastUpdatedPreferencesMetadata', mappedResponse);
      } catch (e) {
        commit('lastUpdatedPreferencesMetadata', null);
      } finally {
        commit('isMetadataLoaded', true);
      }
    },
  },
  mutations: {
    updateCurrentProfileId(state, profileId) {
      state.currentProfileId = profileId;
    },
    isLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    updateCourseCapabilities(state, courseCapabilities) {
      state.capabilities = courseCapabilities;
    },
    updatePagination(state, pagination) {
      state.pagination = pagination;
    },
    resetState(state) {
      state.currentProfileId = null;
      state.courseCapabilities = [];
      state.isLoading = false;
    },
    lastUpdatedPreferencesMetadata(state, metadata) {
      state.lastUpdatedPreferencesMetadata = metadata;
    },
    isMetadataLoaded(state, isLoaded) {
      state.isMetadataLoaded = isLoaded;
    },
  },
};
