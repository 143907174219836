<template>
  <n-table
    :header-items="headerItems"
    :data="importOverview"
    :is-loading="isLoading"
  >
    <template
      v-for="header in headerItems"
      #[`th-${header.key}`]="{ item }"
      :key="header.key"
    >
      <span class="mr-2">{{ item.label }}</span>
    </template>
    <template #row="{ isExpanded, item }">
      <n-table-row :item="item">
        <td>{{ item.id }}</td>
        <td>{{ getFormattedDate(item.createdAt) }}</td>
        <td>{{ item.taskStatus }}</td>
        <td>{{ item.currentStep }}</td>
        <template #extended>
          <DetailedImportSummaryTable
            :expanded="isExpanded"
            :import-job="item"
          ></DetailedImportSummaryTable>
        </template>
      </n-table-row>
    </template>
  </n-table>
</template>

<script>
import { t } from '@/plugins/translations';
import { mapActions, mapGetters } from 'vuex';
import DetailedImportSummaryTable from '@/components/data-import/DetailedImportSummaryTable.vue';

export default {
  name: 'ImportHistoryOverviewTable',
  components: {
    DetailedImportSummaryTable,
  },
  data() {
    return {
      headerItems: [
        { class: 'w-4/12 px-3', label: 'Import Id', key: 'id' },
        {
          class: 'w-2/12 px-3',
          get label() {
            return t(
              'views.data-import-import-history-overview-table.header.date'
            );
          },
          key: 'createdAt',
        },
        {
          class: 'w-4/12 px-3',
          get label() {
            return t(
              'views.data-import-import-history-overview-table.header.state'
            );
          },
          key: 'taskStatus',
        },
        {
          class: 'w-2/12 px-3',
          get label() {
            return t(
              'views.data-import-import-history-overview-table.header.step'
            );
          },
          key: 'currentStep',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('dataImport', {
      importOverview: 'getImportOverview',
      isLoading: 'getLoading',
    }),
  },
  async mounted() {
    await this.loadJobOverview();
  },
  methods: {
    ...mapActions('dataImport', ['loadJobOverview']),
    getFormattedDate(date) {
      return new Intl.DateTimeFormat('default', {
        dateStyle: 'short',
      }).format(new Date(date));
    },
  },
};
</script>
