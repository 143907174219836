import { t } from '@/plugins/translations';
import { convertTranslatedString } from '@/utils/translated-string';
import { getTextByKey } from '@/constants/picklists/index';

export const CAPABILITY_TRAIT = {
  CAN_TEACH_ONLINE: 'canTeachOnline',
  CAN_TEACH_ONSITE: 'canTeachOnsite',
  CAN_CORRECT: 'canCorrect',
  CAN_AUDIT_EXAMS: 'canAuditExams',
  CAN_SUPERVISE_THESIS: 'canSuperviseThesis',
  CAN_MANAGE_MEDIA: 'canManageMedia',
  EXTRA: 'extra',
};

export const CAPABILITIES = Object.freeze([
  {
    key: CAPABILITY_TRAIT.CAN_TEACH_ONLINE,
    get text() {
      return t('common.enums.university-capabilities.canTeachOnline');
    },
    icon: 'mdi-globe-model',
  },
  {
    key: CAPABILITY_TRAIT.CAN_TEACH_ONSITE,
    get text() {
      return t('common.enums.university-capabilities.canTeachOnsite');
    },
    icon: 'mdi-account-supervisor-circle-outline',
  },
  {
    key: CAPABILITY_TRAIT.CAN_CORRECT,
    get text() {
      return t('common.enums.university-capabilities.canCorrect');
    },
    icon: 'mdi-text-box-check-outline',
  },
  {
    key: CAPABILITY_TRAIT.CAN_AUDIT_EXAMS,
    get text() {
      return t('common.enums.university-capabilities.canAuditExams');
    },
    icon: 'mdi-eye-outline',
  },
  {
    key: CAPABILITY_TRAIT.CAN_SUPERVISE_THESIS,
    get text() {
      return t('common.enums.university-capabilities.canSuperviseThesis');
    },
    icon: 'mdi-file-find-outline',
  },
  {
    key: CAPABILITY_TRAIT.CAN_MANAGE_MEDIA,
    get text() {
      return t('common.enums.university-capabilities.canManageMedia');
    },
    icon: 'mdi-youtube-studio',
  },
  {
    key: CAPABILITY_TRAIT.EXTRA,
    get text() {
      return CAPABILITY_TRAIT.EXTRA;
    },
    icon: 'mdi-youtube-studio',
  },
]);

export function getTextByCapability(capability) {
  if (capability?.capability !== CAPABILITY_TRAIT.EXTRA) {
    return getTextByKey(CAPABILITIES, capability?.capability);
  } else {
    return convertTranslatedString(capability.assignmentCategoryTitle);
  }
}
