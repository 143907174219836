export function capitalize(str, all = false) {
  if (str.includes(' ') && all) {
    return str
      .split(' ')
      .map((word) => capitalize(word))
      .join(' ');
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
