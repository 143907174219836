import { Api } from '@/api';

export default {
  namespaced: true,
  state: {
    planningPeriods: [],
  },
  getters: {
    planningPeriods: (state) => state.planningPeriods,
  },
  actions: {
    async loadPlanningPeriods({ commit }) {
      commit('errors/clearErrors', null, { root: true });
      commit('setLoading', true);
      try {
        const resp = await Api.planningPeriod.get();

        commit('updatePlanningPeriods', resp?.data?.data ?? []);
      } catch (e) {
        commit('updatePlanningPeriods', []);
      } finally {
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    updatePlanningPeriods(state, planningPeriods) {
      state.planningPeriods = planningPeriods;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
};
