<template>
  <n-chip :color="chipColor">{{ state }}</n-chip>
</template>

<script>
import { AssignmentLifeCycle } from '@/constants/assignment-status';

export default {
  name: 'AssignmentStatusChip',
  props: {
    state: {
      type: String,
      required: true,
    },
  },
  computed: {
    chipColor() {
      switch (this.state) {
        case AssignmentLifeCycle.CREATED:
          return 'light-blue';
        case AssignmentLifeCycle.ACCEPTED:
          return 'blue';
        case AssignmentLifeCycle.STARTED:
          return 'green';
        case AssignmentLifeCycle.ENDED:
          return 'pink';
        case AssignmentLifeCycle.REFUSED:
          return 'red';
        default:
          return 'gray';
      }
    },
  },
};
</script>
