<template>
  <div>
    <p class="mt-1">{{ address.street }} {{ address.houseNumber }}</p>
    <p class="mt-1">
      {{ address.additionToAddress }}
    </p>
    <p class="mt-1">{{ address.zip }} {{ address.city }}</p>
    <p class="mt-1">
      {{ getCountryNameByIsoCode(address.country) }}
    </p>
  </div>
</template>

<script>
import { CMSListings } from '@epos/core-applets';

export default {
  name: 'AddressData',
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      countries: [],
    };
  },
  async created() {
    this.countries = await CMSListings.getCountries();
  },
  methods: {
    getCountryNameByIsoCode(isoCode) {
      const country = this.countries.find(
        (country) => country.countryIso === isoCode
      );

      return country?.name;
    },
  },
};
</script>
