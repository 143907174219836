<template>
  <n-modal
    :title="$t('common.labels.confirmation-required')"
    :is-open="isOpen"
    @close="closeModal"
  >
    <template #default>
      <p class="text-base">
        {{ $t('views.deputation.delete-assignment-confirmation-modal.note') }}
      </p>
    </template>
    <template #actions>
      <n-button variant="secondary" @click="closeModal">Abbrechen</n-button>
      <n-button
        class="danger"
        variant="primary"
        @click="$emit('onDeleteConfirmed', assignmentId)"
      >
        {{
          $t(
            'views.deputation.delete-assignment-confirmation-modal.delete-assignment'
          )
        }}
      </n-button>
    </template>
  </n-modal>
</template>

<script>
export default {
  name: 'DeleteAssignmentConfirmationModal',
  emits: ['onDeleteConfirmed'],
  data() {
    return {
      assignmentId: null,
      isOpen: false,
    };
  },
  methods: {
    openModal(assignmentId) {
      this.isOpen = true;
      this.assignmentId = assignmentId;
    },
    closeModal() {
      this.isOpen = false;
      this.assignmentId = null;
    },
  },
};
</script>
