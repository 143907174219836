import { t } from '@/plugins/translations';

export const AvailableDeputationStatus = Object.freeze({
  UNDERDEPUTATION: 'under',
  OVERDEPUTATION: 'over',
  ONTARGET: 'onTarget',
});

export const DEPUTATION_STATUS = Object.freeze([
  {
    get text() {
      return t('common.enums.deputation-status.underdeputation');
    },
    key: AvailableDeputationStatus.UNDERDEPUTATION,
  },
  {
    get text() {
      return t('common.enums.deputation-status.ontarget');
    },
    key: AvailableDeputationStatus.ONTARGET,
  },
  {
    get text() {
      return t('common.enums.deputation-status.overdeputation');
    },
    key: AvailableDeputationStatus.OVERDEPUTATION,
  },
]);

export const getDeputationStatusColorTuple = (status) => {
  if (status === AvailableDeputationStatus.UNDERDEPUTATION) {
    return {
      filledColor: 'yellow-600',
      backgroundColor: 'yellow-200',
    };
  }

  if (status === AvailableDeputationStatus.ONTARGET) {
    return {
      filledColor: 'green-600',
      backgroundColor: 'green-200',
    };
  }

  if (status === AvailableDeputationStatus.OVERDEPUTATION) {
    return {
      filledColor: 'error',
      backgroundColor: 'error',
    };
  }
};
