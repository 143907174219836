<template>
  <n-table
    :header-items="headerItems"
    :data="locations"
    :is-loading="isLoading"
  >
    <template #row="{ item: location }">
      <n-table-row
        :class="{
          'bg-[#c0c9e8]': hasUpdatedPreferences(location.locationId),
        }"
      >
        <td class="font-bold text-blue-600">
          {{ location.location.campus }}
        </td>
        <td class="text-blue-600">
          {{ mapSingleUnitName(location.location.unit) }}
        </td>
        <td class="pl-3">
          <set-location-preference-chip
            :editable="!isMainLocation(location)"
            :selected-value="findPreferenceValue(location)"
            @select="
              updatePreferences($event, location.locationId, location.id)
            "
          />
        </td>
      </n-table-row>
    </template>
  </n-table>
</template>

<script>
import { t } from '@/plugins/translations';
import { PREFERENCES } from '@/constants/picklists/preferences';
import SetLocationPreferenceChip from '@/components/location/SetLocationPreferenceChip.vue';
import { mapSingleUnitName } from '@/utils/units-name-mapper';

const TABLE_HEADERS = [
  { class: 'w-4/12 px-3', label: 'Campus', key: 'campus' },
  { class: 'w-3/12 px-3', label: 'Unit', key: 'unit' },
  {
    class: 'w-2/12 px-3',
    get label() {
      return t('common.labels.preference');
    },
    key: 'preference',
  },
];

export default {
  name: 'SelectLocationPreferencesTable',
  components: { SetLocationPreferenceChip },
  props: {
    locations: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    mainLocation: { type: Object, default: () => null },
  },
  emits: ['preferences-changed'],
  data() {
    return {
      headerItems: TABLE_HEADERS,
      selectedPreferences: new Map(),
    };
  },
  computed: {
    transformedPreferences() {
      return Array.from(
        this.selectedPreferences,
        ([{ locationId, id }, preference]) => ({
          locationId,
          preference,
          id,
        })
      );
    },
  },
  methods: {
    mapSingleUnitName,
    isMainLocation(location) {
      return (
        this.mainLocation &&
        location.location.unit === this.mainLocation.unit &&
        location.location.campus === this.mainLocation.campus
      );
    },
    hasUpdatedPreferences(locationId) {
      return this.transformedPreferences.some(
        (location) => location.locationId === locationId
      );
    },
    findPreferenceValue(location) {
      const hasSelectedPreference =
        this.hasLocationSelectedPreference(location);
      const isPreferenceUnknown = this.isLocationPreferenceUnknown(location);

      if (hasSelectedPreference || isPreferenceUnknown) {
        return this.getSelectedPreferenceOrDefault(location);
      }

      return this.isMainLocation(location)
        ? PREFERENCES.POSITIVE.value
        : location.preference;
    },
    hasLocationSelectedPreference(location) {
      return (
        this.transformedPreferences.find(
          (preference) =>
            location.locationId === preference.locationId &&
            location.id === preference.id
        ) !== undefined
      );
    },
    isLocationPreferenceUnknown(location) {
      return location.preference === PREFERENCES.UNKNOWN.value;
    },
    getSelectedPreferenceOrDefault(location) {
      if (this.isMainLocation(location)) {
        return PREFERENCES.POSITIVE.value;
      }

      return (
        this.transformedPreferences.find(
          (preference) =>
            location.locationId === preference.locationId &&
            location.id === preference.id
        )?.preference?.value || PREFERENCES.NEGATIVE.value
      );
    },
    updatePreferences(preference, locationId, id) {
      this.selectedPreferences.set({ locationId, id }, preference);

      this.$emit('preferences-changed', this.transformedPreferences);
    },
  },
};
</script>
