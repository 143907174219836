import { ApiClient } from '@/api/ApiClient';
import { PROFILES_BASE_ROUTE } from '@/api/profiles';
import { pluckData } from '@/utils/pluckData';

const LOCATION_PREFERENCES_BASE_ROUTE = 'locations';

export const locationPreferences = {
  getLastUpdatedPreferencesMetadata: (profileId) =>
    ApiClient.instance.get(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${LOCATION_PREFERENCES_BASE_ROUTE}/preferences/last-updated-metadata`
    ),
  setPreferences: (profileId, payload) =>
    ApiClient.instance.patch(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${LOCATION_PREFERENCES_BASE_ROUTE}/preferences`,
      payload
    ),
  searchPreferences: (profileId, params) =>
    ApiClient.instance
      .get(
        `/${PROFILES_BASE_ROUTE}/${profileId}/${LOCATION_PREFERENCES_BASE_ROUTE}/preferences`,
        {
          params,
        }
      )
      .then(pluckData),
};
