<template>
  <n-modal
    :title="$t('common.labels.confirmation-required')"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template #default>
      <p class="text-base">
        {{
          $t(
            'views.course-capabilities.capabilities.delete-course-capability-confirmation-modal.note'
          )
        }}
      </p>
    </template>
    <template #actions>
      <n-button variant="secondary" @click="$emit('close')">Abbrechen</n-button>
      <n-button
        class="danger"
        variant="primary"
        @click="$emit('onDeleteConfirmed', courseCapabilityId)"
      >
        {{
          $t(
            'views.course-capabilities.capabilities.delete-course-capability-confirmation-modal.delete-capability'
          )
        }}
      </n-button>
    </template>
  </n-modal>
</template>

<script>
export default {
  name: 'DeleteCourseCapabilityConfirmationModal',
  props: {
    courseCapabilityId: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'onDeleteConfirmed'],
};
</script>
