export const WEEKDAYS = [
  { key: 'MONDAY', short: 'MO', index: 0 },
  { key: 'TUESDAY', short: 'DI', index: 1 },
  { key: 'WEDNESDAY', short: 'MI', index: 2 },
  { key: 'THURSDAY', short: 'DO', index: 3 },
  { key: 'FRIDAY', short: 'FR', index: 4 },
];

export const COLORS = {
  NEUTRAL: 'gray',
  POSITIVE: 'green',
  NEGATIVE: 'red',
};

export const HEADERS = (() => {
  const headers = [
    {
      class: 'w-2/12 text-center',
      label: '',
      key: 'slots',
    },
  ];

  for (const weekday of WEEKDAYS) {
    headers.push({
      class: 'w-2/12 text-center',
      label: weekday.short,
      key: 'slots',
    });
  }
  return headers;
})();
