<template>
  <template v-if="isUnknownPreference">
    <n-tooltip :content="$t('views.location.set-location-preference-chip')">
      <div class="pl-8">-</div>
    </n-tooltip>
  </template>
  <template v-else>
    <n-chip v-if="!editable" :color="currentPreference.chipColor">
      {{ currentPreference.text }}
    </n-chip>
    <n-menu v-else>
      <template #activator="{ toggle: openMenu, isOpen: isMenuOpen }">
        <n-chip
          :color="currentPreference.chipColor"
          :is-open="isMenuOpen"
          @click="openMenu"
        >
          {{ currentPreference.text }}
        </n-chip>
      </template>
      <template #default="{ close: closeMenu }">
        <n-menu-list class="-ml-3 px-3 py-2 space-y-1">
          <li v-for="option in availableOptions" :key="option.text">
            <n-chip
              :color="option.chipColor"
              @click="selectOption(option, closeMenu)"
            >
              {{ option.text }}
            </n-chip>
          </li>
        </n-menu-list>
      </template>
    </n-menu>
  </template>
</template>

<script>
import {
  DEFAULT_PREFERENCE,
  findPreference,
  PREFERENCES,
} from '@/constants/picklists/preferences';

import { availableOptions } from '@/utils/preferences';

export default {
  name: 'SetLocationPreferenceChip',
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    selectedValue: {
      type: String,
      default: DEFAULT_PREFERENCE,
    },
  },
  emits: ['select'],
  data() {
    return {
      currentPreference:
        findPreference(this.selectedValue) || DEFAULT_PREFERENCE,
    };
  },
  computed: {
    isUnknownPreference() {
      return this.selectedValue === PREFERENCES.UNKNOWN.value;
    },
    availableOptions() {
      return availableOptions(this.currentPreference);
    },
  },
  watch: {
    selectedValue(val) {
      this.currentPreference = findPreference(val) || DEFAULT_PREFERENCE;
    },
  },
  methods: {
    selectOption(option, closeMenu) {
      this.currentPreference = option;

      this.$emit('select', option);

      closeMenu();
    },
  },
};
</script>
