<template>
  <n-table
    :header-items="headerItems"
    :data="locations"
    :is-loading="isLoading"
  >
    <template #row="{ item: location }">
      <n-table-row
        :class="{
          'bg-[#c0c9e8]': hasUpdatedPreferences(location.locationId),
        }"
      >
        <td class="font-bold text-blue-600">
          {{ location.location.campus }}
        </td>
        <td class="text-blue-600">
          {{ mapSingleUnitName(location.location.unit) }}
        </td>
        <td class="pl-3">
          <set-location-preference-chip
            :editable="false"
            :selected-value="findPreferenceValue(location)"
          />
        </td>
      </n-table-row>
    </template>
  </n-table>
  <n-pagination
    v-if="assignedPagination?.totalCount > 0"
    class="mt-16"
    :model-value="assignedPagination"
    @update:model-value="onPaginationChanged"
  />
</template>

<script>
import { t } from '@/plugins/translations';
import {
  DEFAULT_PREFERENCE,
  PREFERENCES,
} from '@/constants/picklists/preferences';
import SetLocationPreferenceChip from '@/components/location/SetLocationPreferenceChip.vue';
import { mapState } from 'vuex';
import { mapSingleUnitName } from '@/utils/units-name-mapper';

const TABLE_HEADERS = [
  { class: 'w-4/12 px-3', label: 'Campus', key: 'campus' },
  { class: 'w-3/12 px-3', label: 'Unit', key: 'unit' },
  {
    class: 'w-2/12 px-3',
    get label() {
      return t('common.labels.preference');
    },
    key: 'preference',
  },
];

export default {
  name: 'AssignedLocationPreferencesTable',
  components: { SetLocationPreferenceChip },
  props: {
    locations: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      headerItems: TABLE_HEADERS,
      selectedPreferences: new Map(),
    };
  },
  computed: {
    ...mapState('locationPreferences', ['assignedPagination']),
    transformedPreferences() {
      return Array.from(
        this.selectedPreferences,
        ([locationId, preference]) => ({
          locationId,
          preference,
        })
      );
    },
  },
  methods: {
    mapSingleUnitName,
    hasUpdatedPreferences(locationId) {
      return this.transformedPreferences.some(
        (location) => location.locationId === locationId
      );
    },
    findPreferenceValue(location) {
      const hasSelectedPreference =
        this.hasLocationSelectedPreference(location);
      const isPreferenceUnknown = this.isLocationPreferenceUnknown(location);

      if (hasSelectedPreference || isPreferenceUnknown) {
        return this.getSelectedPreferenceOrDefault(location);
      }

      return location.preference;
    },
    hasLocationSelectedPreference(location) {
      return (
        this.selectedPreferences.get(location.locationId)?.value !== undefined
      );
    },
    isLocationPreferenceUnknown(location) {
      return location.preference === PREFERENCES.UNKNOWN.value;
    },
    getSelectedPreferenceOrDefault(location) {
      return (
        this.selectedPreferences.get(location.locationId)?.value ||
        DEFAULT_PREFERENCE.value
      );
    },
    async updatePagination(pagination) {
      await this.$store.dispatch(
        'locationPreferences/updateAssignedPagination',
        pagination
      );
    },
    async onPaginationChanged(pagination) {
      await this.updatePagination(pagination);
      const currentQuery = { ...this.$route.query };
      await this.$router.push({
        query: {
          ...currentQuery,
          offset: pagination.offset,
          limit: pagination.limit,
        },
      });
    },
  },
};
</script>
