import { ApiClient } from '@/api/ApiClient';

export const locations = {
  getMainLocation: (profileId) =>
    ApiClient.instance.get(`profiles/${profileId}/main-location`),
  updateMainLocation: (profileId, data) =>
    ApiClient.instance.post(`profiles/${profileId}/main-location`, data),
  getPossibleUnitsById: (profileId) =>
    ApiClient.instance.get(`profiles/${profileId}/units`),
  getPossibleCampusesByUnitAndId: ({ unit, profileId }) =>
    ApiClient.instance.get(`profiles/${profileId}/campuses`, {
      params: { unit },
    }),
  getAssignedUnitsAndCampusesByProfileId: (profileId) =>
    ApiClient.instance.get(`profiles/${profileId}/assigned-units-and-campuses`),
  assignLocationToProfile: (profileId, data) =>
    ApiClient.instance.post(`profiles/${profileId}/location`, data),
  deleteAssignedLocationByProfileIdAndUnitName: (profileId, unitName) =>
    ApiClient.instance.delete(`profiles/${profileId}/units/${unitName}`),
};
