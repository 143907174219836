import {
  makeNestedRoutes,
  profileGuard,
  beforeEnterPermissionGuard,
} from '@/routes/make-nested-routes';
import { SEARCH_META, PROFILE_META } from '@/routes/meta';
import ProfileCreate from '@/views/profiles/ProfileCreate';
import ProfileUpdate from '@/views/profiles/ProfileUpdate';
import ProfileDelete from '@/views/profiles/ProfileDelete';
import Profiles from '@/views/profiles/Profiles';
import {
  createEmails,
  createPhones,
  createAddresses,
} from '@/routes/profiles/create';
import {
  updateEmails,
  updatePhones,
  updateAddresses,
} from '@/routes/profiles/update';
import ManageUniversityCapabilities from '@/views/university-capabilities/ManageUniversityCapabilities.vue';
import {
  capabilitiesGuard,
  courseCapabilitiesGuard,
  //  deputationGuard, // disabled as it the route is disabled at the moment
  locationGuard,
  resetStateOnProfileChange,
} from '@/routes/guards';
// import DeputationOverview from '@/views/deputation/DeputationOverview.vue'; // disabled at the moment
import LocationManagement from '@/views/location/LocationManagement.vue';
import ManageCourseCapabilities from '@/views/course-capabilities/ManageCourseCapabilities.vue';
import OrchestrateDataImport from '@/views/data-import/OrchestrateDataImport.vue';
import AvailabilityPreferences from '@/views/location/AvailabilityPreferences.vue';

const createProfileRoute = {
  path: 'create',
  component: ProfileCreate,
  name: 'profile-create',
  meta: PROFILE_META,
  beforeEnter: (_to, _from, next) => {
    beforeEnterPermissionGuard('academic-staff', ['c'], next);
  },
};

const updateProfileRoute = {
  path: ':profileId',
  component: ProfileUpdate,
  name: 'profile-update',
  props: true,
  beforeEnter: profileGuard,
  meta: PROFILE_META,
};

export const profilesRoutes = [
  {
    path: '',
    component: Profiles,
    name: 'profiles',
    meta: SEARCH_META,
    props: true,
    children: [
      {
        path: ':profileId/delete',
        name: 'profile-delete',
        components: {
          'deletion-modal': ProfileDelete,
        },
        props: {
          'deletion-modal': true,
        },
        meta: {
          ...SEARCH_META,
          showDeletionModal: true,
        },
        beforeEnter: profileGuard,
        beforeRouteLeave: resetStateOnProfileChange,
      },
    ],
  },
  {
    ...createProfileRoute,
    children: makeNestedRoutes(
      { name: createProfileRoute.name, meta: createProfileRoute.meta },
      [...createEmails, ...createAddresses, ...createPhones]
    ),
  },
  {
    ...updateProfileRoute,
    children: [
      ...makeNestedRoutes(
        { name: updateProfileRoute.name, meta: updateProfileRoute.meta },
        [...updateEmails, ...updateAddresses, ...updatePhones]
      ),
      {
        path: 'soft-delete',
        name: 'profile-soft-delete',
        meta: {
          showSoftDeleteProfileModal: true,
        },
      },
    ],
  },
  //@TODO: move to their corresponding folders, sadly the route in the sidebar needs the profile prefix to stay active
  // Deputation routes are disabled until the feature is reworked
  // {
  //   path: 'deputation/:profileId',
  //   component: DeputationOverview,
  //   name: 'profile-deputation',
  //   props: true,
  //   beforeEnter: deputationGuard,
  //   beforeRouteLeave: resetStateOnProfileChange,
  //   meta: PROFILE_META,
  // },
  {
    path: 'university-capabilities/:profileId',
    component: ManageUniversityCapabilities,
    name: 'profile-capabilities',
    props: true,
    beforeEnter: capabilitiesGuard,
    beforeRouteLeave: resetStateOnProfileChange,
    meta: PROFILE_META,
  },
  {
    path: ':profileId/course-capabilities',
    component: ManageCourseCapabilities,
    name: 'profile-course-capabilities',
    props: true,
    beforeEnter: courseCapabilitiesGuard,
    beforeRouteLeave: resetStateOnProfileChange,
    meta: PROFILE_META,
  },
  {
    path: 'locations/:profileId',
    component: LocationManagement,
    name: 'profile-locations',
    beforeEnter: locationGuard,
    beforeRouteLeave: resetStateOnProfileChange,
    meta: PROFILE_META,
  },
  {
    path: ':profileId/availability-preferences',
    component: AvailabilityPreferences,
    name: 'availability-preferences',
    props: true,
    beforeEnter: profileGuard,
    beforeRouteLeave: resetStateOnProfileChange,
    meta: PROFILE_META,
  },
  {
    path: 'data-import',
    component: OrchestrateDataImport,
    name: 'data-import',
    meta: PROFILE_META,
  },
];
