import { ContactsResourceFactory } from '@/api/ApiResourceFactory';

const _addresses = ContactsResourceFactory('addresses');

export const addressTransform = ({
  primary = false,
  type,
  street,
  houseNumber,
  additionToAddress = '',
  city,
  zip,
  country,
}) => ({
  primary,
  type,
  street,
  houseNumber,
  additionToAddress,
  city,
  zip,
  country,
});

export const addresses = {
  ..._addresses,
  updateById: (id, payload) =>
    _addresses.updateById(id, addressTransform(payload)),
};
