import { authMiddleware } from '@epos/core-applets';

export const hasPermissionFor = (resourcePath, actions) => {
  const permissions = authMiddleware.isAuthenticated
    ? authMiddleware.user.permissions
    : [];

  return permissions.some(
    (permission) =>
      permission.resourcePath === resourcePath &&
      actions.every((action) => permission.actions.includes(action))
  );
};
