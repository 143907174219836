import { Routes } from '@epos/core-applets';
import { makeNestedRoutes } from '@/routes/make-nested-routes';
import { profilesRoutes } from './profiles';
import GeneralErrorPage from '@/views/error-pages/GeneralErrorPage.vue';

export const routes = [
  ...makeNestedRoutes({ path: Routes.STAFF_SERVICES.PROFILES }, profilesRoutes),
  { path: Routes.STAFF_SERVICES.BASE, redirect: { name: 'profiles' } },
  {
    path: Routes.STAFF_SERVICES.BASE,
    component: GeneralErrorPage,
    name: 'forbidden',
    props: { variant: 'forbidden' },
  },
  {
    path: `${Routes.STAFF_SERVICES.BASE}/*`,
    component: GeneralErrorPage,
    name: 'not-found',
    props: { variant: 'notFound' },
  },
];
