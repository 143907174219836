import { datadogLogs } from '@datadog/browser-logs';

export default {
  namespaced: true,
  state: {
    errors: [],
  },
  actions: {
    pushError({ commit }, error) {
      commit('pushError', error);
    },
    dismissError({ commit }) {
      commit('shiftError');
    },
    reportError(error) {
      datadogLogs.logger.error(error?.message ?? 'Error', {}, error);
    },
    clearErrors({ commit }) {
      commit('clearErrors');
    },
  },
  mutations: {
    pushError(state, error) {
      state.errors.push(error);
    },
    shiftError(state) {
      state.errors.shift();
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
  getters: {
    hasErrors: (state) => !!state.errors.length,
    totalErrors: (state) => state.errors.length,
    lastError: (state) => (state.errors.length ? state.errors[0] : null),
  },
};
