<template>
  <div class="flex">
    <div class="justify-between w-full">
      <div :class="`bg-${background} w-full rounded`">
        <div
          :class="`bg-${filledColor} ${progressFractionClass} ${roundedClass}  mr-0.5`"
        >
          <p
            v-if="currentBudgetInPercent > segmentWidthLimit"
            class="text-center"
          >
            <deputation-tooltip
              :actual="currentBudgetInPercent"
              :deputation-status="deputationStatus"
              :planned="desiredBudgetInPercent"
              :title="capabilityTitle"
            >
              <span :class="fontColor" class="text-base font-bold p-1.5">
                {{ budgetText }}
              </span>
            </deputation-tooltip>
          </p>
        </div>
        <p
          v-if="currentBudgetInPercent < segmentWidthLimit"
          class="text-center"
        >
          <deputation-tooltip
            :actual="currentBudgetInPercent"
            :deputation-status="deputationStatus"
            :planned="desiredBudgetInPercent"
            :title="capabilityTitle"
          >
            <span :class="fontColor" class="text-base font-bold p-1.5">
              {{ budgetText }}
            </span>
          </deputation-tooltip>
        </p>
      </div>
      <p
        class="text-center text-ellipsis overflow-hidden whitespace-nowrap p-2 text-md text-blue-600"
      >
        {{ capabilityTitle }}
      </p>
    </div>
  </div>
</template>

<script>
import { getDeputationStatusColorTuple } from '@/constants/picklists';
import DeputationTooltip from '@/components/deputation/Common/DeputationTooltip.vue';
import { getTextByCapability } from '@/constants/picklists/capabilities';

export default {
  name: 'DeputationBarSegment',
  components: { DeputationTooltip },
  props: {
    currentBudgetInPercent: {
      type: Number,
      default: 0,
      validator(value) {
        return value >= 0;
      },
    },
    desiredBudgetInPercent: {
      type: Number,
      default: 100,
      validator(value) {
        return value >= 0;
      },
    },
    deputationStatus: {
      type: String,
      required: true,
    },
    capability: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filledColor() {
      return getDeputationStatusColorTuple(this.deputationStatus).filledColor;
    },
    background() {
      return getDeputationStatusColorTuple(this.deputationStatus)
        .backgroundColor;
    },
    budgetText() {
      return `${this.currentBudgetInPercent}%`;
    },
    segmentWidthLimit() {
      return 1;
    },
    fontColor() {
      return this.currentBudgetInPercent > this.segmentWidthLimit
        ? 'text-white'
        : 'text-black';
    },
    progressFractionClass() {
      const progress = this.progressFraction;

      return progress ? `w-${progress}/12` : `w-0`;
    },
    roundedClass() {
      return this.currentBudgetInPercent < 100 &&
        this.currentBudgetInPercent > 0
        ? 'rounded-l'
        : 'rounded';
    },
    progressFraction() {
      let progress = this.currentBudgetInPercent;

      if (this.currentBudgetInPercent > 100) {
        progress = 100;
      }

      progress = Math.round((progress / 100) * 12);

      return progress;
    },
    capabilityTitle() {
      return getTextByCapability(this.capability);
    },
  },
};
</script>
<style>
.segment-background,
.segment-inner {
  height: 2rem;
}
</style>
