import { ApiClient } from '@/api/ApiClient';
import { PROFILES_BASE_ROUTE } from '@/api/profiles';

const CAPABILITIES_BASE_ROUTE = 'university-capabilities';

export const universityCapabilities = {
  getById: (profileId) =>
    ApiClient.instance.get(
      `${PROFILES_BASE_ROUTE}/${profileId}/${CAPABILITIES_BASE_ROUTE}`
    ),
  calculateBudgets: (profileId, payload) =>
    ApiClient.instance.post(
      `${PROFILES_BASE_ROUTE}/${profileId}/${CAPABILITIES_BASE_ROUTE}/calculate-budgets`,
      payload
    ),
  post: (profileId, payload) =>
    ApiClient.instance.post(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${CAPABILITIES_BASE_ROUTE}`,
      payload
    ),
};
