<template>
  <n-toolbar>
    <n-button
      data-test="add-capability-button"
      variant="quaternary"
      :disabled="!userCanAddCapabilities"
      :title="addCapabilityButtonTitle"
      @click="openSearchModal"
    >
      <n-icon class="mr-2" icon="mdi-plus" />
      {{
        $t('views.course-capabilities.capabilities.capabilities-toolbar.add')
      }}
    </n-button>
    <n-tooltip :content="preferenceButtonTooltipText">
      <n-button
        data-test="update-preferences-button"
        variant="quaternary"
        :disabled="preferenceButtonDisabled"
        :title="updatePreferenceButtonTitle"
        @click="openPreferencesModal"
      >
        <n-icon class="mr-2" icon="mdi-cog-outline" />
        {{ $t('views.course-capabilities.edit-course-preferences') }}
      </n-button>
    </n-tooltip>
  </n-toolbar>
</template>

<script>
import { t } from '@/plugins/translations';
import { hasPermissionFor } from '@/utils/permissionUtil';

const RESOURCE_PATH = 'academic-staff';

export default {
  name: 'CapabilitiesToolbar',
  props: {
    hasCapabilities: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['openSearchModal', 'openPreferencesModal'],
  computed: {
    preferenceButtonTooltipText() {
      return (
        !this.hasCapabilities &&
        t(
          'views.course-capabilities.capabilities.capabilities-toolbar.preference-button-tooltip'
        )
      );
    },
    preferenceButtonDisabled() {
      return !this.userCanUpdatePreferences || !this.hasCapabilities;
    },
    userCanAddCapabilities() {
      return hasPermissionFor(RESOURCE_PATH, ['c']);
    },
    userCanUpdatePreferences() {
      return hasPermissionFor(RESOURCE_PATH, ['u']);
    },
    addCapabilityButtonTitle() {
      return this.userCanAddCapabilities
        ? ''
        : t(
            'views.course-capabilities.capabilities.capabilities-toolbar.add-button-title'
          );
    },
    updatePreferenceButtonTitle() {
      return this.userCanAddCapabilities
        ? ''
        : t(
            'views.course-capabilities.capabilities.capabilities-toolbar.update-button-title'
          );
    },
  },
  methods: {
    openSearchModal() {
      this.$emit('openSearchModal');
    },
    openPreferencesModal() {
      this.$emit('openPreferencesModal');
    },
  },
};
</script>
