<template>
  <div>
    <contacts-box
      :contacts="phones"
      :contact-types="contactTypes"
      :actions="actions"
      :tags="tags"
      :mandatory="false"
      :disabled="disabled"
      :section-title="$t('views.profiles-phones.section-title')"
      :add-button-label="$t('views.profiles-phones.add-button-label')"
      :empty-text="$t('views.profiles-phones.empty-text')"
      data-test="profile-phones"
      @click-create="clickCreate"
      @click-delete="clickDelete"
      @click-set-as-primary="setAsPrimary"
      @click-update="clickUpdate"
    >
      <template #data="{ contact }">
        <p class="mt-1">{{ contact.prefix }} {{ contact.phonenumber }}</p>
      </template>
    </contacts-box>
    <portal to="phone-create">
      <phone-form
        :model-value="{ primary: !phones.length }"
        @submit="submitCreate"
        @close="close"
      />
    </portal>
    <portal to="phone-update">
      <template #default="{ phone }">
        <phone-form
          :model-value="phone"
          @submit="submitUpdate"
          @close="close"
        />
      </template>
    </portal>
  </div>
</template>

<script>
import { t } from '@/plugins/translations';
import ContactsBox from '@/components/profiles/ContactsBox';
import PhoneForm from '@/components/profiles/Phones/PhoneForm';
import { PHONE_TYPES } from '@/constants/picklists';

export default {
  name: 'Phones',
  components: { PhoneForm, ContactsBox },
  props: {
    phones: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update-contact',
    'create-contact',
    'set-as-primary-contact',
    'open-contact-delete',
    'open-contact-update',
    'open-contact-create',
    'close',
  ],
  computed: {
    contactTypes() {
      return PHONE_TYPES;
    },
  },
  methods: {
    tags(contact) {
      return contact.primary ? ['Korrespondenz'] : [];
    },
    actions(contact) {
      return [
        {
          id: 'click-update',
          name: 'click-update',
          get text() {
            return t('common.labels.edit');
          },
          disabled: !!contact.primary,
        },
        {
          id: 'click-delete',
          name: 'click-delete',
          get text() {
            return t('common.labels.delete');
          },
          disabled: contact.primary || this.phones.length <= 1,
        },
        {
          id: 'click-set-as-primary',
          name: 'click-set-as-primary',
          get text() {
            return t('views.profiles.set-as-correspondence');
          },
          disabled: !!contact.primary,
        },
      ];
    },
    close() {
      this.$emit('close');
    },
    clickCreate() {
      this.$emit('open-contact-create', 'phones');
    },
    clickUpdate(payload) {
      this.$emit('open-contact-update', 'phones', payload);
    },
    clickDelete(payload) {
      this.$emit('open-contact-delete', 'phones', payload);
    },
    setAsPrimary(payload) {
      this.$emit('set-as-primary-contact', 'phones', payload);
    },
    submitCreate(payload) {
      this.$emit('create-contact', 'phones', payload);
    },
    submitUpdate(payload) {
      this.$emit('update-contact', 'phones', payload);
    },
  },
};
</script>
