<template>
  <div class="flex items-center">
    <template v-if="updateMetadataAvailable">
      <icon-with-rounded-background icon="clock-outline" class="mr-2" />
      <p class="text-blue-600 text-base">
        {{
          $t(
            'views.course-capabilities.common.course-capabilities-preferences-last-edited.last-changed-by'
          )
        }}
        <span class="font-bold">
          <a
            v-if="userFullName"
            :href="`/services/iam/users/${profileId}/profile`"
          >
            {{ userFullName }}
          </a>
          <template v-else>
            {{
              $t(
                'views.course-capabilities.common.course-capabilities-preferences-last-edited.unknown'
              )
            }}
          </template>
        </span>
        {{ $t('common.labels.at') }}
        {{ formattedUpdatedAt }}
      </p>
    </template>
    <template v-else>
      <icon-with-rounded-background icon="cancel" class="mr-2" />
      <p class="text-blue-600 text-base">
        {{
          $t(
            'views.course-capabilities.common.course-capabilities-preferences-last-edited.no-course-preferences-set'
          )
        }}
      </p>
    </template>
  </div>
</template>
<script>
import { iamApi } from '@/api/iam';
import IconWithRoundedBackground from '@/components/common/IconWithRoundedBackground.vue';
export default {
  name: 'CourseCapabilitiesPreferencesLastEdited',
  components: { IconWithRoundedBackground },
  props: {
    updatedBy: {
      type: String,
      default: null,
    },
    updatedAt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      profileId: null,
      userFullName: null,
    };
  },
  computed: {
    formattedUpdatedAt() {
      return new Intl.DateTimeFormat('default', { dateStyle: 'short' }).format(
        new Date(this.updatedAt)
      );
    },
    updateMetadataAvailable() {
      return this.updatedBy && this.updatedAt;
    },
  },
  watch: {
    updatedBy: {
      immediate: true,
      async handler(profileId) {
        this.userFullName = null;
        this.profileId = profileId;

        if (profileId) {
          this.userFullName = await this.fetchUser(profileId);
        }
      },
    },
  },
  methods: {
    async fetchUser(profileId) {
      try {
        const user = await iamApi.getUser(profileId);
        return `${user.givenName} ${user.familyName}`;
      } catch (e) {
        return 'Unbekannt';
      }
    },
  },
};
</script>
