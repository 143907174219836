import { initApi } from '@/api/init';

export default {
  namespaced: true,
  state: {
    teachingLevels: [],
  },
  getters: {
    teachingLevels: (state) => state.teachingLevels,
  },
  actions: {
    async fetchInit({ commit }) {
      commit('setInitValues', await initApi.getValues());
    },
  },
  mutations: {
    setInitValues(state, values) {
      for (const key of Object.keys(values)) {
        state[key] = values[key];
      }
    },
  },
};
