import { BasicResourceFactory } from '@/api/ApiResourceFactory';
import { phoneTransform } from '@/api/phones';
import { addressTransform } from '@/api/addresses';
import { emailTransform } from '@/api/emails';
import { ApiClient } from '@/api/ApiClient';

export const PROFILES_BASE_ROUTE = 'profiles';

const {
  get,
  getById,
  post,
  updateById,
  getSubPathById,
  postSubPathById,
  patchSubPathById,
  deleteById,
} = BasicResourceFactory(PROFILES_BASE_ROUTE);

const formatSearchQuery = ({
  name,
  academyId,
  email,
  teachingLevels,
  employmentType,
  brands,
  units,
  planningStatus,
  preferences,
  limit = 20,
  offset = 0,
}) => {
  return {
    ...(name ? { 'filter[name]': name } : {}),
    ...(academyId ? { 'filter[academyId]': academyId } : {}),
    ...(email ? { 'filter[email]': email } : {}),
    ...(teachingLevels?.length
      ? { 'filter[teachingLevels]': teachingLevels }
      : {}),
    ...(employmentType?.length
      ? { 'filter[employmentType]': employmentType }
      : {}),
    ...(planningStatus?.length
      ? { 'filter[planningStatus]': planningStatus }
      : {}),
    ...(brands?.length ? { 'filter[brands]': brands } : {}),
    ...(units?.length ? { 'filter[units]': units } : {}),
    ...(preferences ? { 'filter[preferences]': preferences } : {}),
    limit,
    offset,
  };
};

const profileTransform = ({
  givenName,
  familyName,
  honorificPrefix = null,
  gender,
  degree = null,
  employmentType,
  isProvisional = false,
  workdayId,
  citizenships = [],
  dateOfBirth,
  planningStatus = true,
  overallCapacity = 0,
  brand,
  teachingLevel,
  contractStartDate,
  contractEndDate,
  taskCapacity,
}) => ({
  givenName,
  familyName,
  honorificPrefix,
  gender,
  degree,
  employmentType,
  isProvisional,
  workdayId,
  citizenships,
  dateOfBirth,
  planningStatus,
  overallCapacity,
  brand,
  teachingLevel,
  contractStartDate,
  contractEndDate,
  taskCapacity,
});

const profileWithContactsTransforms = ({
  addresses = [],
  emails = [],
  phones = [],
  ...profile
}) => ({
  ...profileTransform(profile),
  addresses: addresses.map((address) => addressTransform(address)),
  emails: emails.map((email) => emailTransform(email)),
  phones: phones.map((phone) => phoneTransform(phone)),
});

export const profiles = {
  get: (params) => get(formatSearchQuery(params)),
  getById,
  deleteById,
  post: (payload) => post(profileWithContactsTransforms(payload)),
  updateById: (id, payload) => updateById(id, profileTransform(payload)),
  togglePlanningStatus: (id) => patchSubPathById(id, 'planning-status/toggle'),
  getAddresses: (id) => getSubPathById(id, 'addresses'),
  addresses: (id, payload) =>
    postSubPathById(id, 'addresses', addressTransform(payload)),
  getEmails: (id) => getSubPathById(id, 'emails'),
  emails: (id, payload) =>
    postSubPathById(id, 'emails', emailTransform(payload)),
  getPhones: (id) => getSubPathById(id, 'phones'),
  phones: (id, payload) =>
    postSubPathById(id, 'phones', phoneTransform(payload)),
  restore: (id) => postSubPathById(id, 'restore'),
  getAllUnits: () => ApiClient.instance.get(`units`),
};
