export {
  createContactGuard,
  updateContactGuard,
  profileGuard,
  beforeEnterPermissionGuard,
} from '@/routes/guards';

/**
 * @param path
 * @param name
 * @param meta
 * @param routes
 */
export const makeNestedRoutes = ({ path = '', name, meta = {} }, routes) =>
  routes.map((route) => ({
    ...route,
    path: path ? `${path}/${route.path}` : route.path,
    name: name ? `${name}.${route.name}` : route.name,
    meta: { ...meta, ...route.meta },
  }));
