<template>
  <div class="flex">
    <div class="w-3/4">
      <p class="text-blue-600 text-2xl mb-4 font-bold uppercase">
        {{
          $t(
            'views.course-capabilities.capabilities.course-capabilities-table-banner.head'
          )
        }}
      </p>
      <p class="text-blue-600 text-base">
        {{ $t('common.course-capabilities.description-capabilities') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseCapabilitiesTableBanner',
};
</script>
