const mapSingleUnitName = (unit) => {
  switch (unit) {
    case 'Distance Learning':
      return 'Fernstudium';
    case 'DS':
      return 'Duales Studium';
    default:
      return unit;
  }
};

const mapMultipleUnitsNames = (units) => {
  return units.map((unit) => mapSingleUnitName(unit));
};

const unmapSingleMappedUnitName = (mappedUnit) => {
  switch (mappedUnit) {
    case 'Fernstudium':
      return 'Distance Learning';
    case 'Duales Studium':
      return 'DS';
    default:
      return mappedUnit;
  }
};

const unmapMultipleMappedUnitNames = (mappedUnits) => {
  return mappedUnits.map((mappedUnit) => unmapSingleMappedUnitName(mappedUnit));
};

export {
  mapSingleUnitName,
  mapMultipleUnitsNames,
  unmapSingleMappedUnitName,
  unmapMultipleMappedUnitNames,
};
