<template>
  <n-toolbar>
    <n-button
      variant="quaternary"
      :disabled="!userCanAssignLocationsOrUpdatePreferences"
      :title="editMainLocationButtonTitle"
      @click="openEditMainLocationModel"
    >
      <n-icon class="mr-2" icon="mdi-pencil-outline" />
      {{ $t('views.location.edit-main-unit-and-campus') }}
    </n-button>
    <n-button
      data-test="assign-location-button"
      variant="quaternary"
      :disabled="!userCanAssignLocationsOrUpdatePreferences"
      :title="assignLocationButtonTitle"
      @click="openAssignLocationModal"
    >
      <n-icon class="mr-2" icon="mdi-plus" />
      {{ $t('views.location.assign-unit') }}
    </n-button>
    <n-tooltip :content="preferenceButtonTooltipText">
      <n-button
        data-test="update-preferences-button"
        variant="quaternary"
        :disabled="preferenceButtonDisabled"
        :title="updatePreferenceButtonTitle"
        @click="openPreferencesModal"
      >
        <n-icon class="mr-2" icon="mdi-cog-outline" />
        {{ $t('views.location.edit-campuspreferences') }}
      </n-button>
    </n-tooltip>
  </n-toolbar>
</template>

<script>
import { hasPermissionFor } from '@/utils/permissionUtil';
import { t } from '@/plugins';

const RESOURCE_PATH = 'academic-staff';

export default {
  name: 'LocationToolbar',
  props: {
    hasLocations: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'openAssignLocationModal',
    'openPreferencesModal',
    'openEditMainLocationModel',
  ],
  computed: {
    preferenceButtonTooltipText() {
      return (
        !this.hasLocations &&
        'Campuspräferenzen können erst angegeben werden, sobald Unit & Campus Zuordnungen gesetzt wurden.'
      );
    },
    preferenceButtonDisabled() {
      return (
        !this.userCanAssignLocationsOrUpdatePreferences || !this.hasLocations
      );
    },
    userCanAssignLocationsOrUpdatePreferences() {
      return hasPermissionFor(RESOURCE_PATH, ['u']);
    },
    assignLocationButtonTitle() {
      return this.userCanAssignLocationsOrUpdatePreferences
        ? ''
        : t('views.location.add-location-no-permission');
    },
    editMainLocationButtonTitle() {
      return this.userCanAssignLocationsOrUpdatePreferences
        ? ''
        : t('views.location.update-main-location-no-permission');
    },
    updatePreferenceButtonTitle() {
      return this.userCanAssignLocationsOrUpdatePreferences
        ? ''
        : t('views.location.update-location-preferences-no-permission');
    },
  },
  methods: {
    openAssignLocationModal() {
      this.$emit('openAssignLocationModal');
    },
    openEditMainLocationModel() {
      this.$emit('openEditMainLocationModel');
    },
    openPreferencesModal() {
      this.$emit('openPreferencesModal');
    },
  },
};
</script>
