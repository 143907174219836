<template>
  <div v-if="isExpanded">
    <div
      class="mb-5 gap-8 inline-flex cursor-pointer"
      @click="linkToProfileEdit(profile.id)"
    >
      <div class="grow">
        <p class="mb-0 capitalize text-gray-700">
          {{ $t('common.labels.email') }}
        </p>
        <p>
          {{ profile.primaryEmail || '-' }}
        </p>
      </div>
      <div class="grow">
        <p class="mb-0 capitalize text-gray-700">
          {{ $t('common.labels.workday-id') }}
        </p>
        <p>
          {{ profile.workdayId || '-' }}
        </p>
      </div>
      <div class="grow">
        <p class="mb-0 capitalize text-gray-700">
          {{ $t('common.labels.contract-start-date') }}
        </p>
        <p>
          {{
            profile.contractStartDate
              ? formatDate(profile.contractStartDate)
              : '-'
          }}
        </p>
      </div>
      <div class="grow">
        <p class="mb-0 capitalize text-gray-700">
          {{ $t('common.labels.contract-end-date') }}
        </p>
        <p>
          {{
            profile.contractEndDate ? formatDate(profile.contractEndDate) : '-'
          }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from '@/utils/assignment-dates-formatter';

export default {
  props: {
    profile: {
      type: Object,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    formatDate,
    linkToProfileEdit(profileId) {
      this.$router.push({ name: 'profile-update', params: { profileId } });
    },
  },
};
</script>
