<template>
  <n-select
    :id="`filter__${value}`"
    v-model="model"
    :label="label"
    :options="options"
    class="table-filter-search"
    option-label="title"
    option-value="key"
    track-by="key"
    searchable
    mapped
    multiple
    max="20"
  />
</template>

<script>
import { NSelect } from '@careerpartner/nitro';

export default {
  name: 'TableFilterMultiselect',
  components: {
    NSelect,
  },
  props: {
    modelValue: {
      type: () => Array | String | Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue', 'close'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style>
.table-filter-search {
  @apply overflow-hidden whitespace-nowrap bg-white px-3 py-1 shadow-lg;
  width: 16rem;
  min-height: 2.625rem;

  & :deep(label > span) {
    @apply overflow-x-hidden truncate;
  }
}
</style>
