<template>
  <div class="selected-preset-info bg-blue-100 px-4 py-4 rounded-lg mt-4">
    <div class="uppercase flex">
      <span class="font-bold leading-5 tracking-tighter text-left">
        Ausgewählte Vorlage:
      </span>
      <n-chip class="ml-2 bg-blue-600 text-white">{{
        getRoleLabel(selectedPreset)
      }}</n-chip>
    </div>
    <div class="selected-capabilities flex gap-1">
      <div
        v-for="capability in selectedCapabilities"
        :key="capability"
        class="pt-4 flex items-center"
      >
        <n-icon icon="mdi-check" class="text-green-600"></n-icon>
        <span
          class="pl-1 font-semibold truncate"
          :class="{ 'limit-max-width': selectedCapabilities.length > 3 }"
          :title="getCapabilityLabel(capability)"
        >
          {{ getCapabilityLabel(capability) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTextByKey,
  CAPABILITIES,
  PROFILE_PRESETS,
} from '@/constants/picklists';

export default {
  name: 'SelectedPreset',
  props: {
    selectedPreset: {
      type: String,
      required: true,
    },
    selectedCapabilities: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getRoleLabel(role) {
      return getTextByKey(PROFILE_PRESETS, role);
    },
    getCapabilityLabel(capability) {
      return getTextByKey(CAPABILITIES, capability);
    },
  },
};
</script>

<style scoped>
.limit-max-width {
  max-width: 105px;
}
@media (min-width: 1068px) {
  .limit-max-width {
    max-width: 145px;
  }
}
</style>
