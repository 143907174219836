import { createStore } from 'vuex';
import currentUser from '@/store/modules/currentUser';
import errors from '@/store/modules/errors';
import snackbar from '@/store/modules/snackbar';
import profile from '@/store/modules/profile';
import puffer from '@/store/modules/puffer';
import capabilities from '@/store/modules/universityCapabilities/universityCapabilities';
import courseCapabilities from '@/store/modules/courseCapabilities';
import assignments from '@/store/modules/assignment';
import deputation from '@/store/modules/deputation';
import location from '@/store/modules/location';
import init from '@/store/modules/init';
import courseSearchModal from '@/store/modules/courseSearchModal';
import setPreferencesModal from '@/store/modules/setPreferencesModal';
import dataImport from '@/store/modules/dataImport';
import locationPreferences from '@/store/modules/locationPreferences';
import mainLocation from '@/store/modules/main-location';
import studyPrograms from '@/store/modules/studyPrograms';
import planningPeriods from '@/store/modules/planningPeriods';

export const store = createStore({
  modules: {
    currentUser,
    errors,
    profile,
    snackbar,
    puffer,
    capabilities,
    courseCapabilities,
    assignments,
    deputation,
    location,
    init,
    courseSearchModal,
    setPreferencesModal,
    dataImport,
    locationPreferences,
    mainLocation,
    studyPrograms,
    planningPeriods,
  },
});
