<template>
  <div>
    <contacts-box
      :contacts="addresses"
      :contact-types="contactTypes"
      :actions="actions"
      :tags="tags"
      :mandatory="false"
      :disabled="disabled"
      :section-title="$t('views.profiles-addresses.section-title')"
      :add-button-label="$t('views.profiles-addresses.add-button-label')"
      :empty-text="$t('views.profiles-addresses.empty-text')"
      data-test="profile-addresses"
      @click-create="clickCreate"
      @click-update="clickUpdate"
      @click-delete="clickDelete"
      @click-set-as-primary="setAsPrimary"
    >
      <template #data="{ contact }">
        <address-data :address="contact" />
      </template>
    </contacts-box>
    <portal to="address-create">
      <address-form
        :model-value="{ primary: !addresses.length }"
        :title="$t('views.profiles.addresses.modal-title-add')"
        @submit="submitCreate"
        @close="close"
      />
    </portal>
    <portal to="address-update">
      <template #default="{ address }">
        <address-form
          :model-value="address"
          :title="$t('views.profiles.addresses.modal-title-edit')"
          @submit="submitUpdate"
          @close="close"
        />
      </template>
    </portal>
  </div>
</template>

<script>
import { t } from '@/plugins/translations';
import AddressData from '@/components/profiles/Addresses/AddressData';
import { ADDRESS_TYPES } from '@/constants/picklists';
import ContactsBox from '@/components/profiles/ContactsBox';
import AddressForm from '@/components/profiles/Addresses/AddressForm';

export default {
  name: 'Addresses',
  components: {
    AddressData,
    AddressForm,
    ContactsBox,
  },
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update-contact',
    'create-contact',
    'set-as-primary-contact',
    'open-contact-delete',
    'open-contact-update',
    'open-contact-create',
    'close',
  ],
  computed: {
    contactTypes() {
      return ADDRESS_TYPES;
    },
  },
  methods: {
    tags(address) {
      return address?.primary ? [t('common.labels.billing')] : [];
    },
    actions(address) {
      return [
        {
          id: 'click-update',
          name: 'click-update',
          get text() {
            return t('common.labels.edit');
          },
          disabled: false,
        },
        {
          id: 'click-delete',
          name: 'click-delete',
          get text() {
            return t('common.labels.delete');
          },
          disabled: address.primary || this.addresses.length <= 1,
        },
        {
          id: 'click-set-as-primary',
          name: 'click-set-as-primary',
          get text() {
            return t('views.profiles.addresses.set-as-billing-address');
          },
          disabled: !!address.primary,
        },
      ];
    },
    close() {
      this.$emit('close');
    },
    clickCreate() {
      this.$emit('open-contact-create', 'addresses');
    },
    clickUpdate(payload) {
      this.$emit('open-contact-update', 'addresses', payload);
    },
    clickDelete(payload) {
      this.$emit('open-contact-delete', 'addresses', payload);
    },
    setAsPrimary(payload) {
      this.$emit('set-as-primary-contact', 'addresses', payload);
    },
    submitCreate(payload) {
      this.$emit('create-contact', 'addresses', payload);
    },
    submitUpdate(payload) {
      this.$emit('update-contact', 'addresses', payload);
    },
  },
};
</script>
