<template>
  <portal-target
    name="email-update"
    :slot-props="{
      email: contact,
    }"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EmailUpdate',
  data() {
    return {
      contact: null,
    };
  },
  computed: {
    ...mapGetters('puffer', ['currentContact']),
    ...mapGetters('profile', ['getContact']),
  },
  beforeMount() {
    if (this.currentContact) {
      this.contact = this.currentContact;
    } else {
      this.contact = this.getContact(this.$route.params.contactId);
    }
  },
};
</script>
