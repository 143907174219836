import { defineRule, Form, Field } from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
import de from '@vee-validate/i18n/dist/locale/de.json';
import { h } from 'vue';
import { configure } from 'vee-validate';
import { rulesMap } from '@/plugins/veeValidate/rules';

configure({
  generateMessage: localize({
    de,
  }),
});

[...rulesMap.keys()].forEach((rule) => {
  defineRule(rule, rulesMap.get(rule));
});

export const useVeeValidate = (app, i18n) => {
  // legacy names and default renderless components for a seamless upgrade
  app.component('ValidationForm', h(Form, { as: '' }));
  app.component('ValidationProvider', h(Field, { as: '' }));
  setLocale(i18n.global.locale.value);
};
