import { Api } from '@/api';

const initialState = () => ({
  assignedLocationPreferences: [],
  assignableLocationPreferences: [],
  assignedPagination: null,
  assignablePagination: null,
  isSavingPreferences: false,
  isLoadingPreferences: false,
  isLoadingAssignableLocationPreferences: false,
  profileId: null,
  lastUpdatedPreferencesMetadata: null,
  isMetadataLoaded: false,
});

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    isSavingPreferences: (state) => state.isSavingPreferences,
    isLoadingPreferences: (state) => state.isLoadingPreferences,
    assignedLocationPreferences: (state) => state.assignedLocationPreferences,
    assignableLocationPreferences: (state) =>
      state.assignableLocationPreferences,
    assignedPagination: (state) => state.assignedPagination,
    assignablePagination: (state) => state.assignablePagination,
    profileId: (state) => state.profileId,
    isLoadingAssignableLocationPreferences: (state) =>
      state.isLoadingAssignableLocationPreferences,
    lastUpdatedPreferencesMetadata: (state) =>
      state.lastUpdatedPreferencesMetadata,
    isMetadataLoaded: (state) => state.isMetadataLoaded,
  },
  actions: {
    setProfileId({ commit }, profileId) {
      commit('resetState');
      commit('setProfileId', profileId);
    },
    getAssignedLocations: async (
      { commit, dispatch, getters },
      searchParameters
    ) => {
      commit('setLoadingPreferencesStatus', true);
      try {
        const params = getSearchParams(searchParameters);

        const response = await Api.locationPreferences.searchPreferences(
          getters.profileId,
          params
        );

        commit('setAssignedLocations', response.data);
        commit('updateAssignedPagination', {
          ...params,
          totalCount: response?.pagination?.totalCount ?? 0,
        });
      } catch (error) {
        await dispatch('errors/pushError', error, { root: true });
      } finally {
        commit('setLoadingPreferencesStatus', false);
      }
    },
    searchAssignableLocations: async (
      { commit, dispatch, getters },
      searchParameters
    ) => {
      commit('setIsLoadingAssignableLocationPreferences', true);
      try {
        const params = getSearchParams(searchParameters);

        const response = await Api.locationPreferences.searchPreferences(
          getters.profileId,
          params
        );

        commit('setAssignableLocations', response.data);
        commit('updateAssignablePagination', {
          ...params,
          totalCount: response?.pagination?.totalCount ?? 0,
        });
      } catch (error) {
        await dispatch('errors/pushError', error, { root: true });
      } finally {
        commit('setIsLoadingAssignableLocationPreferences', false);
      }
    },
    savePreferenceUpdates: async (
      { commit, dispatch, getters },
      preferences
    ) => {
      commit('setSavingPreferences', true);

      try {
        const mappedPreferences = mapPreferences(
          getters.profileId,
          preferences.preferences
        );

        await Api.locationPreferences.setPreferences(getters.profileId, {
          preferences: mappedPreferences,
        });
        await dispatch(
          'snackbar/update',
          { message: 'Campuspräferenzen wurden gespeichert' },
          {
            root: true,
          }
        );
      } catch (error) {
        await dispatch('errors/pushError', error, { root: true });
      } finally {
        commit('setSavingPreferences', false);
      }
    },
    async getLastUpdatedPreferencesMetadata({ commit }, profileId) {
      try {
        const response =
          await Api.locationPreferences.getLastUpdatedPreferencesMetadata(
            profileId
          );

        const mappedResponse =
          !response.data || response.data === '' ? null : response.data;

        commit('setLastUpdatedPreferencesMetadata', mappedResponse);
      } catch (e) {
        commit('setLastUpdatedPreferencesMetadata', null);
      } finally {
        commit('setIsMetadataLoaded', true);
      }
    },
    updateAssignedPagination({ commit, state }, pagination) {
      commit('updateAssignedPagination', {
        ...state.assignedPagination,
        ...pagination,
      });
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setProfileId(state, profileId) {
      state.profileId = profileId;
    },
    setSavingPreferences(state, isSavingPreferences) {
      state.isSavingPreferences = isSavingPreferences;
    },
    setLoadingPreferencesStatus(state, isLoadingPreferences) {
      state.isLoadingPreferences = isLoadingPreferences;
    },
    setAssignedLocations(state, assignedLocations) {
      state.assignedLocationPreferences = assignedLocations;
    },
    setAssignableLocations(state, assignableLocations) {
      state.assignableLocationPreferences = assignableLocations;
    },
    updateAssignedPagination(state, pagination) {
      state.assignedPagination = pagination;
    },
    updateAssignablePagination(state, pagination) {
      state.assignablePagination = pagination;
    },
    setIsLoadingAssignableLocationPreferences(
      state,
      isLoadingAssignableLocationPreferences
    ) {
      state.isLoadingAssignableLocationPreferences =
        isLoadingAssignableLocationPreferences;
    },
    setLastUpdatedPreferencesMetadata(state, lastUpdatedPreferencesMetadata) {
      state.lastUpdatedPreferencesMetadata = lastUpdatedPreferencesMetadata;
    },
    setIsMetadataLoaded(state, isMetadataLoaded) {
      state.isMetadataLoaded = isMetadataLoaded;
    },
  },
};

function getSearchParams(params = {}) {
  return { ...params, limit: params.limit || 4, offset: params.offset || 0 };
}

function mapPreferences(profileId, preferences = []) {
  return preferences.map((preference) => ({
    profileId,
    id: preference.id,
    locationId: preference.locationId,
    preference: preference.preference.value,
  }));
}
