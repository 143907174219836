<template>
  <n-table
    :header-items="[
      {
        class: 'w-1/12 px-3',
        get label() {
          return $t('common.labels.selected');
        },
        key: 'selected',
      },
      {
        class: 'w-3/12 px-3',
        get label() {
          return $t('common.labels.course-title');
        },
        key: 'defaultTitle',
      },
      {
        class: 'w-3/12 px-3',
        get label() {
          return $t('common.labels.course-code');
        },
        key: 'courseCode',
      },
      {
        class: 'w-3/12 px-3',
        get label() {
          return $t('common.labels.subject');
        },
        key: 'subject',
      },
      {
        class: 'w-2/12 px-3',
        get label() {
          return $t('common.labels.study-program');
        },
        key: 'studyPrograms',
      },
    ]"
    :data="data"
    :is-loading="isLoading"
  >
    <template #filter-studyPrograms="{ close }">
      <table-filter-multiselect
        id="study_program_filter"
        v-model="studyProgramsFilterModel"
        data-test="study-program-filter"
        value="studyProgram"
        :label="$t('common.labels.study-program')"
        :options="trackedStudyPrograms"
        multiple
        @close="close"
      />
    </template>
    <template #row="{ item }">
      <n-table-row :item="item">
        <td class="text-center">
          <n-tooltip
            :content="
              item.assinged &&
              'Diese Kurskompetenz wurde bereits einer Lehrperson zugewiesen.'
            "
          >
            <n-checkbox
              :model-value="selectionState(item)"
              :disabled="item.assigned"
              @update:model-value="selectionChanged(item.id)"
            />
          </n-tooltip>
        </td>
        <td class="font-bold">
          <n-tooltip :content="item.defaultTitle">
            {{ truncateText(item.defaultTitle) }}
          </n-tooltip>
        </td>
        <td>
          {{ item.courseCode }}
        </td>
        <td>
          <n-tooltip :content="item.subject">
            {{ truncateText(item.subject) }}
          </n-tooltip>
        </td>
        <td>
          <scrollable-tooltip :content="item.studyPrograms?.join(', ') || '-'">
            {{
              item.studyPrograms?.length
                ? truncateText(item.studyPrograms.join(', '))
                : '-'
            }}
          </scrollable-tooltip>
        </td>
      </n-table-row>
    </template>
  </n-table>
</template>
<script>
import { truncateText } from '@/utils/text-utils';
import ScrollableTooltip from '@/components/common/ScrollableTooltip.vue';
import studyProgramFilterTableMixin from '@/mixins/study-program-filter-table.mixin';

export default {
  name: 'CourseCapabilitiesTable',
  components: { ScrollableTooltip },
  mixins: [studyProgramFilterTableMixin],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selection-changed'],
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    truncateText,
    selectionState(course) {
      return course.assigned || this.selected.includes(course.id);
    },
    selectionChanged(courseId) {
      const exists = this.selected.includes(courseId);

      if (exists) {
        this.selected = this.selected.filter((id) => id !== courseId);
      } else {
        this.selected.push(courseId);
      }

      this.$emit('selection-changed', this.selected);
    },
  },
};
</script>
