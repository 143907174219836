<template>
  <div class="text-xs text-left -mb-6">
    <p>
      {{ $t('views.deputation.instructions.1') }}
    </p>
    <p>
      {{ $t('views.deputation.instructions.2') }}
    </p>
    <ul class="list-disc px-4 py-2">
      <li>
        <span class="font-bold" :class="under">
          {{ $t('views.deputation.instructions.deputation-under') }} <br />
        </span>
        {{ $t('views.deputation.instructions.3') }}
      </li>
      <li>
        <span class="font-bold" :class="onTarget">
          {{ $t('views.deputation.instructions.deputation-plan') }} <br />
        </span>
        {{ $t('views.deputation.instructions.4') }}
      </li>
      <li>
        <span class="font-bold" :class="over">
          {{ $t('views.deputation.instructions.deputation-over') }} <br />
        </span>
        {{ $t('views.deputation.instructions.5') }}
      </li>
    </ul>
    <p>
      {{ $t('views.deputation.instructions.6') }}
      <br />
      {{ $t('views.deputation.instructions.7') }}
    </p>
  </div>
</template>
<script>
import {
  AvailableDeputationStatus,
  getDeputationStatusColorTuple,
} from '@/constants/picklists';

export default {
  name: 'Instructions',
  components: {},
  data() {
    return {};
  },
  computed: {
    over() {
      return (
        'text-' +
        getDeputationStatusColorTuple(AvailableDeputationStatus.OVERDEPUTATION)
          .filledColor
      );
    },
    under() {
      return (
        'text-' +
        getDeputationStatusColorTuple(AvailableDeputationStatus.UNDERDEPUTATION)
          .filledColor
      );
    },
    onTarget() {
      return (
        'text-' +
        getDeputationStatusColorTuple(AvailableDeputationStatus.ONTARGET)
          .filledColor
      );
    },
  },
};
</script>
