import { t } from '@/plugins/translations';

export const SEMESTER_SHORT = Object.freeze([
  {
    key: 'winter',
    get text() {
      return t('common.labels.winter-semester');
    },
  },
  {
    key: 'summer',
    get text() {
      return t('common.labels.summer-semester');
    },
  },
]);
