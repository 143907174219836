<template>
  <n-modal
    :title="$t('views.common.unsaved-changes-modal.title')"
    :is-open="isOpen"
    @close="$emit('close')"
  >
    <template #default>
      <p class="text-base">
        {{ $t('views.common.unsaved-changes-modal.note') }}
      </p>
    </template>
    <template #actions>
      <n-button variant="secondary" @click="$emit('closeAndSave')">
        {{ $t('views.common.unsaved-changes-modal.accept-changes') }}
      </n-button>
      <n-button class="danger" variant="primary" @click="$emit('leaveSite')">
        {{ $t('views.common.unsaved-changes-modal.decline-changes') }}
      </n-button>
    </template>
  </n-modal>
</template>

<script>
export default {
  name: 'UnsavedChangesModal',
  props: {
    hasChanges: {
      type: Boolean,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['closeAndSave', 'leaveSite', 'close'],
};
</script>
