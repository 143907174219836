<template>
  <section-structure>
    <template #headline>
      <h3 class="font-bold uppercase text-blue-600">
        {{ $t('views.profiles-contacts.headline') }}
      </h3>
    </template>
    <div class="col-span-full">
      <addresses :addresses="addresses" :disabled="disabled" v-bind="$attrs" />
      <emails :emails="emails" :disabled="emailsDisabled" v-bind="$attrs" />
      <phones :phones="phones" :disabled="disabled" v-bind="$attrs" />
    </div>
  </section-structure>
</template>

<script>
import Addresses from '@/components/profiles/Addresses';
import Emails from '@/components/profiles/Emails';
import Phones from '@/components/profiles/Phones';
import SectionStructure from '@/components/common/SectionStructure';

export default {
  name: 'ProfileContacts',
  components: {
    Addresses,
    Emails,
    Phones,
    SectionStructure,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    emailsDisabled: {
      type: Boolean,
      default: false,
    },
    addresses: {
      type: Array,
      default: () => [],
    },
    emails: {
      type: Array,
      default: () => [],
    },
    phones: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
