<template>
  <n-modal
    :is-open="isOpen"
    :title="$t('views.location.assign-unit-and-campus-modal.title')"
    @close="$emit('close')"
  >
    <validation-form
      v-slot="{ meta }"
      ref="observer"
      :initial-values="initialValues"
    >
      <div class="m-3">
        <validation-provider
          v-slot="{ errors, field }"
          rules="required"
          name="unit"
          label="Unit"
        >
          <n-select
            :model-value="selectedUnit"
            label="Unit"
            class="mb-3"
            :errors="errors"
            v-bind="field"
            required
            :options="possibleUnits"
            @update:model-value="(data) => $emit('updateSelectedUnit', data)"
          />
        </validation-provider>
      </div>
      <div class="flex justify-center h-[250px] mt-6">
        <img :src="IMAGE_URLS.CAMPUS" class="h-full" />
      </div>
      <div class="my-3">
        <p class="text-xs text-gray-700">
          {{ $t('common.labels.mandatory-fields') }}
        </p>
      </div>
      <div class="flex justify-end">
        <n-button
          variant="primary"
          :disabled="!meta.dirty || !meta.valid"
          @click="$emit('save')"
        >
          {{ $t('common.labels.save') }}
        </n-button>
      </div>
    </validation-form>
  </n-modal>
</template>

<script>
import { IMAGE_URLS } from '@/constants/images';

export default {
  name: 'AssignUnitAndCampusModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    possibleUnits: {
      type: [Array, null],
      required: true,
    },
    selectedUnit: {
      type: [String, null],
      required: true,
    },
    possibleCampuses: {
      type: Array,
      required: true,
    },
  },
  emits: ['close', 'save', 'updateSelectedUnit'],
  computed: {
    IMAGE_URLS() {
      return IMAGE_URLS;
    },
    isOnlineUnit() {
      return this.possibleCampuses === undefined;
    },
    initialValues() {
      return {
        unit: null,
        campus: null,
      };
    },
  },
};
</script>
