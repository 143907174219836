<template>
  <n-input
    v-model="model"
    :label="$t('common.labels.search-text')"
    class="grow mt-12 mb-6"
    icon="magnify"
  />
  <div
    v-if="filters.length"
    class="flex bg-blue-100 p-3 px-4 items-center h-16"
  >
    <div>
      <span class="font-bold text-gray-700">{{
        $t('common.labels.filter')
      }}</span>
    </div>
    <div class="flex-1 px-2">
      <span
        v-for="filter in filters"
        :key="filter"
        class="flex-0 mx-2 bg-cta px-2 text-white inline-block"
      >
        <span class="font-bold">{{ filter.label }}:</span>
        {{ getFilterValue(filter) }}
        <n-button
          variant="quaternary"
          @click="$emit('reset-filter', filter.key)"
        >
          <n-icon icon="mdi-close-box" class="text-white icon-md" />
        </n-button>
      </span>
    </div>
    <div>
      <n-button variant="tertiary" @click="$emit('reset-filter')">
        {{ $t('common.labels.reset-filter') }}
      </n-button>
    </div>
  </div>
  <div v-else class="flex bg-blue-100 p-3 px-4 items-center">
    <div class="text-blue-600">{{ $t('common.labels.no-filter-set') }}</div>
  </div>
</template>
<script>
import { t } from '@/plugins/translations';

export default {
  name: 'ProfilesListFilterInfo',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['reset-filter', 'update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    getFilterValue(filter) {
      if (
        Object.prototype.hasOwnProperty.call(filter, 'valueTranslationPath')
      ) {
        return Array.isArray(filter.value)
          ? filter.value
              .map((value) =>
                this.getFilterValueTranslation(
                  filter.valueTranslationPath,
                  value
                )
              )
              .join('; ')
          : this.getFilterValueTranslation(
              filter.valueTranslationPath,
              filter.value
            );
      } else {
        return Array.isArray(filter.value)
          ? filter.value.join('; ')
          : filter.value;
      }
    },
    getFilterValueTranslation(valueTranslationPath, value) {
      return t(`${valueTranslationPath}.${value}`);
    },
  },
};
</script>
