<template>
  <n-button
    variant="quaternary"
    :color="buttonColor"
    :disabled="disabled"
    :title="buttonTitle"
    @click="$emit(isProfileSoftDeleted ? 'restore' : 'softDelete')"
  >
    <n-icon :icon="buttonIcon" class="mr-2" />
    {{ buttonText }}
  </n-button>
</template>
<script>
const { mapGetters } = createNamespacedHelpers('puffer');

import { t } from '@/plugins/translations';
import { hasPermissionFor } from '@/utils/permissionUtil';
import { createNamespacedHelpers } from 'vuex';

export default {
  name: 'SoftDeleteButton',
  emits: ['softDelete', 'restore'],
  computed: {
    ...mapGetters(['isProfileSoftDeleted']),
    buttonTitle() {
      if (this.isProfileSoftDeleted && !this.userCanRestoreProfiles) {
        return t(
          'views.profiles.soft-delete-button.not-allowed-restore-profile'
        );
      }

      if (!this.isProfileSoftDeleted && !this.userCanDeleteProfiles) {
        return t(
          'views.profiles.soft-delete-button.not-allowed-delete-profile'
        );
      }

      return this.isProfileSoftDeleted
        ? t('views.profiles.soft-delete-button.restore-profile')
        : t('views.profiles.soft-delete-button.delete-profile');
    },
    buttonText() {
      return this.isProfileSoftDeleted
        ? t('common.labels.restore')
        : t('common.labels.delete');
    },
    buttonIcon() {
      return this.isProfileSoftDeleted ? 'mdi-restore' : 'mdi-delete';
    },
    buttonColor() {
      return this.isProfileSoftDeleted ? 'success' : 'danger';
    },
    disabled() {
      if (this.isProfileSoftDeleted) {
        return !this.userCanRestoreProfiles;
      }

      return !this.userCanDeleteProfiles;
    },
    userCanDeleteProfiles() {
      return hasPermissionFor('archived-academic-staff-profile', ['d']);
    },
    userCanRestoreProfiles() {
      return hasPermissionFor('archived-academic-staff-profile', ['c']);
    },
  },
};
</script>
