import { ApiClient } from './ApiClient';

function withCancellation(requestCallback) {
  return (...args) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const request = requestCallback(...args, { signal }).catch((error) => {
      if (error.name === 'CanceledError') {
        // eslint-disable-next-line no-console
        console.error('Request was cancelled');
      } else {
        throw error;
      }
    });

    return {
      request,
      cancel: () => abortController.abort(),
    };
  };
}

export const BasicResourceFactory = (resourceName, client = ApiClient) => {
  const RESOURCE_BASE_PATH = `/${resourceName}`;

  return {
    get: withCancellation((params, config) =>
      client.instance.get(RESOURCE_BASE_PATH, { params, ...config })
    ),
    post: (payload) => client.instance.post(RESOURCE_BASE_PATH, payload),
    getById: withCancellation((id, config) =>
      client.instance.get(`${RESOURCE_BASE_PATH}/${id}`, config)
    ),
    updateById: (id, payload) =>
      client.instance.put(`${RESOURCE_BASE_PATH}/${id}`, payload),
    deleteById: (id) => client.instance.delete(`${RESOURCE_BASE_PATH}/${id}`),
    getSubPathById: (id, path) =>
      client.instance.get(`${RESOURCE_BASE_PATH}/${id}/${path}`),
    postSubPathById: (id, path, payload) =>
      client.instance.post(`${RESOURCE_BASE_PATH}/${id}/${path}`, payload),
    patchSubPathById: (id, path, payload) =>
      client.instance.patch(`${RESOURCE_BASE_PATH}/${id}/${path}`, payload),
  };
};

export const ContactsResourceFactory = (resourceName) => {
  const { updateById, deleteById } = BasicResourceFactory(resourceName);

  return {
    updateById: (id, payload) => updateById(id, payload),
    deleteById: (id) => deleteById(id),
  };
};
