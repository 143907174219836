import {
  mapMultipleUnitsNames,
  mapSingleUnitName,
  unmapSingleMappedUnitName,
} from '@/utils/units-name-mapper';
import { Api } from '@/api';

export default {
  namespaced: true,
  state: {
    profileId: null,
    assignedMainUnit: null,
    assignedMainCampus: null,
    mainLocaitonPossibleUnits: [],
    mainLocationPossibleCampuses: [],
    isLoading: false,
    mainLocationModalOpen: false,
  },
  getters: {
    mainLocationPossibleUnits: (state) =>
      mapMultipleUnitsNames(state.mainLocaitonPossibleUnits).sort(),
    assignedMainUnit: (state) => mapSingleUnitName(state.assignedMainUnit),
    assignedMainCampus: (state) => state.assignedMainCampus,
    mainLocationPossibleCampuses: (state) => {
      return Array.isArray(state.mainLocationPossibleCampuses)
        ? state.mainLocationPossibleCampuses.sort()
        : state.mainLocationPossibleCampuses;
    },
    isLoadingMainLocation: (state) => state.isLoading,
    profileId: (state) => state.profileId,
    mainLocationModalOpen: (state) => state.mainLocationModalOpen,
  },
  actions: {
    resetState({ commit }) {
      commit('setProfileId', null);

      commit('setAssignedMainUnit', null);

      commit('setAssignedMainCampus', null);

      commit('setPossibleUnits', []);

      commit('setPossibleCampuses', []);

      commit('setIsLoading', false);

      commit('setMainLocationModalOpen', false);
    },
    async fetchMainLocationOfProfile({ commit, state }) {
      commit('setIsLoading', true);

      try {
        const { data } = await Api.locations.getMainLocation(state.profileId);

        commit('setAssignedMainUnit', data.unit);

        commit('setAssignedMainCampus', data.campus);

        commit('setIsLoading', false);
      } catch (error) {
        commit('setIsLoading', false);
      }
    },
    async fetchPossibleUnits({ commit, state }) {
      commit('setIsLoading', true);

      try {
        const { data } =
          await Api.locations.getAssignedUnitsAndCampusesByProfileId(
            state.profileId
          );

        const allPossibleUnits = data.map((item) => item.unit);

        commit('setPossibleUnits', allPossibleUnits);
      } catch (error) {
        commit('setPossibleUnits', []);
      }
      commit('setIsLoading', false);
    },
    async fetchPossibleCampuses({ commit, state }, unit) {
      commit('setIsLoading', true);

      const unmappedUnitName = unmapSingleMappedUnitName(unit);
      try {
        const { data } =
          await Api.locations.getAssignedUnitsAndCampusesByProfileId(
            state.profileId
          );

        let allPossibleCampusesObject = data
          .filter((item) => item.unit === unmappedUnitName)
          .flatMap((item) => item.campuses);

        if (
          allPossibleCampusesObject.length === 1 &&
          allPossibleCampusesObject[0] === null
        ) {
          allPossibleCampusesObject = undefined;
        }

        commit('setPossibleCampuses', allPossibleCampusesObject);
      } catch (error) {
        commit('setPossibleCampuses', []);
      }
      commit('setIsLoading', false);
    },
    async updateMainLocation({ commit, state, dispatch }, { unit, campus }) {
      commit('setIsLoading', true);

      const unmappedUnitName = unmapSingleMappedUnitName(unit);

      try {
        await Api.locations.updateMainLocation(state.profileId, {
          unit: unmappedUnitName,
          campus,
        });

        await dispatch('fetchMainLocationOfProfile');
      } finally {
        commit('setMainLocationModalOpen', false);
        commit('setIsLoading', false);
      }
    },
    setProfileId({ commit }, profileId) {
      commit('setProfileId', profileId);
    },
    openMainLocationModal({ commit }) {
      commit('setMainLocationModalOpen', true);
    },
    closeMainLocationModal({ commit }) {
      commit('setMainLocationModalOpen', false);
    },
  },
  mutations: {
    setProfileId(state, value) {
      state.profileId = value;
    },
    setAssignedMainUnit(state, value) {
      state.assignedMainUnit = value;
    },
    setAssignedMainCampus(state, value) {
      state.assignedMainCampus = value;
    },
    setPossibleUnits(state, value) {
      state.mainLocaitonPossibleUnits = value;
    },
    setPossibleCampuses(state, value) {
      state.mainLocationPossibleCampuses = value;
    },
    setIsLoading(state, profileId) {
      state.isLoading = profileId;
    },
    setMainLocationModalOpen(state, value) {
      state.mainLocationModalOpen = value;
    },
  },
};
