<template>
  <form class="flex items-center">
    <n-input type="file" accept=".csv" @change="onFileSelected($event)" />
    <n-button variant="primary" class="ml-2 p-1" @click="initiateCsvUpload">
      Upload
    </n-button>
  </form>
</template>
<script>
import { t } from '@/plugins/translations';
import { mapActions } from 'vuex';
import { dataImport } from '@/api/dataImport';

export default {
  name: 'FileImport',
  emits: ['file-uploaded'],
  data() {
    return {
      selectedFile: null,
    };
  },
  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/update',
    }),
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
    },
    async initiateCsvUpload() {
      if (!this.selectedFile) {
        return;
      }

      const fileData = this.prepareFileData(this.selectedFile);

      await this.uploadFileToServer(fileData);

      await this.displayNotification({
        message: t('views.data-import-file-import.success-notification'),
        type: 'success',
      });

      this.$emit('file-uploaded');
    },
    prepareFileData(file) {
      const formData = new FormData();

      formData.append('file', file);

      return formData;
    },
    async uploadFileToServer(fileData) {
      await dataImport.uploadFile(fileData);
    },
    async displayNotification(notification) {
      await this.showSnackbar(notification);
    },
  },
};
</script>
