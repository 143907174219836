<template>
  <n-snackbar
    :model-value="!!message"
    :timer-length="1500"
    :variant="error ? 'error' : 'success'"
  >
    <template #message>
      {{ message }}
    </template>
  </n-snackbar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('snackbar');

export default {
  name: 'Snackbar',
  computed: {
    ...mapState(['message', 'error']),
  },
};
</script>
