<template>
  <n-input
    v-model="capacity"
    class="w-full"
    :disabled="disabled"
    :label="label"
    v-bind="field"
    name="capacity"
    :errors="!isValid ? 'Der Wert muss zwischen 0 und 999 liegen' : null"
    type="number"
  />
</template>

<script>
import { NInput } from '@careerpartner/nitro';
import { debounce } from 'lodash';

export default {
  name: 'CapacityInput',
  components: {
    NInput,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      isValid: true,
      maxValue: 999,
    };
  },
  computed: {
    capacity: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.setCapacity(val);
      },
    },
  },
  methods: {
    setCapacity: debounce(function (val) {
      try {
        const parsedVal = parseInt(val, 10);

        if (parsedVal > this.maxValue) {
          this.isValid = false;
          this.$emit('update:model-value', this.maxValue);
        } else if (parsedVal < 0) {
          this.isValid = false;
          this.$emit('update:model-value', 0);
        } else {
          this.isValid = true;
          this.$emit('update:model-value', parsedVal);
        }
      } catch (e) {
        this.isValid = false;
        this.$emit('update:model-value', 0);
      }
    }, 500),
  },
};
</script>
