import { t } from '@/plugins/translations';

export const PROFILE_PRESETS = Object.freeze([
  {
    key: 'professor',
    get text() {
      return t('common.enums.profile-preset.professor');
    },
  },
  {
    key: 'academic-lecturer-internal',
    get text() {
      return t('common.enums.profile-preset.academic-lecturer-internal');
    },
  },
  {
    key: 'academic-lecturer-external',
    get text() {
      return t('common.enums.profile-preset.academic-lecturer-external');
    },
  },
]);
