import { ApiClient } from '@/api/ApiClient';

export const availabilityPreferenceApi = {
  async getAvailabilityPreferences(profileId) {
    const { data } = await ApiClient.instance.get(
      `profiles/${profileId}/availability-preferences`
    );
    return data;
  },
  async getAvailabilitySlots() {
    const { data } = await ApiClient.instance.get('/availability-slots');
    return data.sort((a, b) => a.startTime.localeCompare(b.startTime));
  },
  async updatePreferences(profileId, preferences) {
    await ApiClient.instance.post(
      `profiles/${profileId}/availability-preferences`,
      preferences
    );
  },
};
