<template>
  <section-structure>
    <template #headline>
      <h3 class="font-bold uppercase text-blue-600">
        {{ $t('views.profiles-personal.headline') }}
      </h3>
    </template>
    <div class="col-span-3 flex space-x-2">
      <validation-provider
        v-slot="{ errors, field }"
        name="title"
        :label="$t('common.labels.title')"
      >
        <n-select
          id="profile-form__title"
          v-model="profile.honorificPrefix"
          v-bind="field"
          track-by="key"
          class="grow"
          option-label="key"
          mapped
          :disabled="disabled"
          :errors="errors"
          :options="honorificPrefixes"
          :label="$t('common.labels.title')"
          data-test="profile-form-honorific-prefix"
        />
      </validation-provider>
    </div>
    <div class="col-span-3 flex space-x-2">
      <validation-provider
        v-slot="{ errors, field }"
        name="degree"
        :label="$t('common.labels.academic-level')"
      >
        <n-select
          id="profile-form__degree"
          v-bind="field"
          v-model="profile.degree"
          :disabled="disabled"
          :errors="errors"
          :options="degrees"
          class="grow"
          :label="$t('common.labels.academic-level')"
          option-label="text"
          track-by="text"
          mapped
          data-test="profile-form-degree"
        />
      </validation-provider>
    </div>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors, field }"
        :rules="rules.givenName"
        name="givenName"
        :label="$t('common.labels.firstname')"
      >
        <n-input
          id="profile-form__given-name"
          v-model="profile.givenName"
          :disabled="disabled"
          :errors="errors"
          v-bind="field"
          :label="$t('common.labels.firstname')"
          data-test="profile-form-given-name"
          required
          @change="trim('givenName')"
        />
      </validation-provider>
    </div>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors, field }"
        name="familyName"
        :label="$t('common.labels.lastname')"
        :disabled="disabled"
        :rules="rules.familyName"
      >
        <n-input
          id="profile-form__family-name"
          v-model="profile.familyName"
          :disabled="disabled"
          :errors="errors"
          v-bind="field"
          :label="$t('common.labels.lastname')"
          data-test="profile-form-family-name"
          required
          @change="trim('familyName')"
        />
      </validation-provider>
    </div>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors, field }"
        name="dateOfBirth"
        :label="$t('common.labels.birthdate')"
      >
        <n-input
          id="profile-form__birth-date"
          :model-value="profile.dateOfBirth"
          :disabled="disabled"
          :errors="errors"
          v-bind="field"
          type="date"
          :label="$t('common.labels.birthdate')"
          data-test="profile-form-date-of-birth"
          @update:model-value="updateDateOfBirth"
        />
      </validation-provider>
    </div>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors, field }"
        name="gender"
        :label="$t('common.labels.sex')"
        rules="required"
      >
        <n-select
          id="profile-form__gender"
          v-model="profile.gender"
          :disabled="disabled"
          :errors="errors"
          v-bind="field"
          :options="genders"
          :label="$t('common.labels.sex')"
          track-by="value"
          option-label="text"
          mapped
          data-test="profile-form-gender"
          required
        />
      </validation-provider>
    </div>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors, field }"
        name="citizenships"
        :label="$t('views.profiles-personal.citizenship')"
      >
        <n-select
          id="profile-form__citizenship"
          v-model="profile.citizenships"
          :disabled="disabled"
          :errors="errors"
          :options="countries"
          v-bind="field"
          :max="2"
          :label="$t('views.profiles-personal.citizenship')"
          option-label="name"
          track-by="countryIso"
          mapped
          multiple
          searchable
          data-test="profile-form-citizenship"
        />
      </validation-provider>
    </div>
    <div class="col-span-3">
      <validation-provider
        v-slot="{ errors, field }"
        name="workdayId"
        :label="$t('common.labels.workday-id')"
      >
        <n-input
          id="profile-form__workday_id"
          :model-value="profile.workdayId"
          :label="$t('common.labels.workday-id')"
          :errors="errors"
          v-bind="field"
          @update:model-value="updateWorkdayId"
        />
      </validation-provider>
    </div>
    <div class="col-span-full">
      <div class="text-caption italic text-gray-700">
        * {{ $t('common.labels.required') }}
      </div>
    </div>
  </section-structure>
</template>

<script>
import { DEGREES } from '@/constants/picklists';
import SectionStructure from '@/components/common/SectionStructure';
import { CMSListings } from '@epos/core-applets';

export default {
  name: 'Personal',
  components: {
    SectionStructure,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile: this.modelValue,
      countries: [],
      genders: [],
      honorificPrefixes: [],
    };
  },
  computed: {
    degrees() {
      return DEGREES;
    },
    rules() {
      return {
        givenName: { required: true, max: 100 },
        familyName: { required: true, max: 50 },
      };
    },
  },
  async created() {
    this.countries = await CMSListings.getCountries();
    this.genders = await CMSListings.getGenders();
    this.honorificPrefixes = await CMSListings.getHonorificPrefixes();
  },
  methods: {
    trim(prop) {
      if (
        Object.keys(this.profile).includes(prop) &&
        'string' === typeof this.profile[prop]
      ) {
        this.profile[prop] = this.profile[prop].trim();
      }
    },
    updateDateOfBirth(value) {
      this.profile.dateOfBirth = value ? value : null;
    },
    updateWorkdayId(value) {
      this.profile.workdayId = value ? value : null;
    },
  },
};
</script>
