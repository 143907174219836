import { addresses } from '@/api/addresses';
import { emails } from '@/api/emails';
import { phones } from '@/api/phones';
import { profiles } from '@/api/profiles';
import { universityCapabilities } from '@/api/universityCapabilities';
import { courseCapabilities } from '@/api/courseCapabilities';
import { assignments } from '@/api/assignments';
import { deputation } from '@/api/deputation';
import { locations } from '@/api/locations';
import { initApi } from '@/api/init';
import { coursesApi } from '@/api/courses';
import { dataImport } from '@/api/dataImport';
import { locationPreferences } from '@/api/locationPreferences';
import { planningPeriod } from '@/api/planning-periods';

export const Api = Object.freeze({
  addresses,
  emails,
  phones,
  profiles,
  universityCapabilities,
  courseCapabilities,
  assignments,
  deputation,
  locations,
  initApi,
  coursesApi,
  dataImport,
  locationPreferences,
  planningPeriod,
});
