<template>
  <div class="bg-white p-8 elevated rounded">
    <div class="font-bold text-blue-600 text-md">{{ cardHeadingLabel }}</div>
    <div class="pt-2">
      <deputation-tooltip
        :title="tooltipTitle"
        :deputation-status="overallDeputationStatus"
        :planned="100"
        :actual="overallDeputationInPercent"
      >
        <DeputationPercentage
          :percentage="overallDeputationInPercent"
          :deputation-status="overallDeputationStatus"
        ></DeputationPercentage>
      </deputation-tooltip>
    </div>
    <DeputationVerticalBarGroup
      class="pt-2"
      :deputation-data="deputationData.capabilities"
    ></DeputationVerticalBarGroup>
  </div>
</template>
<script>
import { t } from '@/plugins/translations';
import { buildDeputationGroupLabel } from '@/utils/build-deputation-group-label';
import DeputationVerticalBarGroup from '@/components/deputation/Deputation/Detail/DeputationVerticalBarGroup.vue';
import DeputationTooltip from '@/components/deputation/Common/DeputationTooltip.vue';
import DeputationPercentage from '@/components/deputation/Common/DeputationPercentage.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DeputationDetailCard',
  components: {
    DeputationTooltip,
    DeputationVerticalBarGroup,
    DeputationPercentage,
  },
  props: {
    groupType: {
      type: String,
      required: true,
    },
    groupValue: {
      type: Number,
      required: true,
    },
    deputationData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('deputation', ['selectedYear']),
    tooltipTitle() {
      return `${t('views.deputation.deputation-detail-card.tooltip')} ${
        this.cardHeadingLabel
      }`;
    },
    cardHeadingLabel() {
      return buildDeputationGroupLabel(
        this.groupValue,
        this.groupType,
        this.selectedYear
      );
    },
    overallDeputationInPercent() {
      return this.deputationData.overallDeputationInPercent;
    },
    overallDeputationStatus() {
      return this.deputationData.overallDeputationStatus;
    },
  },
};
</script>
