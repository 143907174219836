<template>
  <span :class="textClasses"> {{ percentage }}% </span>
</template>
<script>
import { getDeputationStatusColorTuple } from '@/constants/picklists';

export default {
  name: 'DeputationPercentage',
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    deputationStatus: {
      type: String,
      required: true,
    },
    percentage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    textClasses() {
      return `font-bold ${this.fontSize} text-${this.textColor}`;
    },
    textColor() {
      return getDeputationStatusColorTuple(this.deputationStatus)?.filledColor;
    },
    fontSize() {
      if (this.size === 'medium') {
        return 'text-2xl';
      } else if (this.size === 'large') {
        return 'text-4xl';
      } else {
        return 'text-2xl';
      }
    },
  },
};
</script>
