export default {
  namespaced: true,
  state: {
    currentContact: null,
    currentProfile: null,
  },
  getters: {
    currentContact: (state) => state.currentContact,
    currentProfile: (state) => state.currentProfile,
    isProfileSoftDeleted: (state) => state.currentProfile?.deletedAt,
  },
  actions: {
    resetState({ commit }) {
      commit('updateContact', null);
      commit('updateProfile', null);
    },
    updateStoreContact({ commit }, contact) {
      commit('updateContact', contact);
    },
    updateStoreProfile({ commit }, profile) {
      commit('updateProfile', profile);
    },
  },
  mutations: {
    updateContact(state, contact) {
      state.currentContact = contact;
    },
    updateProfile(state, profile) {
      state.currentProfile = profile;
    },
  },
};
