<template>
  <n-table :header-items="headers" :data="data" :is-loading="isLoading">
    <template #row="{ item }">
      <n-table-row :item="item">
        <td>{{ item.course.defaultTitle }}</td>
        <td class="pl-3">{{ item.course.courseCode }}</td>
        <td class="pl-3 justify-center">
          <set-capability-preference-chip
            :selected-value="item.preference"
            :editable="false"
          ></set-capability-preference-chip>
        </td>
        <td class="text-center">
          <n-button
            variant="quaternary"
            @click="$emit('deleteCourseCapability', item)"
          >
            <n-icon class="text-red-500" icon="mdi-delete-outline"></n-icon>
          </n-button>
        </td>
        <template #extended>
          <capabilities-expandable-table-row
            :item="item"
          ></capabilities-expandable-table-row>
        </template>
      </n-table-row>
    </template>
  </n-table>
  <n-pagination
    v-if="pagination?.totalCount > 0"
    class="mt-16"
    :model-value="pagination"
    @update:model-value="onPaginationChanged"
  />
</template>
<script>
import { t } from '@/plugins/translations';
import { mapState } from 'vuex';
import CapabilitiesExpandableTableRow from '@/components/course-capabilities/Capabilities/CapabilitiesExpandableTableRow.vue';
import SetCapabilityPreferenceChip from '@/components/course-capabilities/Preferences/SetCapabilityPreferenceChip.vue';

export default {
  name: 'AssignedCourseCapabilitiesTable',
  components: {
    CapabilitiesExpandableTableRow,
    SetCapabilityPreferenceChip,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['deleteCourseCapability'],
  computed: {
    ...mapState('courseCapabilities', ['isLoading', 'pagination']),
    headers() {
      return [
        {
          class: 'w-7/12 px-3',
          get label() {
            return t('common.labels.description');
          },
          key: 'title',
        },
        {
          class: 'w-2/12 px-3',
          get label() {
            return t('common.labels.course-code');
          },
          key: 'courseCode',
        },
        {
          class: 'w-2/12 px-3',
          get label() {
            return t('common.labels.preference');
          },
          key: 'preference',
        },
        {
          class: 'w-1/12 px-3',
          get label() {
            return t('common.labels.action');
          },
          key: 'action',
        },
      ];
    },
  },
  methods: {
    async onPaginationChanged(pagination) {
      await this.$store.dispatch(
        'courseCapabilities/updatePagination',
        pagination
      );

      const currentQuery = { ...this.$route.query };

      await this.$router.push({
        query: {
          ...currentQuery,
          offset: pagination.offset,
          limit: pagination.limit,
        },
      });
    },
  },
};
</script>
