import { ApiClient } from '@/api/ApiClient';
import { pluckData } from '@/utils/pluckData';
import { PROFILES_BASE_ROUTE } from '@/api/profiles';

const BASE_ROUTE = 'courses';

export const coursesApi = {
  searchForCourses: (profileId, params) => {
    return ApiClient.instance
      .post(`/${BASE_ROUTE}/${PROFILES_BASE_ROUTE}/${profileId}`, params)
      .then(pluckData);
  },
};
