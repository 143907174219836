import TableFilterMultiselect from '@/components/common/TableFilterMultiselect.vue';

export default {
  props: {
    studyProgramsFilter: {
      type: Array,
      required: true,
    },
    studyPrograms: {
      type: Array,
      required: true,
    },
  },
  components: { TableFilterMultiselect },
  emits: ['update:study-programs-filter'],
  computed: {
    studyProgramsFilterModel: {
      get() {
        return this.studyProgramsFilter;
      },
      set(values) {
        this.$emit('update:study-programs-filter', values);
      },
    },

    trackedStudyPrograms() {
      return this.studyPrograms.map((studyProgram) => ({
        key: studyProgram,
        title: studyProgram,
      }));
    },
  },
};
