<template>
  <div
    v-if="filters.length"
    class="flex bg-blue-100 p-3 px-4 items-center h-16"
  >
    <div>
      <span class="font-bold text-gray-700">Filter</span>
    </div>
    <div class="flex-1 px-2">
      <span
        v-for="filter in filters"
        :key="filter.label"
        class="flex-0 mx-2 bg-cta px-2 text-white inline-block"
      >
        <span class="font-bold">{{ filter.label }}:</span>
        {{ filterValue(filter.value) }}
        <n-button
          variant="quaternary"
          @click="emitResetEvent(filter.key || filter.label)"
        >
          <n-icon icon="mdi-close-box" class="text-white icon-md" />
        </n-button>
      </span>
    </div>
    <div>
      <n-button variant="tertiary" @click="emitResetAll">
        {{ $t('views.common.search-filter.reset-filter') }}
      </n-button>
    </div>
  </div>
  <div v-else class="flex bg-blue-100 p-3 px-4 items-center">
    <div class="text-blue-600">
      {{ $t('views.common.search-filter.empty-text') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['reset', 'resetAll'],
  methods: {
    emitResetAll() {
      this.$emit('resetAll');
    },
    emitResetEvent(filterName) {
      this.$emit('reset', filterName);
    },
    filterValue(value) {
      return Array.isArray(value) ? value.join('; ') : value;
    },
  },
};
</script>
