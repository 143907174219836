<template>
  <div>
    <n-modal
      ref="modal"
      :is-open="isOpen"
      :title="
        $t(
          'views.university-capabilities-assign-capabilities.change-budget-modal.title',
          { capability: getCapabilityLabel(selectedCapability) }
        )
      "
      size="sm"
      display-close-button
      white
      auto-height
      @close="closeOverlay()"
    >
      <template #default>
        <n-input
          v-model="currentBudgetInPercent"
          type="number"
          :label="
            $t(
              'views.university-capabilities-assign-capabilities.change-budget-modal.budget-in-percent'
            )
          "
          :errors="errors"
          @update:model-value="validateBudget($event)"
        />
        <div class="text-sm mt-4 text-gray-700 flex align-middle">
          <n-icon icon="mdi-information-outline" />
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="ml-2"
            v-html="
              $t(
                'views.university-capabilities-assign-capabilities.change-budget-modal.remaining-budget'
              )
            "
          >
          </span>
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </template>

      <template #actions>
        <n-button variant="primary" :disabled="errors" @click="updateBudget">
          Budget ändern
        </n-button>
      </template>
    </n-modal>
    <n-table :data="getDataForCapabilityTable" :header-items="headerItems">
      <template #row="{ item }">
        <n-table-row :class="{ 'bg-[#c0c9e8]': item.hasChanged }" :item="item">
          <td class="px-3 py-6 font-bold">
            {{ getCapabilityLabel(item) }}
          </td>
          <td class="px-3 py-6">
            {{
              $t(
                `views.university-capabilities-assign-capabilities.descriptions.${item?.capability}`
              )
            }}
          </td>
          <td
            class="px-3 py-6"
            :class="{ 'bg-[#c0c9e8]': item.hasBudgetChanged }"
          >
            <template v-if="item.budgetInPercent !== null">
              <div class="flex">
                <div class="flex flex-col justify-center">
                  <p class="font-bold">{{ item.budgetInPercent }}%</p>
                  <template v-if="item.budgetSetManually">
                    {{
                      $t(
                        'views.university-capabilities-assign-capabilities.manually-set'
                      )
                    }}
                  </template>
                </div>
                <div class="flex flex-col justify-center ml-4">
                  <button
                    class="flex text-cta"
                    @click="openBudgetOverlay(item)"
                  >
                    <n-icon icon="mdi-pencil-outline" />
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <span class="text-gray-700 text-sm">Nicht verfügbar</span>
            </template>
          </td>
          <td class="py-14 flex items-center align-middle justify-center">
            <n-toggle
              :id="item?.capability"
              :model-value="item.isAssigned"
              class="capability-toggle"
              @update:model-value="handleToggleChange(item)"
            />
            <label
              class="pl-2 text-caption uppercase font-bold"
              :for="`active-${item?.capability}`"
            >
              <span v-if="item.isAssigned" class="text-cta">
                {{
                  $t(
                    'views.university-capabilities-assign-capabilities.assigned'
                  )
                }}
              </span>
              <!-- eslint-disable vue/no-v-html -->
              <span
                v-else
                class="text-gray-700"
                v-html="
                  $t(
                    'views.university-capabilities-assign-capabilities.not-assigned'
                  )
                "
              >
              </span>
              <!-- eslint-enable vue/no-v-html -->
            </label>
          </td>
        </n-table-row>
      </template>
    </n-table>
  </div>
</template>

<script>
import { t } from '@/plugins/translations';
import { createNamespacedHelpers } from 'vuex';
import { getTextByCapability } from '@/constants/picklists/capabilities';
import {
  NIcon,
  NInput,
  NTable,
  NTableRow,
  NToggle,
} from '@careerpartner/nitro';
const { mapGetters, mapActions } = createNamespacedHelpers('capabilities');

const { mapGetters: mapCapacityGetters } =
  createNamespacedHelpers('overallCapacity');

export default {
  name: 'AssignCapabilities',
  components: { NInput, NTable, NTableRow, NToggle, NIcon },
  data() {
    return {
      headerItems: [
        {
          class: 'w-1/3 px-3',
          get label() {
            return t('common.labels.university-capability');
          },
        },
        {
          class: 'w-1/3 px-3',
          get label() {
            return t('common.labels.description');
          },
        },
        {
          class: 'w-1/3 px-3',
          get label() {
            return t('common.labels.budget');
          },
        },
        {
          class: 'w-1/3 px-3',
          get label() {
            return t('common.labels.assigned');
          },
        },
      ],
      currentBudgetInPercent: null,
      selectedCapability: null,
      isOpen: false,
      errors: null,
    };
  },
  computed: {
    ...mapCapacityGetters(['teachingCapacity']),
    ...mapGetters([
      'getDataForCapabilityTable',
      'isCapabilityAssigned',
      'manuallyAssignableBudget',
    ]),
    calculatedBudget() {
      return this.selectedCapability.budgetSetManually
        ? this.selectedCapability.budgetInPercent +
            this.manuallyAssignableBudget
        : this.manuallyAssignableBudget;
    },
  },
  methods: {
    ...mapActions([
      'assignCapability',
      'unassignCapability',
      'updateBudgetForCapability',
      'setInitialCapabilities',
    ]),
    validateBudget(value) {
      if (value === 'e') {
        this.errors = 'Budget muss eine Zahl sein';
      }

      const maxBudget = this.calculatedBudget;

      try {
        parseInt(value);
      } catch (e) {
        this.errors = 'Budget muss eine Zahl sein';
      }

      if (value > maxBudget) {
        this.errors = `Budget darf nicht größer als ${maxBudget}% sein`;
      } else if (value > 100) {
        this.errors = 'Budget darf nicht größer als 100% sein';
      } else if (value < 0) {
        this.errors = 'Budget darf nicht kleiner als 0% sein';
      } else {
        this.errors = null;
      }
    },
    resetCapabilities() {
      this.setInitialCapabilities();
    },
    getCapabilityLabel(capability) {
      return getTextByCapability(capability);
    },
    handleToggleChange(item) {
      const key = item?.capability;
      if (this.isCapabilityAssigned(key)) {
        this.unassignCapability(key);
      } else {
        this.assignCapability(key);
      }
    },
    openBudgetOverlay(item) {
      this.currentBudgetInPercent = item.budgetInPercent;
      this.selectedCapability = item;

      this.isOpen = true;
    },
    closeOverlay() {
      this.isOpen = false;

      this.currentBudgetInPercent = null;
      this.selectedCapability = null;
    },
    updateBudget() {
      const updatedCapability = Object.freeze({
        ...this.selectedCapability,
        budgetInPercent: this.currentBudgetInPercent,
        budgetSetManually: true,
      });

      this.updateBudgetForCapability(updatedCapability);

      this.closeOverlay();
    },
  },
};
</script>

<style scoped>
.capability-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #e5e5e5;
}
</style>
