import { t } from '@/plugins/translations';
import {
  updateContactGuard,
  makeNestedRoutes,
} from '@/routes/make-nested-routes';
import AddressCreate from '@/views/profiles/addresses/AddressCreate';
import AddressUpdate from '@/views/profiles/addresses/AddressUpdate';
import { createContactFieldGuard } from '@/routes/guards';

export const updateAddresses = makeNestedRoutes(
  { path: 'addresses', name: 'addresses' },
  [
    {
      path: 'create',
      name: 'create',
      components: {
        modal: AddressCreate,
      },
      props: {
        default: true,
        modal: true,
      },
      meta: {
        showModal: true,
        get modalTitle() {
          return t('views.profiles.addresses.modal-title-add');
        },
      },
      beforeEnter: createContactFieldGuard,
    },
    {
      path: ':contactId/update',
      name: 'update',
      components: {
        modal: AddressUpdate,
      },
      props: {
        default: true,
        modal: true,
      },
      meta: {
        showModal: true,
        get modalTitle() {
          return t('views.profiles.addresses.modal-title-edit');
        },
      },
      beforeEnter: updateContactGuard,
    },
    {
      path: ':contactId/delete',
      name: 'delete',
      meta: {
        showDeletionModal: true,
        resourceName: 'addresses',
      },
      beforeEnter: updateContactGuard,
    },
  ]
);
