<template>
  <div
    class="flex flex-col py-16 justify-center items-center border border-dashed border-gray-600 rounded h-full min-h-[200px]"
  >
    <p class="text-xl font-bold uppercase text-blue-600">
      <slot name="subheading"></slot>
    </p>
    <p class="mt-4 font-xs text-blue-600">
      <slot name="description"></slot>
    </p>
    <slot name="actions"></slot>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
};
</script>
