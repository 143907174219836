<template>
  <deputation-section>
    <template #title>
      <slot name="heading" />
    </template>
    <template #content>
      <div class="flex flex-col items-center bg-white shadow">
        <div
          class="mx-auto my-16 flex max-w-sm flex-col items-center text-center"
        >
          <slot name="image" />
          <p class="text-xl mt-8 font-bold uppercase">
            <slot name="subheading" />
          </p>
          <p class="mt-4 font-xs">
            <slot name="description" />
          </p>
          <span class="mt-2">
            <router-link :to="routeTo" class="text-cta font-bold">
              <slot name="routerLinkText" />
            </router-link>
          </span>
        </div>
      </div>
    </template>
  </deputation-section>
</template>
<script>
import DeputationSection from '@/components/deputation/Common/DeputationSection.vue';

export default {
  components: {
    DeputationSection,
  },
  props: {
    routeTo: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['name', 'params'].every((key) => key in value);
      },
    },
  },
};
</script>
