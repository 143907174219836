<template>
  <n-modal
    :is-open="isModalOpen"
    :title="
      $t('views.course-capabilities.capabilities.course-search-modal.title')
    "
    class="relative"
    :large-deprecated="true"
    @close="closeModal"
  >
    <div class="mb-9">
      <n-input
        v-model="localSearchTerm"
        icon="magnify"
        :label="$t('views.course-capabilities.search-for-course')"
        @update:model-value="handleSearch"
      ></n-input>
    </div>
    <div class="mb-9">
      <SearchFilter
        :filters="appliedSearchFilters"
        @reset="resetSearchFilter"
        @reset-all="resetAllSearchFilters"
      />
    </div>
    <div class="mb-9">
      <course-capabilities-table
        :study-programs-filter="selectedStudyPrograms"
        :is-loading="isLoading"
        :data="courseSearchResult"
        :study-programs="studyPrograms"
        @update:study-programs-filter="updateStudyProgramsFilter"
        @selection-changed="selectionChanged"
      ></course-capabilities-table>
    </div>
    <div class="mb-9">
      <n-pagination
        v-model="localPagination"
        :limit-options="[]"
        @update:model-value="paginate"
      />
    </div>
    <div class="flex justify-end">
      <n-button
        variant="primary"
        :disabled="0 === newlySelectedCourseIds.length || isSaving"
        @click="save"
      >
        {{
          $t('views.course-capabilities.capabilities.course-search-modal.add', {
            count: newlySelectedCourseIds.length,
          })
        }}
      </n-button>
    </div>
  </n-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import SearchFilter from '@/components/common/SearchFilter.vue';
import CourseCapabilitiesTable from '@/components/course-capabilities/Capabilities/CourseCapabilitiesTable.vue';

const { mapActions, mapGetters } = createNamespacedHelpers('courseSearchModal');

const { mapGetters: studyProgramsGetter, mapActions: studyProgramsAction } =
  createNamespacedHelpers('studyPrograms');

export default {
  name: 'CourseSearchModal',
  components: {
    CourseCapabilitiesTable,
    SearchFilter,
  },
  emits: ['saved'],

  data() {
    return {
      isModalOpen: false,
      newlySelectedCourseIds: [],
      lastUpdatedPreferencesMetadata: null,
      localSearchTerm: null,
      localPagination: {
        limit: 4,
        offset: 0,
      },
    };
  },

  computed: {
    ...mapGetters([
      'courseSearchResult',
      'pagination',
      'searchTerm',
      'isSaving',
      'isLoading',
      'selectedStudyPrograms',
      'appliedSearchFilters',
    ]),
    ...studyProgramsGetter(['studyPrograms']),
  },
  watch: {
    pagination: {
      handler(currentPagination) {
        this.localPagination = currentPagination;
      },
      deep: true,
    },

    searchTerm: {
      handler(currentSearchTerm) {
        this.localSearchTerm = currentSearchTerm;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      triggerSearch: 'triggerSearchForCourses',
      saveCourseCapabilityPreferencesForProfile:
        'saveCourseCapabilityPreferencesForProfile',
      updateSearchTerm: 'updateSearchTerm',
      updateStudyPrograms: 'updateStudyProgramsFilter',
      updatePagination: 'updatePagination',
      resetSearchFilters: 'resetSearch',
      resetModal: 'resetModal',
    }),
    ...studyProgramsAction(['getStudyPrograms']),

    updateStudyProgramsFilter(selectedStudyPrograms) {
      this.updateStudyPrograms(selectedStudyPrograms);
    },

    async openModal(lastUpdatedPreferencesMetadata) {
      this.isModalOpen = true;
      this.lastUpdatedPreferencesMetadata = lastUpdatedPreferencesMetadata;
      this.newlySelectedCourseIds = [];

      await this.triggerSearch();
      await this.getStudyPrograms(this.$i18n.locale);
    },

    closeModal() {
      this.resetModal();

      this.isModalOpen = false;
    },

    paginate({ limit, offset }) {
      if (
        this.pagination.limit === limit &&
        this.pagination.offset === offset
      ) {
        return;
      }

      this.updatePagination({ limit, offset });
    },

    resetSearchFilter(key) {
      if (key === 'searchTerm') {
        this.updateSearchTerm('');
      } else if (key === 'studyProgramsFilter') {
        this.resetSearchFilters();
      }
    },

    async resetAllSearchFilters() {
      await this.resetModal();

      await this.triggerSearch();
    },

    handleSearch() {
      this.updateSearchTerm(this.localSearchTerm);
    },

    selectionChanged(selected) {
      this.newlySelectedCourseIds = selected;
    },

    async save() {
      await this.saveCourseCapabilityPreferencesForProfile({
        profileId: this.profileId,
        courseIds: this.newlySelectedCourseIds,
      });

      await this.triggerSearch();

      this.closeModal();
      this.$emit('saved');
    },
  },
};
</script>
