<template>
  <div>
    <n-select
      id="capability-preset"
      v-model="selectedPreset"
      :label="$t('views.university-capabilities-preset-selector.select-preset')"
      class="w-full"
      option-label="label"
      track-by="key"
      searchable
      mapped
      required
      :options="presetsWithLabel"
    />
    <selected-preset
      v-if="selectedPreset"
      :selected-preset="selectedPreset"
      :selected-capabilities="selectedCapabilities"
    />
  </div>
</template>
<script>
import { getTextByKey, PROFILE_PRESETS } from '@/constants/picklists';
import { UNIVERSITY_CAPABILITIES_BY_PRESETS } from '@/constants/universityCapabilitiesByPresets';
import SelectedPreset from '@/components/university-capabilities/SelectedPreset.vue';
import { createNamespacedHelpers } from 'vuex';
import { NSelect } from '@careerpartner/nitro';

const { mapActions } = createNamespacedHelpers('capabilities');

export default {
  name: 'PresetSelector',
  components: { SelectedPreset, NSelect },
  props: {
    presets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPreset: null,
    };
  },
  computed: {
    presetsWithLabel() {
      return this.presets.map((preset) => {
        return {
          key: preset.key,
          label: getTextByKey(PROFILE_PRESETS, preset.key),
        };
      });
    },
    selectedCapabilities() {
      if (!this.selectedPreset) {
        return [];
      }

      return UNIVERSITY_CAPABILITIES_BY_PRESETS.find(
        (preset) => preset.key === this.selectedPreset
      ).capabilities;
    },
  },
  watch: {
    selectedPreset: {
      deep: true,
      handler: function (value) {
        const assignedCapabilities =
          UNIVERSITY_CAPABILITIES_BY_PRESETS.find(
            (preset) => preset.key === value
          )?.capabilities || [];

        if (assignedCapabilities.length === 0) {
          this.setInitialCapabilities();

          return;
        }

        this.assignCapabilities(assignedCapabilities);
      },
    },
  },
  methods: {
    ...mapActions(['assignCapabilities', 'setInitialCapabilities']),
    resetSelectedPreset() {
      this.selectedPreset = null;
    },
  },
};
</script>
<style scoped>
.limit-max-width {
  max-width: 105px;
}
@media (min-width: 1068px) {
  .limit-max-width {
    max-width: 145px;
  }
}
</style>
