import en from '@/i18n/en.json';
import de from '@/i18n/de.json';
import { loadAndUpdateI18n } from '@epos/core-applets';
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  legacy: false,
  fallbackLocale: ['en', 'de'],
  messages: { en, de },
});

loadAndUpdateI18n(i18n, 'academicstaff');
loadAndUpdateI18n(i18n, 'nitro');

export const useTranslations = (app) => {
  app.use(i18n);
  return i18n;
};

export const t = i18n.global.t;
export const d = i18n.global.d;
export const locale = i18n.global.locale;
export const getLocaleMessage = i18n.global.getLocaleMessage;
