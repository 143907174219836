export const BRANDS = Object.freeze([
  {
    label: 'IU Internationale Hochschule',
    value: 'iu',
  },
  {
    label: 'London Institute of Banking & Finance',
    value: 'libf',
  },
  { value: 'ufred', label: 'University of Fredericton' },
  { value: 'hu', label: 'IU Health University' },
]);
