<template>
  <n-modal
    :is-open="isModalOpen"
    :title="$t('views.deputation.assignment-modal.title')"
    class="relative"
    @close="closeModal"
  >
    <validation-form
      ref="observer"
      v-slot="{ meta }"
      :key="validationFormKey"
      :initial-values="initialValues"
    >
      <div class="grid-flow-row grid grid-cols-12 gap-x-6 gap-y-7 px-5 pb-5">
        <div class="col-span-full">
          {{ $t('common.labels.type-of-assignment') }}*
        </div>

        <div class="col-span-full">
          <n-radio-group
            v-model="assignmentType"
            class="w-1/2"
            :options="ASSIGNMENT_TYPE_OPTIONS"
            name="assignmentType"
          >
            <template #default="{ options }">
              <div class="w-full flex justify-between items-center">
                <div v-for="(item, index) in options" :key="index">
                  <n-radio-button v-model="assignmentType" :value="item.key">
                    {{ item.text }}
                  </n-radio-button>
                </div>
              </div>
            </template>
          </n-radio-group>
        </div>

        <div class="col-span-full">
          <validation-provider
            v-slot="{ errors, field }"
            name="capability"
            :label="capabilityLabel"
            :rules="!hasInitialValue ? 'required' : ''"
          >
            <n-select
              id="academicStaffAssignmentModal-form__profile"
              v-model="capabilityModel"
              v-bind="field"
              :label="capabilityLabel"
              option-label="text"
              track-by="key"
              searchable
              mapped
              required
              :errors="errors"
              :options="capabilityOptions"
              :disabled="hasInitialValue"
            />
          </validation-provider>
        </div>

        <div v-if="assignmentSubCategories" class="col-span-full">
          <validation-provider
            v-slot="{ errors, field }"
            name="sub-categories"
            :label="subCategoryLabel"
            rules="required"
          >
            <n-select
              id="academicStaffAssignmentModal-form__profile"
              v-model="subCategoryModel"
              v-bind="field"
              :label="subCategoryLabel"
              option-label="text"
              track-by="key"
              searchable
              mapped
              required
              :errors="errors"
              :options="assignmentSubCategories"
            />
          </validation-provider>
        </div>

        <div class="col-span-full">
          <div>{{ $t('common.labels.period-of-time') }}*</div>
          <div class="flex flex-row mt-2">
            <n-radio-group
              v-model="dateRadioValue"
              class="w-1/2 mr-5"
              :options="ASSIGNMENT_DATE_TYPE.options"
              name="enddate"
              :disabled="isEndDateSectionDisabled"
            >
              <template #default="{ options }">
                <div class="w-full flex justify-between items-center">
                  <div v-for="(item, index) in options" :key="index">
                    <n-radio-button
                      v-model="dateRadioValue"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </n-radio-button>
                  </div>
                </div>
              </template>
            </n-radio-group>
          </div>
        </div>

        <div class="col-span-full">
          <validation-provider
            v-slot="{ errors, field }"
            name="onCampusPlanningTime"
            :label="$t('common.labels.on-campus-planning-time')"
          >
            <n-select
              id="academicStaffAssignmentModal-form__onCampusPlanningTime"
              v-model="planningPeriod"
              v-bind="field"
              name="assignmentStartDate"
              class="w-full"
              option-label="text"
              track-by="key"
              :errors="errors"
              :label="$t('common.labels.select-on-campus-planning-time')"
              :options="planningPeriodOptions"
              searchable
              mapped
            />
          </validation-provider>
        </div>

        <div class="col-span-full">
          <validation-provider
            v-slot="{ errors, field }"
            name="assignmentStartDate"
            :label="$t('common.labels.start-date')"
            rules="required"
          >
            <n-input
              id="academicStaffAssignmentModal-form__startdate"
              v-model="internalValue.assignmentStartDate"
              name="assignmentStartDate"
              :label="$t('common.labels.start-date')"
              type="date"
              v-bind="field"
              :errors="errors"
              required
              :disabled="isStartDateDisabled"
              :min="todayDate"
              :max="maxStartDate"
              @input="planningPeriod = null"
            />
          </validation-provider>
        </div>

        <div class="col-span-full">
          <validation-provider
            v-slot="{ errors, field }"
            ref="validationProviderEndDate"
            name="assignmentEndDate"
            :label="$t('common.labels.end-date')"
            :rules="{ required: !isEnddateDisabled }"
          >
            <n-input
              id="academicStaffAssignmentModal-form__enddate"
              v-bind="field"
              v-model="assignmentEndDate"
              name="assignmentEndDate"
              type="date"
              :errors="errors"
              :label="$t('common.labels.end-date')"
              class="w-full"
              :min="getEndDateMinimumDate"
              :required="!isEnddateDisabled"
              :disabled="isEnddateDisabled"
              @input="planningPeriod = null"
            />
          </validation-provider>
        </div>

        <div class="col-span-full">
          <validation-provider
            v-slot="{ errors, field }"
            name="weeklyScheduledHours"
            :label="$t('views.deputation.assignment-modal.weekly-effort')"
            :rules="{
              required: true,
              numeric: true,
              between: {
                min: 0,
                max: 40,
              },
            }"
          >
            <n-input
              id="academicStaffAssignmentModal-form__weeklyScheduledHours"
              v-model="internalValue.weeklyScheduledHours"
              name="weeklyScheduledHours"
              :label="$t('views.deputation.assignment-modal.weekly-effort')"
              type="number"
              v-bind="field"
              :errors="errors"
              required
            />
          </validation-provider>
        </div>

        <div class="col-span-full">
          <div class="text-caption text-gray-700">
            {{ $t('common.labels.mandatory-fields') }}
          </div>
        </div>
      </div>
      <div class="flex col-span-full place-content-end mt-7">
        <n-button
          class="absolute bottom-7 right-7"
          variant="primary"
          :disabled="!meta.valid || !hasChanged || isSaving"
          @click="save"
        >
          {{
            hasInitialValue ? $t('common.labels.save') : $t('common.labels.add')
          }}
        </n-button>
      </div>
    </validation-form>
  </n-modal>
</template>

<script>
import { t } from '@/plugins/translations';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {
  endDateInputDisabled,
  startDateInputDisabled,
} from '@/utils/date-input-disabled';
import { isNil, pick } from 'lodash';
import {
  CAPABILITIES,
  ASSIGNMENT_TYPE_OPTIONS,
  ASSIGNMENT_TYPES,
} from '@/constants/picklists';
import { mapActions, mapGetters } from 'vuex';
import { capitalize } from '@/utils/string';
import { NSelect } from '@careerpartner/nitro';
import { convertTranslatedString } from '@/utils/translated-string';
import { CAPABILITY_TRAIT } from '@/constants/picklists/capabilities';
import { isAfter } from 'date-fns';

const ASSIGNMENT_DATE_TYPE = Object.freeze({
  options: [
    {
      value: '0',
      get label() {
        return capitalize(t('common.labels.unlimited'));
      },
    },
    {
      value: '1',
      get label() {
        return capitalize(t('common.labels.limited'));
      },
    },
  ],
});

const unlimited = ASSIGNMENT_DATE_TYPE.options[0].value;
const limited = ASSIGNMENT_DATE_TYPE.options[1].value;

export default {
  name: 'AssignmentModal',
  components: { NSelect },
  props: {
    isSaving: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['save'],

  data() {
    return {
      ASSIGNMENT_DATE_TYPE,
      ASSIGNMENT_TYPES,
      ASSIGNMENT_TYPE_OPTIONS,
      isModalOpen: false,
      hasInitialValue: false,
      limited,
      unlimited,
      dateRadioValue: unlimited,
      isEnddateDisabled: true,
      initialValue: {},
      internalValue: {},
      todayDate: new Date().toISOString().split('T')[0],
      maxStartDate: new Date(`${new Date().getFullYear() + 5}-12-31`)
        .toISOString()
        .split('T')[0],
      lastEndDateValue: null,
      initialValues: {
        capability: null,
        assignmentStartDate: new Date().toISOString().slice(0, 10),
        assignmentEndDate: null,
        weeklyScheduledHours: 0,
      },
      validationFormKey: new Date().getTime(),
      assignmentType: ASSIGNMENT_TYPES.CURRICULAR,
      subCategory: null,
      selectedPlanningPeriod: '',
    };
  },

  computed: {
    ...mapGetters('capabilities', ['assignedCapabilities']),
    ...mapGetters('assignments', ['assignmentCategories']),
    ...mapGetters('planningPeriods', ['planningPeriods']),

    hasChanged() {
      return !isEqual(this.internalValue, this.initialValue);
    },
    isEndDateSectionDisabled() {
      return (
        this.hasInitialValue &&
        this.initialValue.assignmentEndDate &&
        endDateInputDisabled(new Date(this.initialValue.assignmentEndDate))
      );
    },
    isStartDateDisabled() {
      return (
        this.hasInitialValue &&
        !isNil(this.initialValue.assignmentStartDate) &&
        startDateInputDisabled(
          new Date(this.initialValue.assignmentStartDate).getTime()
        )
      );
    },
    getEndDateMinimumDate() {
      return this.internalValue.assignmentStartDate
        ? new Date(this.internalValue.assignmentStartDate)
            .toISOString()
            .split('T')[0]
        : this.todayDate;
    },
    possibleCapabilities() {
      return CAPABILITIES.filter(
        ({ key }) =>
          this.assignedCapabilities.some(
            ({ capability }) => capability === key
          ) && key !== CAPABILITY_TRAIT.EXTRA
      );
    },
    possibleAssignmentCategories() {
      return this.assignmentCategories.map((category) => ({
        key: category.id,
        get text() {
          return convertTranslatedString(category.title);
        },
      }));
    },

    capabilityLabel() {
      return this.$t('common.labels.university-capability', {
        type: capitalize(this.assignmentType),
      });
    },
    capabilityModel: {
      get() {
        return this.assignmentType === ASSIGNMENT_TYPES.CURRICULAR
          ? this.internalValue?.capability
          : this.internalValue.assignmentCategoryId;
      },
      set(value) {
        if (this.assignmentType === ASSIGNMENT_TYPES.CURRICULAR) {
          this.internalValue.capability = value;
        } else {
          this.internalValue.assignmentCategoryId = value;
        }
      },
    },
    capabilityOptions() {
      return this.assignmentType === ASSIGNMENT_TYPES.CURRICULAR
        ? this.possibleCapabilities
        : this.possibleAssignmentCategories;
    },
    assignmentSubCategories() {
      const catId = this.internalValue.assignmentCategoryId;
      if (!catId) {
        return null;
      }
      const selectedExtraTask = this.assignmentCategories.find(
        (cat) => cat.id === catId
      );
      if (!selectedExtraTask?.assignmentSubcategories?.length) {
        return null;
      }

      return selectedExtraTask.assignmentSubcategories.map((category) => ({
        key: category.id,
        get text() {
          return convertTranslatedString(category.title);
        },
        effort: category.value,
      }));
    },
    subCategoryLabel() {
      return this.$t('common.labels.assignment-sub-category');
    },
    subCategoryModel: {
      get() {
        return this.internalValue.subCategory;
      },
      set(id) {
        if (id) {
          const { effort } =
            this.assignmentSubCategories?.find((sub) => sub.key === id) || {};

          if (effort) {
            this.internalValue.weeklyScheduledHours = effort;
          } else {
            this.internalValue.weeklyScheduledHours = 0;
          }
        }
        this.internalValue.subCategory = id;
      },
    },
    planningPeriodOptions() {
      return this.planningPeriods
        ?.filter((pp) => isAfter(pp.endDate, new Date()))
        ?.map((pp) => ({
          key: pp.id,
          get text() {
            return pp.name;
          },
        }));
    },
    planningPeriod: {
      get() {
        return this.selectedPlanningPeriod;
      },
      set(id) {
        const { startDate, endDate } =
          this.planningPeriods.find((pp) => pp.id === id) || {};

        if (startDate) {
          this.internalValue.assignmentStartDate = startDate.slice(0, 10);
        }

        if (endDate && !this.isEnddateDisabled) {
          this.assignmentEndDate = endDate.slice(0, 10);
        }

        this.selectedPlanningPeriod = id;
      },
    },
    assignmentEndDate: {
      get() {
        return this.internalValue.assignmentEndDate;
      },
      set(value) {
        this.internalValue.assignmentEndDate = value;
        if (this.$refs?.validationProviderEndDate) {
          this.$refs.validationProviderEndDate.value = value;
        }
      },
    },
  },
  watch: {
    dateRadioValue(val) {
      switch (val) {
        case unlimited:
          this.isEnddateDisabled = true;

          if (this.hasInitialValue) {
            this.lastEndDateValue = this.initialValue.assignmentEndDate;
          }

          this.assignmentEndDate = null;

          break;
        case limited:
          this.assignmentEndDate = this.lastEndDateValue;
          this.isEnddateDisabled = false;

          break;
      }
    },
    assignmentType(value) {
      if (value === ASSIGNMENT_TYPES.CURRICULAR) {
        this.internalValue.assignmentCategoryId = null;
        this.subCategoryModel = null;
        this.internalValue.capability = null;
      } else {
        this.internalValue.capability = CAPABILITY_TRAIT.EXTRA;
      }
    },
    internalValue() {
      this.validationFormKey = new Date().getTime();
    },
  },

  methods: {
    ...mapActions('assignments', ['loadAssignmentCategories']),
    ...mapActions('planningPeriods', ['loadPlanningPeriods']),
    openModal(val) {
      if (!this.assignmentCategories.length) {
        this.loadAssignmentCategories();
      }

      if (!this.planningPeriods.length) {
        this.loadPlanningPeriods();
      }

      this.internalValue = {
        id: null,
        profileId: null,
        capability: null,
        assignmentCategoryId: null,
        assignmentStartDate: new Date().toISOString().slice(0, 10),
        assignmentEndDate: null,
        weeklyScheduledHours: 0,
      };

      this.assignmentType = ASSIGNMENT_TYPES.CURRICULAR;
      this.selectedPlanningPeriod = '';

      this.hasInitialValue =
        !isNil(val) && Object.prototype.hasOwnProperty.call(val, 'id');

      Object.assign(
        this.internalValue,
        pick(val, Object.keys(this.internalValue))
      );

      this.dateRadioValue =
        null === this.assignmentEndDate ? unlimited : limited;

      this.initialValue = cloneDeep(this.internalValue);

      if (this.hasInitialValue) {
        this.initialValues = Object.assign(
          this.initialValues,
          pick(this.initialValue, Object.keys(this.initialValues))
        );

        this.lastEndDateValue = this.initialValue.assignmentEndDate;
      }

      this.isModalOpen = true;
    },

    closeModal() {
      this.lastEndDateValue = null;
      this.isModalOpen = false;
    },

    save() {
      this.$emit('save', {
        ...this.internalValue,
        weeklyScheduledHours: parseInt(this.internalValue.weeklyScheduledHours),
        assignmentType: this.assignmentType,
      });
    },
    validateEndDate() {
      if (this.isEnddateDisabled || this.isEndDateSectionDisabled) {
        return true;
      } else {
        return !!this.assignmentEndDate;
      }
    },
  },
};
</script>
