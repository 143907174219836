export default {
  namespaced: true,
  state: {
    message: '',
    error: false,
  },
  actions: {
    update({ commit }, payload) {
      commit('update', payload);
      setTimeout(() => commit('update', { message: '', error: false }), 1500);
    },
  },
  mutations: {
    update(state, payload) {
      state.message = payload.message;
      state.error = payload.error;
    },
  },
};
