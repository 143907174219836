export default {
  computed: {
    nameModel: {
      get() {
        return this.name;
      },
      set(value) {
        this.$emit('update:name', value);
      },
    },
    emailModel: {
      get() {
        return this.email;
      },
      set(value) {
        this.$emit('update:email', value);
      },
    },
    teachingLevelsModel: {
      get() {
        return this.teachingLevels;
      },
      set(value) {
        this.$emit('update:teachingLevels', value);
      },
    },
    employmentTypeModel: {
      get() {
        return this.employmentType;
      },
      set(value) {
        this.$emit('update:employmentType', value);
      },
    },
    brandsModel: {
      get() {
        return this.brands;
      },
      set(value) {
        this.$emit('update:brands', value);
      },
    },
    unitsModel: {
      get() {
        return this.units;
      },
      set(value) {
        this.$emit('update:units', value);
      },
    },
    planningStatusModel: {
      get() {
        return this.planningStatus;
      },
      set(value) {
        this.$emit('update:planningStatus', value);
      },
    },
    preferenceModel: {
      get() {
        return this.preferences;
      },
      set(value) {
        this.$emit('update:preferences', value);
      },
    },
    paginationModel: {
      get() {
        return this.pagination;
      },
      set(value) {
        this.$emit('update:pagination', value);
      },
    },
    filters() {
      const filters = [];
      if (this.nameModel) {
        filters.push({
          value: this.nameModel,
          key: 'nameModel',
          label: this.$t('common.labels.name'),
        });
      }
      if (this.teachingLevelsModel?.length) {
        filters.push({
          value: this.teachingLevelsModel,
          key: 'teachingLevelsModel',
          label: this.$t('common.labels.teaching-level'),
        });
      }
      if (this.employmentTypeModel?.length) {
        filters.push({
          value: this.employmentTypeModel,
          valueTranslationPath: 'common.enums.employment-type',
          key: 'employmentTypeModel',
          label: this.$t('common.labels.employment-type'),
        });
      }
      if (this.brandsModel?.length) {
        filters.push({
          value: this.brandsModel,
          key: 'brandsModel',
          label: this.$t('common.labels.brand'),
        });
      }
      if (this.unitsModel?.length) {
        filters.push({
          value: this.unitsModel,
          key: 'unitsModel',
          label: this.$t('common.labels.units'),
        });
      }
      if (this.planningStatusModel?.length) {
        filters.push({
          value: this.planningStatusModel,
          valueTranslationPath: 'common.enums.planning-status',
          key: 'planningStatusModel',
          label: this.$t('common.labels.planning-status'),
        });
      }
      if (this.preferenceModel?.length) {
        filters.push({
          value: this.preferenceModel,
          valueTranslationPath: 'common.enums.preference-state',
          key: 'preferenceModel',
          label: this.$t('common.labels.preference-state'),
        });
      }
      return filters;
    },
  },
};
