<template>
  <div class="flex py-9">
    <n-button
      variant="quaternary"
      :disabled="previousYearDisabled"
      @click="previousYear"
    >
      <n-icon icon="mdi-chevron-left" />
    </n-button>
    <span class="font-bold text-xl mx-4"> {{ selectedYear }}</span>
    <n-button
      variant="quaternary"
      :disabled="nextYearDisabled"
      @click="nextYear"
    >
      <n-icon icon="mdi-chevron-right" />
    </n-button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'YearSelector',
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
  },
  emits: ['onYearChange'],
  computed: {
    ...mapState('assignments', ['selectedYear']),

    nextYearDisabled() {
      return (
        this.loading || this.selectedYear + 1 > new Date().getFullYear() + 5
      );
    },
    previousYearDisabled() {
      return this.loading || this.selectedYear - 1 < 2000;
    },
  },
  methods: {
    ...mapActions('deputation', {
      setDeputationYear: 'setSelectedYear',
    }),
    ...mapActions('assignments', {
      setAssignmentYear: 'setSelectedYear',
    }),
    setSelectedYear(year) {
      this.setDeputationYear(year);
      this.setAssignmentYear(year);
    },
    nextYear() {
      this.setSelectedYear(this.selectedYear + 1);

      this.$emit('onYearChange', this.selectedYear);
    },
    previousYear() {
      this.setSelectedYear(this.selectedYear - 1);

      this.$emit('onYearChange', this.selectedYear);
    },
  },
};
</script>
