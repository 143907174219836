<template>
  <validation-form v-slot="{ meta }" :initial-values="initialValues">
    <div class="grid grid-cols-12 gap-x-6 gap-y-7 px-4">
      <div class="col-span-6">
        <validation-provider
          v-slot="{ errors, field }"
          name="Art der Telefonnummer"
          rules="required"
        >
          <n-select
            id="phone-form__type"
            v-model="phone.type"
            :errors="errors"
            v-bind="field"
            :options="phoneTypes"
            :label="$t('views.profiles.phones.phones-form.phone-type')"
            option-label="text"
            track-by="key"
            mapped
            data-test="phone-form-type"
            required
          />
        </validation-provider>
      </div>
      <div class="col-span-full">
        <validation-provider
          v-slot="{ errors, field }"
          name="Telefonnummer"
          rules="required|phone"
        >
          <n-input
            id="phone-form__phone"
            v-model.trim="phone.phonenumber"
            :errors="errors"
            v-bind="field"
            :label="$t('views.profiles.phones.phones-form.phone-number')"
            data-test="phone-form-number"
            required
          />
        </validation-provider>
      </div>
      <div class="col-span-full">
        <div class="text-caption italic text-gray-700">
          * {{ $t('common.labels.required') }}
        </div>
      </div>
      <div v-if="phone.updatedAt" class="col-span-full">
        <p class="italic text-gray-700">
          Zuletzt geändert am
          {{ formatTimeStampToLocale(phone.updatedAt) }} von User
          {{ phone.updatedBy }}
        </p>
      </div>
      <div class="col-span-full flex h-9">
        <n-button
          :disabled="!meta.valid || !meta.dirty || !hasChanged"
          variant="primary"
          class="mt-18 ml-auto"
          data-test="phone-form-submit"
          @click="submit"
        >
          <n-icon icon="mdi-content-save-outline" class="mr-2" />
          {{ $t('common.labels.save') }}
        </n-button>
      </div>
    </div>
  </validation-form>
</template>

<script>
import _ from '@/plugins/lodash';
import { PHONE_TYPES } from '@/constants/picklists';
import { formatTimeStampToLocale } from '@/utils/formatters';

export default {
  name: 'PhoneForm',
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'submit'],
  data() {
    return {
      phone: {
        type: '',
        phonenumber: '',
      },
    };
  },
  computed: {
    phoneTypes() {
      return PHONE_TYPES;
    },
    hasChanged() {
      return !_.isEqual(this.modelValue, this.phone);
    },
    initialValues() {
      if (!this.modelValue || Object.keys(this.modelValue).length <= 1) {
        return {
          'Art der Telefonnummer': '',
          Telefonnummer: '',
        };
      }
      return {
        'Art der Telefonnummer': this.modelValue.type,
        Telefonnummer: this.modelValue.phonenumber,
      };
    },
  },
  mounted() {
    this.phone = { ...this.modelValue };
  },
  methods: {
    formatTimeStampToLocale,
    submit() {
      this.$emit('submit', { ...this.modelValue, ...this.phone });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
