<template>
  <div>
    <n-card class="px-4 py-4 h-full elevated">
      <template #content>
        <div class="flex flex-col">
          <div class="flex justify-start">
            <span
              class="rounded-md text-2xl font-bold p-2 mt-4 uppercase"
              :class="getCorrespondingUnitColor(unit)"
            >
              {{ mapSingleUnitName(unit) }}
            </span>
          </div>
          <div v-if="campuses" class="flex align-middle mt-4">
            <n-icon
              class="icon-2xl mr-2 text-blue-500"
              icon="map-marker-outline"
            />
            <span class="text-base text-blue-500 h-[160px] overflow-y-auto">
              {{ campuses.join(', ') }}
            </span>
          </div>
          <div v-else class="flex align-middle mt-4 h-[160px] overflow-y-auto">
            <n-icon class="icon-2xl mr-2 text-blue-500" icon="laptop" />
            <span class="text-base text-blue-500">Online</span>
          </div>
          <div class="mt-6 flex justify-end align-top gap-6">
            <n-button
              variant="quaternary"
              class="text-red-500"
              @click="$emit('openDeleteConfirmation', unit)"
            >
              {{ $t('views.location.location-grid-item.delete-unit') }}
            </n-button>
          </div>
        </div>
      </template>
    </n-card>
  </div>
</template>

<script>
import { mapSingleUnitName } from '@/utils/units-name-mapper';
import { getCorrespondingUnitColor } from '@/utils/unit-color.util';

export default {
  name: 'LocationGridItem',
  props: {
    unit: {
      type: String,
      required: true,
    },
    campuses: {
      type: [Array, null],
      required: true,
    },
  },
  emits: ['openDeleteConfirmation'],
  methods: {
    getCorrespondingUnitColor,
    mapSingleUnitName,
  },
};
</script>
