<template>
  <view-wrapper>
    <ErrorBanner />
    <year-selector :loading="isLoading" />
    <DeputationNotAvailable v-if="!isDeputationAvailable" />
    <template v-else>
      <DeputationSummarySection />
      <DeputationDetailSection class="mt-16" />
    </template>
    <ManageAssignmentsSection />
  </view-wrapper>
</template>

<script>
import ViewWrapper from '@/components/common/ViewWrapper.vue';
import ErrorBanner from '@/components/common/ErrorBanner.vue';
import YearSelector from '@/components/deputation/Assignments/YearSelector.vue';
import ManageAssignmentsSection from '@/components/deputation/Assignments/ManageAssignmentsSection.vue';
import DeputationSummarySection from '@/components/deputation/Deputation/Summary/DeputationSummarySection.vue';

import { mapGetters } from 'vuex';
import DeputationDetailSection from '@/components/deputation/Deputation/Detail/DeputationDetailSection.vue';
import DeputationNotAvailable from '@/components/deputation/Common/DeputationUnavailable.vue';

export default {
  name: 'DeputationOverview',
  components: {
    DeputationNotAvailable,
    DeputationDetailSection,
    DeputationSummarySection,
    ManageAssignmentsSection,
    YearSelector,
    ErrorBanner,
    ViewWrapper,
  },
  data() {
    return {
      profileId: null,
    };
  },
  computed: {
    ...mapGetters('assignments', ['isLoading']),
    ...mapGetters('deputation', ['isDeputationAvailable']),
  },
};
</script>
