<template>
  <n-banner
    v-if="error || lastError"
    variant="error"
    :style="`top: ${styleTop}px`"
    class="sticky z-[1001]"
    @close="dismissError"
  >
    <template #message>
      {{ message }}
    </template>
    <template #actions>
      <n-button @click="() => reportError(error)">
        <span class="uppercase text-white hover:underline">Melden</span>
      </n-button>
      <n-button @click="reloadPage">
        <span class="uppercase text-white hover:underline"> Neu laden</span>
      </n-button>
      <n-button v-if="canDismiss" @click="clearErrors">
        <span class="text-white">
          <n-icon icon="close" />
        </span>
      </n-button>
    </template>
  </n-banner>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('errors');

export default {
  name: 'ErrorBanner',
  props: {
    error: {
      type: Object,
      default: null,
    },
    canDismiss: {
      type: Boolean,
      default: true,
    },
    styleTop: {
      type: String,
      default: '50',
    },
  },
  computed: {
    ...mapGetters(['hasErrors', 'totalErrors', 'lastError']),
    errorData() {
      return this.error || this.lastError;
    },
    message() {
      const errorCount = this.totalErrors ? `(${this.totalErrors}) ` : '';
      return `${errorCount}${
        this.errorData.error ? `${this.errorData.error}: ` : ''
      } ${this.errorData.message}`;
    },
  },
  methods: {
    ...mapActions(['dismissError'], ['reportError']),
    reloadPage() {
      location.reload();
    },
  },
};
</script>
