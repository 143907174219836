<template>
  <div class="w-full grid grid-cols-12 items-center gap-x-4 p-3 mb-8">
    <div class="col-span-3">
      <div class="text-blue-400 text-xs">
        {{
          $t(
            'views.course-capabilities.capabilities.capabilities-expandable-table-row.unit'
          )
        }}
      </div>
      <div class="text-blue-600 text-base">
        {{ courseUnit(item).length ? courseUnit(item).join(', ') : '-' }}
      </div>
    </div>
    <div class="col-span-3">
      <div class="text-blue-400 text-xs">
        {{
          $t(
            'views.course-capabilities.capabilities.capabilities-expandable-table-row.topic'
          )
        }}
      </div>
      <n-tooltip :content="item.course.subject">
        <div class="text-blue-600 text-base">
          {{ truncateText(item.course.subject) ?? '-' }}
        </div>
      </n-tooltip>
    </div>
    <div class="col-span-3">
      <div class="text-blue-400 text-xs">
        {{
          $t(
            'views.course-capabilities.capabilities.capabilities-expandable-table-row.campus'
          )
        }}
      </div>
      <n-tooltip :content="courseCampuses(item).join(', ')">
        <div class="text-blue-600 text-base">
          {{
            courseCampuses(item).length
              ? truncateText(courseCampuses(item).join(', '))
              : '-'
          }}
        </div>
      </n-tooltip>
    </div>
    <div class="col-span-3">
      <div class="text-blue-400 text-xs">
        {{ $t('common.labels.study-program') }}
      </div>
      <scrollable-tooltip :content="item.studyPrograms?.join(', ') || '-'">
        <div class="text-blue-600 text-base">
          {{
            item.studyPrograms?.length
              ? truncateText(item.studyPrograms.join(', '))
              : '-'
          }}
        </div>
      </scrollable-tooltip>
    </div>
  </div>
</template>

<script>
import { mapMultipleUnitsNames } from '@/utils/units-name-mapper';
import { truncateText } from '@/utils/text-utils';
import ScrollableTooltip from '@/components/common/ScrollableTooltip.vue';

export default {
  name: 'CapabilitiesExpandableTableRow',
  components: { ScrollableTooltip },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    courseCampuses(item) {
      return (
        item?.course?.locations?.reduce((accumulator, currentValue) => {
          if (currentValue.campus) {
            accumulator.push(currentValue.campus);
          }
          return accumulator.sort();
        }, []) || []
      );
    },
    courseUnit(item) {
      if (!item?.course?.locations?.length) {
        return [];
      }

      return mapMultipleUnitsNames([
        ...new Set(item.course.locations.map((item) => item.unit)),
      ]);
    },
    truncateText,
  },
};
</script>
