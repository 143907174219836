import { authMiddleware } from '@epos/core-applets';

const ROLES_KEY = 'https://iu.org/roles';

export default {
  namespaced: true,
  state: {
    family_name: '',
    given_name: '',
    email: '',
    roles: [],
  },
  actions: {
    setCurrentUser({ commit }) {
      commit('saveCurrentUser', authMiddleware.user);
    },
  },
  mutations: {
    saveCurrentUser(state, user) {
      state.family_name = user.family_name;
      state.given_name = user.given_name;
      state.email = user.email;
      state.roles = user[ROLES_KEY];
    },
  },
};
