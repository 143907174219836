<template>
  <assignment-unavailable v-if="!isDeputationAvailable" class="mt-16" />
  <deputation-section v-else class="mt-16">
    <template #title>{{
      $t('views.deputation.manage-assignments-section.title')
    }}</template>
    <template #actions>
      <n-tooltip :content="manageAssignmentButtonTooltipText">
        <n-button
          :disabled="isLoading || !hasPermissionToManageAssignments"
          class="text-base"
          variant="quaternary"
          @click="openAssignmentModal"
        >
          <n-icon class="mr-1" icon="mdi-plus" />
          {{ $t('views.deputation.manage-assignments-section.add-assignment') }}
        </n-button>
      </n-tooltip>
    </template>
    <template #content>
      <search-filter
        :filters="searchFilters"
        @reset="resetSearchFilters"
        @reset-all="resetFilters"
      />
      <n-table
        :header-items="headerItems"
        :data="assignments"
        :is-loading="isLoading"
      >
        <template #filter-capability="{ close }">
          <n-table-filter-radio
            v-model="filters.capabilityType"
            :options="capabilitiesOptions"
            @close="close"
          />
        </template>
        <template #filter-type="{ close }">
          <n-table-filter-radio
            v-model="filters.type"
            :options="typeOptions"
            @close="close"
          />
        </template>
        <template #th-duration="{ item }">
          <span class="mr-2">{{ item.label }}</span>
          <column-sort-button
            column-name="duration"
            :value="sortParams.duration"
            @on-new-sort="onNewSort"
          />
        </template>
        <template #filter-weeklyScheduledHours="{ close }">
          <n-table-filter-radio
            v-model="filters.duration"
            :options="scheduledWeeklyHoursOptions"
            @close="close"
          />
        </template>
        <template #filter-state="{ close }">
          <n-table-filter-radio
            v-model="filters.status"
            :options="STATUS_OPTIONS"
            @close="close"
          />
        </template>
        <template #row="{ item }">
          <n-table-row :item="item">
            <assignment-table-row
              :assignment="item"
              @delete-assignment="openDeleteConfirmation"
            />
          </n-table-row>
        </template>
        <template #no-results>
          <n-empty-results
            v-if="Object.keys(filters).length > 0"
            :text="
              $t(
                'views.deputation.manage-assignments-section.no-assignments-found'
              )
            "
            @reset="resetFilters"
          />
          <n-empty-data
            v-else
            :text="
              $t(
                'views.deputation.manage-assignments-section.no-assignments-heading'
              )
            "
            :hint="
              $t(
                'views.deputation.manage-assignments-section.no-assignments-description'
              )
            "
          />
        </template>
      </n-table>
      <n-pagination
        v-if="pagination.totalCount > pagination.limit"
        :model-value="pagination"
        @update:model-value="onPaginationChanged"
      />
    </template>
  </deputation-section>
  <assignment-modal
    ref="assignmentModal"
    :is-saving="isLoading"
    @save="saveAssignment"
  />
  <delete-assignment-confirmation-modal
    ref="deleteConfirmationModal"
    @on-delete-confirmed="reallyDeleteAssignment"
  />
</template>
<script>
import AssignmentModal from '@/components/deputation/Assignments/AssignmentModal.vue';
import DeputationSection from '@/components/deputation/Common/DeputationSection.vue';
import { IMAGE_URLS } from '@/constants/images';
import { mapActions, mapGetters } from 'vuex';
import AssignmentUnavailable from '@/components/deputation/Assignments/AssignmentUnavailable.vue';
import { hasPermissionFor } from '@/utils/permissionUtil';
import AssignmentTableRow from '@/components/deputation/Assignments/AssignmentTableRow.vue';
import { CAPABILITIES, getTextByKey } from '@/constants/picklists';
import { AssignmentLifeCycle } from '@/constants/assignment-status';
import { t } from '@/plugins';
import SearchFilter from '@/components/common/SearchFilter.vue';
import ColumnSortButton from '@/components/common/ColumnSortButton.vue';
import { sortOrder } from '@/constants/sortOrder';
import DeleteAssignmentConfirmationModal from '@/components/deputation/Assignments/DeleteAssignmentConfirmationModal.vue';

const STATUS_OPTIONS = Object.freeze([
  { label: 'Created', value: AssignmentLifeCycle.CREATED },
  { label: 'Accepted', value: AssignmentLifeCycle.ACCEPTED },
  { label: 'Started', value: AssignmentLifeCycle.STARTED },
  { label: 'Ended', value: AssignmentLifeCycle.ENDED },
  { label: 'Refused', value: AssignmentLifeCycle.REFUSED },
]);

export default {
  name: 'ManageAssignmentsSection',
  components: {
    DeleteAssignmentConfirmationModal,
    ColumnSortButton,
    SearchFilter,
    AssignmentTableRow,
    AssignmentUnavailable,
    AssignmentModal,
    DeputationSection,
  },
  data() {
    return {
      STATUS_OPTIONS,
      IMAGE_URLS,
      profileId: this.$route.params.profileId,
      searchFilters: [],
    };
  },

  computed: {
    ...mapGetters('assignments', [
      'assignments',
      'isLoading',
      'pagination',
      'filters',
      'sortParams',
      'selectedYear',
    ]),
    headerItems() {
      return [
        {
          class: 'w-3/12 px-3',
          label: t(
            'views.deputation.manage-assignments-section.table-headers.assignment'
          ),
          key: 'capability',
        },
        {
          class: 'w-2/12 px-3',
          label: t(
            'views.deputation.manage-assignments-section.table-headers.type'
          ),
          key: 'type',
        },
        {
          class: 'w-2/12 px-3',
          label: t(
            'views.deputation.manage-assignments-section.table-headers.period-of-time'
          ),
          key: 'duration',
        },
        {
          class: 'w-2/12 px-3',
          label: t(
            'views.deputation.manage-assignments-section.table-headers.duration'
          ),
          key: 'weeklyScheduledHours',
        },
        {
          class: 'w-2/12 px-3',
          label: t(
            'views.deputation.manage-assignments-section.table-headers.status'
          ),
          key: 'state',
        },
        {
          class: 'w-1/12 px-3',
          label: t(
            'views.deputation.manage-assignments-section.table-headers.actions'
          ),
          key: 'action',
        },
      ];
    },
    ...mapGetters('deputation', ['isDeputationAvailable']),
    hasPermissionToManageAssignments() {
      return hasPermissionFor('academic-staff', ['u']);
    },
    manageAssignmentButtonTooltipText() {
      return !this.hasPermissionToManageAssignments
        ? 'Sie haben keine Berechtigung, Lehrendenzuordnungen zu verwalten.'
        : '';
    },
    capabilitiesOptions() {
      return CAPABILITIES.map((capability) => ({
        label: capability.text,
        value: capability.key,
      }));
    },
    typeOptions() {
      return [
        {
          label: t(
            'views.deputation.manage-assignments-section.filter-options.assignment-type.curricular'
          ),
          value: 'CURRICULAR',
        },
        {
          label: t(
            'views.deputation.manage-assignments-section.filter-options.assignment-type.extracurricular'
          ),
          value: 'EXTRACURRICULAR',
        },
      ];
    },
    scheduledWeeklyHoursOptions() {
      return [...Array(9).keys()].map((hour) => ({
        label: t(
          'views.deputation.manage-assignments-section.filter-options.scheduled_weekly_hours_options',
          hour
        ),
        value: hour,
      }));
    },
  },
  watch: {
    selectedYear: {
      immediate: true,
      async handler() {
        await this.fetchAssignments();
      },
    },
    filters: {
      async handler(filter) {
        this.searchFilters = Object.keys(filter).map((key) => {
          if (key === 'capabilityType') {
            return {
              key,
              get value() {
                return getTextByKey(CAPABILITIES, filter[key]);
              },
              get label() {
                return t(
                  'views.deputation.manage-assignments-section.table-headers.assignment'
                );
              },
            };
          }
          if (key === 'type') {
            return {
              key,
              value: filter[key],
              get label() {
                return t(
                  'views.deputation.manage-assignments-section.table-headers.type'
                );
              },
            };
          }

          if (key === 'duration') {
            return {
              key,
              get value() {
                return t(
                  'views.deputation.manage-assignments-section.filter-options.scheduled_weekly_hours_options',
                  filter[key]
                );
              },
              get label() {
                return t(
                  'views.deputation.manage-assignments-section.table-headers.duration'
                );
              },
            };
          }

          if (key === 'status') {
            return {
              key,
              value: filter[key],
              get label() {
                return t(
                  'views.deputation.manage-assignments-section.table-headers.status'
                );
              },
            };
          }
        });

        await this.onPaginationChanged({
          offset: 0,
          limit: this.pagination.limit,
        });
      },
      deep: true,
    },
    sortParams: {
      async handler() {
        await this.fetchAssignments();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('assignments', [
      'loadAssignments',
      'createAssignment',
      'resetFilters',
      'updatePagination',
      'deleteAssignment',
    ]),
    openAssignmentModal() {
      this.$refs.assignmentModal.openModal({ profileId: this.profileId });
    },
    async fetchAssignments() {
      await this.loadAssignments({
        profileId: this.profileId,
        year: this.selectedYear,
      });
    },
    async saveAssignment(assignment) {
      await this.createAssignment(assignment);

      this.$refs.assignmentModal.closeModal();
    },
    async onPaginationChanged(pagination) {
      this.updatePagination({
        offset: pagination.offset,
        limit: pagination.limit,
      });

      await this.fetchAssignments();
    },
    resetSearchFilters(key) {
      if (key) {
        delete this.filters[key];
      }
    },
    onNewSort(sortPayload) {
      if (sortPayload.order === sortOrder.NONE) {
        delete this.sortParams[sortPayload.key];
        return;
      }

      this.sortParams[sortPayload.key] = sortPayload.order;
    },
    openDeleteConfirmation(assignmentId) {
      this.$refs.deleteConfirmationModal.openModal(assignmentId);
    },
    async reallyDeleteAssignment(assignmentId) {
      await this.deleteAssignment({
        profileId: this.profileId,
        assignmentId: assignmentId,
      });

      this.$refs.deleteConfirmationModal.closeModal();
    },
  },
};
</script>
