<template>
  <view-wrapper>
    <p class="pb-6 font-bold">
      {{ $t('views.profiles.delete-question') }}
    </p>
    <p class="pb-6" data-test="deletion-modal-name">
      {{ profile.givenName }} {{ profile.familyName }}
    </p>
    <p class="pb-6 text-xs text-gray-700">
      {{ $t('views.profiles.delete-note') }}
    </p>
  </view-wrapper>
</template>

<script>
import ViewWrapper from '@/components/common/ViewWrapper.vue';

export default {
  name: 'ProfileDelete',
  components: { ViewWrapper },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
};
</script>
