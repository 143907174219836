<template>
  <n-tooltip>
    <slot> </slot>
    <template #content>
      <div class="w-full">
        <span class="italic">{{ title }} <br /> </span>
        <span class="font-bold">{{ description }}</span>
        <br />
        <span>
          {{ $t('common.labels.planned') }}: {{ desiredPercentageWithRatio }}%
        </span>
        <br />
        <span>{{ $t('common.labels.current') }}: {{ actual }}%</span>
      </div>
    </template>
  </n-tooltip>
</template>
<script>
import { DEPUTATION_STATUS, getTextByKey } from '@/constants/picklists';

export default {
  name: 'DeputationTooltip',
  props: {
    planned: {
      type: Number,
      default: 100,
      validator(value) {
        return value >= 0;
      },
    },
    actual: {
      type: Number,
      default: 0,
      validator(value) {
        return value >= 0;
      },
    },
    deputationStatus: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    description() {
      return getTextByKey(DEPUTATION_STATUS, this.deputationStatus);
    },
    desiredPercentageWithRatio() {
      return this.planned > 0 ? 100 : 0;
    },
  },
};
</script>
