import { Api } from '@/api';
import {
  mapMultipleUnitsNames,
  mapSingleUnitName,
  unmapSingleMappedUnitName,
} from '@/utils/units-name-mapper';

export default {
  namespaced: true,
  state: {
    profileId: null,
    assignedUnitsAndCampuses: [],
    currentUnit: {
      selectedUnit: null,
      possibleCampuses: [],
    },
    possibleUnits: [],
    isLoading: false,
    deleteConfirmationOpen: false,
    selectedUnitToDelete: null,
    unitModalOpen: false,
    isLoadingPossibleCampuses: false,
  },
  getters: {
    possibleUnits: (state) => mapMultipleUnitsNames(state.possibleUnits).sort(),
    assignedUnitsAndCampuses: (state) => {
      state.assignedUnitsAndCampuses.map((item) => {
        if (Array.isArray(item.campuses)) {
          item.campuses.sort();
        }
      });
      return state.assignedUnitsAndCampuses.sort((a, b) => a.unit > b.unit);
    },
    selectedUnit: (state) => mapSingleUnitName(state.currentUnit.selectedUnit),
    possibleCampuses: (state) => {
      return Array.isArray(state.currentUnit.possibleCampuses)
        ? state.currentUnit.possibleCampuses.sort()
        : state.currentUnit.possibleCampuses;
    },
    isLoading: (state) => state.isLoading,
    profileId: (state) => state.profileId,
    deleteConfirmationOpen: (state) => state.deleteConfirmationOpen,
    selectedUnitToDelete: (state) =>
      mapSingleUnitName(state.selectedUnitToDelete),
    unitModalOpen: (state) => state.unitModalOpen,
    isLoadingPossibleCampuses: (state) => state.isLoadingPossibleCampuses,
  },
  actions: {
    resetState({ commit }) {
      commit('setProfileId', null);

      commit('setAssignedUnitsAndCampuses', []);

      commit('setCurrentUnit', {
        selectedUnit: null,
        possibleCampuses: [],
      });

      commit('setIsLoading', false);

      commit('setPossibleUnits', []);

      commit('setDeleteConfirmationOpen', false);

      commit('setSelectedUnitToDelete', null);
    },
    setProfileId({ commit }, profileId) {
      commit('setProfileId', profileId);
    },
    async fetchAndStoreAssignedUnitsAndCampuses({ commit, getters }) {
      commit('setIsLoading', true);

      try {
        const resp = await Api.locations.getAssignedUnitsAndCampusesByProfileId(
          getters.profileId
        );

        commit('setAssignedUnitsAndCampuses', resp.data);

        commit('setIsLoading', false);
      } catch {
        commit('setIsLoading', false);
      }
    },
    async fetchPossibleCampusesAndOpenModal({ commit, getters }, unit) {
      commit('setIsLoading', true);

      try {
        const resp = await Api.locations.getPossibleCampusesByUnitAndId({
          unit,
          profileId: getters.profileId,
        });

        const possibleCampuses = resp.data ?? [];

        commit('setCurrentUnit', {
          selectedUnit: unmapSingleMappedUnitName(unit),
          possibleCampuses,
        });

        commit('setIsLoading', false);
      } catch {
        commit('setIsLoading', false);
      }
    },
    openUnitModal({ commit }) {
      commit('setCurrentUnit', {
        selectedUnit: null,
        possibleCampuses: [],
      });

      commit('setUnitModalOpen', true);
    },
    async updateSelectedUnitAndGetPossibleCampuses(
      { commit, getters },
      selectedUnit
    ) {
      commit('setIsLoadingPossibleCampuses', true);
      const unit = unmapSingleMappedUnitName(selectedUnit);
      commit('setCurrentUnit', {
        selectedUnit: unit,
        possibleCampuses: [],
      });

      try {
        const resp = await Api.locations.getPossibleCampusesByUnitAndId({
          unit,
          profileId: getters.profileId,
        });

        commit('setCurrentUnit', {
          selectedUnit: unit,
          possibleCampuses: resp.data?.length > 0 ? resp.data : undefined,
        });

        commit('setIsLoadingPossibleCampuses', false);
      } catch {
        commit('setIsLoadingPossibleCampuses', false);
      }
    },
    closeUnitModal({ commit }) {
      commit('setUnitModalOpen', false);
    },
    async fetchAndStorePossibleUnits({ commit, getters }) {
      commit('setIsLoading', true);

      try {
        const resp = await Api.locations.getPossibleUnitsById(
          getters.profileId
        );

        commit('setPossibleUnits', resp.data);

        commit('setIsLoading', false);
      } catch {
        commit('setIsLoading', false);
      }
    },
    openDeleteConfirmationModal({ commit }, unitName) {
      commit('setSelectedUnitToDelete', unitName);

      commit('setDeleteConfirmationOpen', true);
    },
    closeDeleteConfirmationModal({ commit }) {
      commit('setDeleteConfirmationOpen', false);

      commit('setSelectedUnitToDelete', null);
    },
    async assignLocationToProfile({ commit, state, dispatch }) {
      commit('setIsLoading', true);

      const profileId = state.profileId;
      const payload = {
        unit: state.currentUnit.selectedUnit,
        /*
         *  IMPORTANT NOTE:
         *  it has to be an array with null as item if there is no campus in the unit,
         *  as otherwise api thinks it should remove every assigned Campus.
         *  item null = online, whoever thought this is a good idea
         */
        campuses: state.currentUnit.possibleCampuses?.length
          ? state.currentUnit.possibleCampuses
          : [null],
        profileId,
      };

      try {
        await Api.locations.assignLocationToProfile(profileId, payload);

        await dispatch('fetchAndStorePossibleUnits');

        await dispatch('closeUnitModal');

        await dispatch('fetchAndStoreAssignedUnitsAndCampuses');

        await dispatch(
          'locationPreferences/getAssignedLocations',
          {},
          {
            root: true,
          }
        );

        await dispatch('mainLocation/fetchPossibleUnits', {}, { root: true });
        await dispatch(
          'mainLocation/fetchPossibleCampuses',
          {},
          { root: true }
        );

        commit('setIsLoading', false);
        await dispatch(
          'snackbar/update',
          { message: 'Die Unit wurde erfolgreich zugewiesen' },
          {
            root: true,
          }
        );
      } catch {
        commit('setIsLoading', false);
      }
    },
    async deleteAssignedCampusByProfileIdAndUnitName(
      { commit, getters, dispatch },
      unitName
    ) {
      commit('setIsLoading', true);

      try {
        await Api.locations.deleteAssignedLocationByProfileIdAndUnitName(
          getters.profileId,
          unmapSingleMappedUnitName(unitName)
        );

        await dispatch('fetchAndStorePossibleUnits');

        await dispatch('closeDeleteConfirmationModal');

        await dispatch('fetchAndStoreAssignedUnitsAndCampuses');
        await dispatch(
          'locationPreferences/getAssignedLocations',
          {},
          {
            root: true,
          }
        );

        await dispatch('mainLocation/fetchPossibleUnits', {}, { root: true });
        await dispatch(
          'mainLocation/fetchPossibleCampuses',
          unmapSingleMappedUnitName(unitName),
          { root: true }
        );

        commit('setIsLoading', false);
      } catch {
        commit('setIsLoading', false);
      }
    },
  },
  mutations: {
    setPossibleUnits(state, value) {
      state.possibleUnits = value;
    },
    setAssignedUnitsAndCampuses(state, value) {
      state.assignedUnitsAndCampuses = value;
    },
    setCurrentUnit(state, value) {
      state.currentUnit = value;
    },
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setProfileId(state, profileId) {
      state.profileId = profileId;
    },
    setUnitModalOpen(state, value) {
      state.unitModalOpen = value;
    },
    setIsLoadingPossibleCampuses(state, value) {
      state.isLoadingPossibleCampuses = value;
    },
    setDeleteConfirmationOpen(state, value) {
      state.deleteConfirmationOpen = value;
    },
    setSelectedUnitToDelete(state, value) {
      state.selectedUnitToDelete = unmapSingleMappedUnitName(value);
    },
  },
};
