<template>
  <div class="flex flex-col md:flex-row">
    <deputation-bar-segment
      v-for="capability in summaryCapabilities"
      :key="capability"
      :disabled="!isDeputationAvailable"
      :current-budget-in-percent="getCapabilityBudget(capability)"
      :desired-budget-in-percent="getPlannedBudgetInPercent(capability)"
      :deputation-status="capability.deputationStatus"
      :capability="capability"
      :class="`w-12/12 md:w-${getCapabilityWidth(capability)}/12 mr-1`"
    ></deputation-bar-segment>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DeputationBarSegment from '@/components/deputation/Deputation/Summary/DeputationBarSegment.vue';

export default {
  name: 'DeputationBar',
  components: { DeputationBarSegment },

  computed: {
    ...mapGetters('capabilities', ['assignedCapabilities']),
    ...mapGetters('deputation', [
      'summaryCapabilities',
      'isDeputationAvailable',
    ]),
  },
  methods: {
    getPlannedBudgetInPercent(capability) {
      return this.findCapability(capability).budgetInPercent || 0;
    },
    findCapability(capability) {
      return (
        this.assignedCapabilities.find(
          (assignedCapability) =>
            assignedCapability?.capability === capability?.capability
        ) || {}
      );
    },
    getCapabilityWidth(capability) {
      let width = this.calculateWidth(capability);

      if (this.isPriorityCapability(capability)) {
        width += this.remainderWidth();
      }
      return Math.max(Math.min(width, 12), 1);
    },
    isPriorityCapability(capability) {
      const highestBudget = this.highestAssignedBudget();
      return this.getPlannedBudgetInPercent(capability) === highestBudget;
    },
    highestAssignedBudget() {
      return Math.max(
        ...this.assignedCapabilities.map((cap) =>
          this.getPlannedBudgetInPercent(cap)
        )
      );
    },
    calculateWidth(capability) {
      const totalPercent = this.getTotalPercent();
      const capabilityBudget = this.getPlannedBudgetInPercent(capability);
      return Math.round((capabilityBudget / totalPercent) * 12);
    },
    remainderWidth() {
      const totalWidth = this.summaryCapabilities
        .map((cap) => this.calculateWidth(cap))
        .reduce((sum, currentWidth) => sum + currentWidth, 0);
      return 12 - totalWidth;
    },
    getCapabilityBudget(capability) {
      const assignedCapability = this.findCapabilityFromSummary(capability);

      if (this.zeroPlannedAndNonZeroCurrentBudget(assignedCapability)) {
        return 200;
      }

      if (this.zeroPlannedAndZeroCurrentBudget(assignedCapability)) {
        return 0;
      }

      return this.computeBudget(assignedCapability);
    },
    zeroPlannedAndNonZeroCurrentBudget(assignedCapability) {
      return (
        assignedCapability.plannedBudgetInPercent === 0 &&
        assignedCapability.currentBudgetInPercent > 0
      );
    },
    zeroPlannedAndZeroCurrentBudget(assignedCapability) {
      return (
        assignedCapability.currentBudgetInPercent === 0 &&
        assignedCapability.plannedBudgetInPercent === 0
      );
    },
    findCapabilityFromSummary(capability) {
      return (
        this.summaryCapabilities.find(
          (assignedCapability) =>
            assignedCapability?.capability === capability?.capability
        ) || {}
      );
    },
    computeBudget(assignedCapability) {
      if (assignedCapability) {
        return Math.round(
          (assignedCapability.currentBudgetInPercent /
            assignedCapability.plannedBudgetInPercent) *
            100,
          2
        );
      }
      return 0;
    },
    getTotalPercent() {
      return this.assignedCapabilities.reduce(
        (total, capability) => total + capability.budgetInPercent,
        0
      );
    },
  },
};
</script>
