import { ApiClient } from '@/api/ApiClient';
import { pluckData } from '@/utils/pluckData';

export const dataImport = {
  uploadFile: (payload) =>
    ApiClient.instance.post(`/data-ingestion/upload`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  getJobOverview: () =>
    ApiClient.instance.get(`/import-orchestration`).then(pluckData),
  getJobDetail: (id) =>
    ApiClient.instance.get(`/import-orchestration/${id}`).then(pluckData),
};
