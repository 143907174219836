<template>
  <div class="mb-4">
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-html="
        $t('views.data-import-detailed-import-summary-table.error-count', {
          count: errorCount,
        })
      "
    ></span>
    <!-- eslint-enable vue/no-v-html -->
    {{ ' ' }}
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-html="
        $t('views.data-import-detailed-import-summary-table.updated-count', {
          count: updatedRowsCount,
        })
      "
    ></span>
    <!-- eslint-enable vue/no-v-html -->
    {{ ' ' }}
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-html="
        $t('views.data-import-detailed-import-summary-table.skipped-count', {
          count: skippedRowsCount,
        })
      "
    ></span>
    <!-- eslint-enable vue/no-v-html -->

    <n-table
      v-if="hasUnsuccessfulImports"
      :header-items="headerItems"
      :data="unsuccessfulImports"
      :is-loading="isLoading"
      class="scrollable-table"
    >
      <template #th-type="{ item }">
        <span class="py-2">{{ item.label }}</span>
      </template>
      <template #th-dataRow="{ item }">
        <span class="py-2">{{ item.label }}</span>
      </template>
      <template #th-errorMessage="{ item }">
        <span class="py-2">{{ item.label }}</span>
      </template>
      <template #row="{ item }">
        <n-table-row :item="item">
          <td>{{ item.type }}</td>
          <td>{{ item.dataRow }}</td>
          <td>{{ item.errorMessage }}</td>
        </n-table-row>
      </template>
    </n-table>
  </div>
</template>

<script>
import { t } from '@/plugins/translations';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'DetailedImportSummaryTable',

  props: {
    importJob: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      headerItems: [
        {
          class: 'w-2/12 px-3',
          get label() {
            return t(
              'views.data-import-detailed-import-summary-table.header.type'
            );
          },
          key: 'type',
        },
        {
          class: 'w-8/12 px-3',
          get label() {
            return t(
              'views.data-import-detailed-import-summary-table.header.data-row'
            );
          },
          key: 'dataRowId',
        },
        {
          class: 'w-2/12 px-3',
          get label() {
            return t(
              'views.data-import-detailed-import-summary-table.header.error-message'
            );
          },
          key: 'errorMessage',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('dataImport', [
      'getJobDetail',
      'getUnsuccessfulImports',
      'getUpdatedRowsCount',
      'getSkippedRowsCount',
      'getErrorCount',
      'hasUnsuccessfulImports',
    ]),
    isLoading() {
      return this.getLoading;
    },
    unsuccessfulImports() {
      return this.getUnsuccessfulImports;
    },
    updatedRowsCount() {
      return this.getUpdatedRowsCount;
    },
    skippedRowsCount() {
      return this.getSkippedRowsCount;
    },
    errorCount() {
      return this.getErrorCount;
    },
  },

  watch: {
    expanded(val) {
      if (val) {
        this.loadJobDetail(this.importJob.id);
      }
    },
  },

  methods: {
    ...mapActions('dataImport', ['loadJobDetail']),
  },
};
</script>

<style scoped>
.scrollable-table {
  display: block;
  max-height: 400px;
  overflow-y: auto;
}
</style>
