<template>
  <n-menu
    :actions="actions"
    @click:quarter="onSelectGroupType(DeputationGroupType.QUARTER)"
    @click:month="onSelectGroupType(DeputationGroupType.MONTH)"
    @click:semester="onSelectGroupType(DeputationGroupType.SEMESTER)"
  >
  </n-menu>
</template>
<script>
import { DeputationGroupType } from '@/constants/deputationGroupType';
import { NMenu } from '@careerpartner/nitro';

export default {
  name: 'DeputationDetailViewSelector',
  components: { NMenu },
  props: {
    view: {
      type: String,
      default: DeputationGroupType.MONTH,
    },
  },
  emits: ['update:selectedView'],
  computed: {
    actions() {
      return [
        {
          name: DeputationGroupType.QUARTER,
          text: 'Quartal',
        },
        {
          name: DeputationGroupType.MONTH,
          text: 'Monat',
        },
        {
          name: DeputationGroupType.SEMESTER,
          text: 'Semester',
        },
      ];
    },
    DeputationGroupType() {
      return DeputationGroupType;
    },
  },
  methods: {
    onSelectGroupType(groupType) {
      this.$emit('update:selectedView', groupType);
    },
  },
};
</script>
