import { format } from 'date-fns';

export const formatDate = (dateString) => {
  try {
    if (!dateString) {
      throw new Error('invalid date');
    }

    return format(new Date(dateString), 'dd/MM/yy');
  } catch (e) {
    return '01/01/1970';
  }
};
