import { t } from '@/plugins/translations';

export const PREFERENCES = {
  POSITIVE: {
    chipColor: 'green',
    get text() {
      return t('common.enums.preference.positive');
    },
    value: 'POSITIVE',
  },
  NEGATIVE: {
    chipColor: 'red',
    get text() {
      return t('common.enums.preference.negative');
    },
    value: 'NEGATIVE',
  },
  NEUTRAL: {
    chipColor: 'gray',
    get text() {
      return t('common.enums.preference.neutral');
    },
    value: 'NEUTRAL',
  },
  UNKNOWN: {
    chipColor: 'dark-blue',
    get text() {
      return t('common.enums.preference.unknown');
    },
    value: 'UNKNOWN',
  },
};
export const PREFERENCES_CONSTANTS = Object.values(PREFERENCES);

export const findPreference = (value) =>
  PREFERENCES_CONSTANTS.find((pref) => pref.value === value);

const DEFAULT_PREFERENCE_VALUE = PREFERENCES.NEUTRAL.value;

export const DISALLOWED_PREFERENCES = [PREFERENCES.UNKNOWN.value];

export const DEFAULT_PREFERENCE = findPreference(DEFAULT_PREFERENCE_VALUE);
