import { ContactsResourceFactory } from '@/api/ApiResourceFactory';

const _phones = ContactsResourceFactory('phones');

export const phoneTransform = ({ primary = false, type, phonenumber }) => ({
  primary,
  type,
  phonenumber,
});

export const phones = {
  ..._phones,
  updateById: (id, payload) => _phones.updateById(id, phoneTransform(payload)),
};
