<template>
  <profiles-list-filter-info
    v-model="nameModel"
    :filters="filters"
    @reset-filter="resetFilter"
  />
  <p v-if="hasTotalCount" class="text-right mt-8 mb-3">
    {{ $t('views.profiles.list.totalCount.displaying') }}
    <span class="font-semibold text-primary">{{ pagination.totalCount }}</span>
    {{ $t('views.profiles.list.totalCount.teachers') }}
  </p>
  <n-table :header-items="headers" :data="profiles" :is-loading="isLoading">
    <template #filter-teachingLevel="{ close }">
      <n-table-filter-checkbox
        id="teaching_level_filter"
        v-model="teachingLevelsModel"
        :options="teachingLevelOptions"
        data-test="teaching-level-filter"
        @close="close"
      />
    </template>
    <template #filter-employmentType="{ close }">
      <n-table-filter-checkbox
        id="employment_type_filter"
        v-model="employmentTypeModel"
        :options="employmentTypeOptions"
        data-test="employment-type-filter"
        @close="close"
      />
    </template>
    <template #filter-brand="{ close }">
      <n-table-filter-checkbox
        id="brand_filter"
        v-model="brandsModel"
        :options="brandOptions"
        data-test="teaching-level-filter"
        @close="close"
      />
    </template>
    <template #filter-units="{ close }">
      <n-table-filter-checkbox
        id="units_filter"
        v-model="unitsModel"
        :options="unitsOptions"
        data-test="teaching-level-filter"
        @close="close"
      />
    </template>
    <template #filter-planningStatus="{ close }">
      <n-table-filter-checkbox
        id="planning_status_filter"
        v-model="planningStatusModel"
        :options="planningStatusOptions"
        data-test="planning-status-filter"
        @close="close"
      />
    </template>
    <template #filter-preferenceComplete="{ close }">
      <n-table-filter-checkbox
        id="preference_complete_filter"
        v-model="preferenceModel"
        :options="preferenceOptions"
        data-test="preference-filter"
        @close="close"
      />
    </template>
    <template #row="{ item: profile, isExpanded }">
      <n-table-row :item="profile">
        <profiles-list-table-data :profile="profile" />
        <template #extended>
          <profiles-list-expandable
            :is-expanded="isExpanded"
            :profile="profile"
          />
        </template>
      </n-table-row>
    </template>
    <template #no-results>
      <n-empty-results
        v-if="filters.length > 0"
        :text="$t('views.profiles.list.empty-results.text')"
        :reset-text="$t('views.profiles.list.empty-results.reset-text')"
        @reset="resetFilter"
      />
      <n-empty-data v-else :text="$t('views.profiles.list.empty-data.text')" />
    </template>
  </n-table>
  <n-pagination
    v-if="hasTotalCount && pagination.totalCount > (pagination?.limit ?? 0)"
    v-model="paginationModel"
  />
</template>

<script>
import { Api } from '@/api';
import { formatDate } from '@/utils/assignment-dates-formatter';
import ProfilesListFilterInfo from '@/components/profiles/ProfilesList/ProfilesListFilterInfo.vue';
import ProfilesListComputed from '@/components/profiles/ProfilesList/mixins/profiles-list.computed.mixin';
import ProfilesListData from '@/components/profiles/ProfilesList/mixins/profiles-list.data.mixin';
import ProfilesListProps from '@/components/profiles/ProfilesList/mixins/profiles-list.props.mixin';
import ProfilesListExpandable from '@/components/profiles/ProfilesList/ProfilesListExpandable.vue';
import ProfilesListTableData from '@/components/profiles/ProfilesList/ProfilesListTableData.vue';
export default {
  name: 'ProfilesList',
  components: {
    ProfilesListTableData,
    ProfilesListExpandable,
    ProfilesListFilterInfo,
  },
  mixins: [ProfilesListComputed, ProfilesListData, ProfilesListProps],
  emits: [
    'submit',
    'update:pagination',
    'reset',
    'delete-profile',
    'update:name',
    'update:email',
    'update:teachingLevels',
    'update:employmentType',
    'update:brands',
    'update:units',
    'update:planningStatus',
    'update:preferences',
  ],
  computed: {
    hasTotalCount() {
      return 'totalCount' in (this?.pagination ?? {});
    },
  },
  async beforeMount() {
    const { data } = await Api.profiles.getAllUnits();
    this.unitsOptions.push(
      ...data.map((unit) => ({ value: unit, label: unit }))
    );
  },
  methods: {
    formatDate,
    resetFilter(key) {
      if (!key) {
        this.nameModel = '';
        this.preferenceModel = [];
        this.teachingLevelsModel = [];
        this.employmentTypeModel = [];
        this.brandsModel = [];
        this.unitsModel = [];
        this.planningStatusModel = [];
      } else {
        if (Array.isArray(this[key])) {
          this[key] = [];
        } else if (!Array.isArray(this[key])) {
          this[key] = '';
        }
      }
    },
  },
};
</script>
