import { Api } from '@/api';
import { sortOrder } from '@/constants/sortOrder';
import { t } from '@/plugins';

export default {
  namespaced: true,
  state: {
    assignments: [],
    assignmentCategories: [],
    loading: false,
    pagination: {
      offset: 0,
      limit: 10,
      totalCount: 0,
    },
    filters: {},
    sortParams: { duration: sortOrder.ASC },
    selectedYear: new Date().getFullYear(),
  },
  getters: {
    assignments: (state) => state.assignments,
    pagination: (state) => state.pagination,
    filters: (state) => state.filters,
    sortParams: (state) => state.sortParams,
    isLoading: (state) => state.loading,
    selectedYear: (state) => state.selectedYear,
    assignmentCategories: (state) => state.assignmentCategories,
  },
  actions: {
    async createAssignment({ state, commit, dispatch }, assignment) {
      commit('errors/clearErrors', null, { root: true });
      commit('setLoading', true);

      const { profileId } = assignment;

      try {
        await Api.assignments.create(profileId, assignment);

        commit('deputation/setSelectedYear', state.selectedYear, {
          root: true,
        });

        await Promise.all([
          dispatch('loadAssignments', { profileId, year: state.selectedYear }),
          dispatch('deputation/fetchSummary', null, { root: true }),
          dispatch('deputation/fetchDetailData', null, { root: true }),
          dispatch(
            'snackbar/update',
            {
              message: t(
                'views.deputation.common.assignment-created-successfully'
              ),
            },
            { root: true }
          ),
        ]);
      } catch (e) {
        commit('updateAssignments', []);

        await dispatch('loadAssignments', {
          profileId,
          year: state.selectedYear,
        });

        dispatch('errors/pushError', e, { root: true });
      } finally {
        commit('setLoading', false);
      }
    },
    async loadAssignments({ commit, getters }, { profileId, year }) {
      commit('errors/clearErrors', null, { root: true });
      commit('setLoading', true);
      try {
        const startDate = `${year}-01-01`;
        const endDate = `${year}-12-31`;

        const { limit, offset } = this.state.assignments.pagination;

        const resp = await Api.assignments.getByProfileId(profileId, {
          limit,
          offset,
          filter: {
            startDate,
            endDate,
            ...getters.filters,
          },
          sort: {
            ...getters.sortParams,
          },
        });

        commit('updateAssignments', resp?.data?.data ?? []);

        commit('updatePagination', resp?.data.pagination ?? {});
      } catch (e) {
        commit('updateAssignments', []);
      } finally {
        commit('setLoading', false);
      }
    },
    resetState({ commit }) {
      commit('updateAssignments', []);
      commit('setLoading', false);
      commit('setSelectedYear', new Date().getFullYear());
    },
    setSelectedYear({ commit }, year) {
      commit('setSelectedYear', year);
    },
    resetFilters({ commit }) {
      commit('updateFilters', {});
    },
    updatePagination({ commit }, pagination) {
      commit('updatePagination', pagination);
    },
    async deleteAssignment({ commit, dispatch }, { profileId, assignmentId }) {
      commit('errors/clearErrors', null, { root: true });
      commit('setLoading', true);

      const assignmentYear = this.state.assignments.selectedYear;

      try {
        await Api.assignments.delete(profileId, assignmentId);
        await dispatch('resetFilters');
        await dispatch('loadAssignments', { profileId, year: assignmentYear });
        await dispatch('deputation/fetchSummary', null, { root: true });
        await dispatch('deputation/fetchDetailData', null, { root: true });
      } catch (e) {
        dispatch('errors/pushError', e, { root: true });
      } finally {
        commit('setLoading', false);
      }
    },
    async loadAssignmentCategories({ commit }) {
      commit('errors/clearErrors', null, { root: true });
      commit('setLoading', true);
      try {
        const { data } = await Api.assignments.getAssignmentCategories();

        commit('updateAssignmentCategories', data);
      } catch (e) {
        commit('updateAssignmentCategories', []);
      } finally {
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    updateAssignments(state, assignments) {
      state.assignments = assignments;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setSelectedYear(state, year) {
      state.selectedYear = year;
    },
    updatePagination(state, pagination) {
      state.pagination = pagination;
    },
    updateFilters(state, filters) {
      state.filters = filters;
    },
    updateAssignmentCategories(state, assignmentCategories) {
      state.assignmentCategories = assignmentCategories;
    },
  },
};
