<template>
  <n-applet-wrapper id="epos-academic-staff">
    <router-view />
  </n-applet-wrapper>
</template>

<script>
import { mapActions } from 'vuex';
import { NAppletWrapper } from '@careerpartner/nitro';

export default {
  name: 'AcademicStaff',
  components: { NAppletWrapper },
  mounted() {
    this.setCurrentUser();
    this.fetchInit();
  },
  methods: {
    ...mapActions({
      fetchInit: 'init/fetchInit',
      setCurrentUser: 'currentUser/setCurrentUser',
    }),
    reload() {
      window.location.reload();
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>
