import { locale } from '@/plugins/translations';
import { DeputationGroupType } from '@/constants/deputationGroupType';
import { getTextByKey } from '@/constants/picklists';
import { SEMESTER_SHORT } from '@/constants/picklists/semesters';

const WINTER_SEMESTER = 2;
const SUMMER_SEMESTER = 1;

const monthLabel = (month) =>
  new Date(1994, month - 1, 1).toLocaleString(locale.value, { month: 'long' });

const yearLabelForCorrespondingSemester = (
  yearLabel,
  nextYearLabel,
  isWinter
) => (isWinter ? `${yearLabel}/${nextYearLabel}` : `${yearLabel}`);

const lastTwoDigitsOfYear = (year) => {
  const yearLabel = String(year).slice(-2);

  const nextYearLabel = String(year + 1).slice(-2);

  return { yearLabel, nextYearLabel };
};

const getSemesterLabel = (semester) =>
  getTextByKey(
    SEMESTER_SHORT,
    semester === SUMMER_SEMESTER ? 'summer' : 'winter'
  );

const buildSemesterTextWithYear = (semester, year) => {
  const semesterLabel = getSemesterLabel(semester);

  const { yearLabel: shortYearLabel, nextYearLabel } =
    lastTwoDigitsOfYear(year);

  return `${semesterLabel} ${yearLabelForCorrespondingSemester(
    shortYearLabel,
    nextYearLabel,
    semester === WINTER_SEMESTER
  )}`;
};

export const buildDeputationGroupLabel = (
  groupValue,
  groupType,
  selectedYear = new Date().getFullYear()
) => {
  const parsedGroupValue = parseInt(groupValue, 10);

  if (groupType === DeputationGroupType.MONTH) {
    return monthLabel(parsedGroupValue);
  } else if (groupType === DeputationGroupType.QUARTER) {
    return `Q ${parsedGroupValue}`;
  } else if (groupType === DeputationGroupType.SEMESTER) {
    return buildSemesterTextWithYear(parsedGroupValue, selectedYear);
  } else {
    throw new Error(`Unknown deputation group type: ${groupType}`);
  }
};
