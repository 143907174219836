export const UnitColors = Object.freeze({
  'NEC München': 'bg-orange-50',
  Upskilling: 'bg-red-50',
  DS: 'bg-pink-50',
  HU: 'bg-blue-50',
  'UK LIBF': 'bg-green-50',
  'UK IUCL': 'bg-green-200',
  CS: 'bg-blue-100',
  'Distance Learning': 'bg-yellow-50',
});
