<template>
  <n-button
    variant="tertiary"
    :class="active ? 'text-cta' : 'text-gray-300'"
    @click="handleStateTransition"
  >
    <n-icon :icon="icon"></n-icon>
  </n-button>
</template>

<script>
import { sortOrder } from '@/constants/sortOrder';

export default {
  name: 'ColumnSortButton',
  props: {
    columnName: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: ['onNewSort'],
  computed: {
    icon() {
      switch (this.value) {
        case sortOrder.ASC:
          return 'sort-alphabetical-ascending';
        case sortOrder.DESC:
          return 'sort-alphabetical-descending';
        default:
          return 'sort-alphabetical-ascending';
      }
    },
    active() {
      return [sortOrder.ASC, sortOrder.DESC].includes(this.value);
    },
  },
  methods: {
    handleStateTransition() {
      switch (this.value) {
        case sortOrder.ASC:
          this.$emit('onNewSort', {
            key: this.columnName,
            order: sortOrder.DESC,
          });
          break;
        case sortOrder.DESC:
          this.$emit('onNewSort', {
            key: this.columnName,
            order: sortOrder.ASC,
          });
          break;
      }
    },
  },
};
</script>
