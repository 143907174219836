export { ADDRESS_TYPES } from './addressTypes';
export { DEGREES } from './degrees';
export { EMAIL_TYPES } from './emailTypes';
export { PHONE_TYPES } from './phoneTypes';
export { PROFILE_PRESETS } from './profilePresets';
export { CAPABILITIES } from './capabilities';
export * from './assignmentTypes';
export * from './availableDeputationStatus';
export * from './semesters';
export const getTextByKey = (picklist, key) =>
  picklist.find((item) => item.key === key)?.text;

export const getIconByKey = (picklist, key) =>
  picklist.find((item) => item.key === key)?.icon ||
  picklist.find((item) => item.key === 'default')?.icon;
