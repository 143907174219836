<template>
  <div class="flex">
    <div class="justify-between w-full">
      <div :class="`h-4 bg-${background} w-full rounded`">
        <div
          :class="`h-4 bg-${filledColor} ${progressFractionClass} ${roundedClass}  mr-0.5`"
        >
          <div class="m-auto align-middle">
            <deputation-tooltip
              class="border-0"
              :actual="currentBudgetInPercent"
              :deputation-status="deputationStatus"
              :planned="desiredBudgetInPercent"
              :title="capabilityTitle"
            >
              <span class="font-bold text-transparent pl-5 text-center">
                {{ budgetText }}
              </span>
            </deputation-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDeputationStatusColorTuple } from '@/constants/picklists';
import DeputationTooltip from '@/components/deputation/Common/DeputationTooltip.vue';
import { getTextByCapability } from '@/constants/picklists/capabilities';

export default {
  name: 'MinimalDeputationBarSegment',
  components: { DeputationTooltip },
  props: {
    currentBudgetInPercent: {
      type: Number,
      default: 0,
      validator(value) {
        return value >= 0;
      },
    },
    desiredBudgetInPercent: {
      type: Number,
      default: 100,
      validator(value) {
        return value >= 0;
      },
    },
    deputationStatus: {
      type: String,
      required: true,
    },
    capability: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filledColor() {
      return getDeputationStatusColorTuple(this.deputationStatus).filledColor;
    },
    background() {
      return getDeputationStatusColorTuple(this.deputationStatus)
        .backgroundColor;
    },
    roundedClass() {
      return this.currentBudgetInPercent < 100 &&
        this.currentBudgetInPercent > 0
        ? 'rounded-l'
        : 'rounded';
    },
    progressFractionClass() {
      const progress = this.progressFraction;

      return progress ? `w-${progress}/12` : `w-0`;
    },
    progressFraction() {
      let progress = this.currentBudgetInPercent;

      if (this.currentBudgetInPercent > 100) {
        progress = 100;
      }

      progress = Math.round((progress / 100) * 12);

      return progress;
    },
    budgetText() {
      return `${this.currentBudgetInPercent}%`;
    },
    capabilityTitle() {
      return getTextByCapability(this.capability);
    },
  },
};
</script>
