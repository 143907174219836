<template>
  <Popper
    arrow
    hover
    :disabled="disabled || !($slots.content || content)"
    :placement="placement"
    class="n-tooltip"
  >
    <slot />

    <template #content="contentProps">
      <p class="text-center text-base max-h-52 overflow-y-scroll p-2">
        <slot v-if="$slots.content" name="content" v-bind="contentProps" />
        <template v-else>{{ content }}</template>
      </p>
    </template>
  </Popper>
</template>

<script>
import Popper from 'vue3-popper';

export default {
  name: 'Tooltip',
  components: { Popper },
  props: {
    content: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'top',
    },
  },
};
</script>

<style scoped>
.n-tooltip {
  display: inline-flex !important; /* overrule vue3-popper */
}

.n-tooltip :deep(> div:not(.popper)) {
  @apply flex flex-1;
}

.n-tooltip :deep(.popper) {
  @apply max-w-xs rounded bg-gray-800 px-2 py-1 text-center text-sm text-white shadow-sm;
}

.n-tooltip :deep(.popper #arrow::before),
.n-tooltip :deep(.popper:hover),
.n-tooltip :deep(.popper:hover > #arrow::before) {
  @apply bg-gray-800;
}
</style>
