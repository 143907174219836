<template>
  <div class="h-6 w-6 rounded-full" :class="`bg-${bgColor}`">
    <div class="h-4 w-4 m-auto">
      <n-icon :icon="icon" class="text-base" :class="`text-${color}`" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconWithRoundedBackground',
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'blue-600',
    },
    bgColor: {
      type: String,
      default: 'blue-100',
    },
  },
};
</script>
