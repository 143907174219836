<template>
  <div :title="spinnerText" class="loading-spinner"></div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    spinnerText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.loading-spinner {
  position: absolute;
  top: 50%;
  right: 15px;
  width: 20px;
  height: 20px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3772ff;
  border-top-color: #3772ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  transform: translateY(-50%);
  z-index: 10;
}

@keyframes spin {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}
</style>
