<template>
  <n-table
    :header-items="headerItems"
    :data="capabilities"
    :is-loading="isLoading"
  >
    <template #filter-studyPrograms="{ close }">
      <table-filter-multiselect
        id="study_program_filter"
        v-model="studyProgramsFilterModel"
        data-test="study-program-filter"
        value="studyProgram"
        :label="$t('common.labels.study-program')"
        :options="trackedStudyPrograms"
        multiple
        @close="close"
      />
    </template>
    <template #row="{ item: course }">
      <n-table-row
        :class="{
          'text-gray-400': !course.whitelisted,
          'bg-[#c0c9e8]': hasUpdatedPreferences(course.capabilityId),
        }"
      >
        <td class="font-bold">
          {{ course.defaultCourseTitle }}
        </td>
        <td>
          {{ course.courseCode }}
        </td>
        <td class="pl-3">
          <div class="inline-flex">
            <set-capability-preference-chip
              :editable="course.whitelisted"
              :selected-value="findPreferenceValue(course)"
              @select="updatePreferences($event, course.capabilityId)"
            />
            <n-tooltip
              v-if="!course.whitelisted"
              :content="
                $t(
                  'views.course-capabilities.preferences.select-preferences-table.course-not-whitelisted'
                )
              "
            >
              <n-icon icon="information-outline" class="text-cta" />
            </n-tooltip>
          </div>
        </td>
        <td>
          <scrollable-tooltip
            :content="course.studyPrograms?.join(', ') || '-'"
          >
            {{
              course.studyPrograms?.length
                ? truncateText(course.studyPrograms.join(', '))
                : '-'
            }}
          </scrollable-tooltip>
        </td>
      </n-table-row>
    </template>
  </n-table>
</template>

<script>
import { t } from '@/plugins/translations';
import SetCapabilityPreferenceChip from '@/components/course-capabilities/Preferences/SetCapabilityPreferenceChip.vue';
import {
  DEFAULT_PREFERENCE,
  PREFERENCES,
} from '@/constants/picklists/preferences';
import { truncateText } from '@/utils/text-utils';
import ScrollableTooltip from '@/components/common/ScrollableTooltip.vue';
import studyProgramFilterTableMixin from '@/mixins/study-program-filter-table.mixin';

const TABLE_HEADERS = [
  {
    class: 'w-4/12 px-3',
    get label() {
      return t('common.labels.course-title');
    },
    key: 'defaultCourseTitle',
  },
  {
    class: 'w-3/12 px-3',
    get label() {
      return t('common.labels.course-code');
    },
    key: 'courseCode',
  },
  {
    class: 'w-2/12 px-3',
    get label() {
      return t('common.labels.preference');
    },
    key: 'preference',
  },
  {
    class: 'w-3/12 px-3',
    get label() {
      return t('common.labels.study-program');
    },
    key: 'studyPrograms',
  },
];

export default {
  name: 'SelectPreferencesTable',
  components: { ScrollableTooltip, SetCapabilityPreferenceChip },
  mixins: [studyProgramFilterTableMixin],
  props: {
    capabilities: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
  },
  emits: ['preferences-changed'],
  data() {
    return { headerItems: TABLE_HEADERS, selectedPreferences: new Map() };
  },
  computed: {
    transformedPreferences() {
      return Array.from(
        this.selectedPreferences,
        ([capabilityId, preference]) => ({
          capabilityId,
          preference,
        })
      );
    },
  },
  methods: {
    truncateText,
    hasUpdatedPreferences(capabilityId) {
      return this.transformedPreferences.some(
        (course) => course.capabilityId === capabilityId
      );
    },
    findPreferenceValue(course) {
      const hasSelectedPreference = this.hasCourseSelectedPreference(course);
      const isPreferenceUnknown = this.isCoursePreferenceUnknown(course);

      if (hasSelectedPreference || isPreferenceUnknown) {
        return this.getSelectedPreferenceOrDefault(course);
      }

      return course.preference;
    },
    hasCourseSelectedPreference(course) {
      return (
        this.selectedPreferences.get(course.capabilityId)?.value !== undefined
      );
    },
    isCoursePreferenceUnknown(course) {
      return course.preference === PREFERENCES.UNKNOWN.value;
    },
    getSelectedPreferenceOrDefault(course) {
      return (
        this.selectedPreferences.get(course.capabilityId)?.value ||
        DEFAULT_PREFERENCE.value
      );
    },
    updatePreferences(preference, capabilityId) {
      this.selectedPreferences.set(capabilityId, preference);

      this.$emit('preferences-changed', this.transformedPreferences);
    },
  },
};
</script>
