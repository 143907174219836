<template>
  <section class="mt-11">
    <div><slot name="headline" /></div>
    <div autocomplete="off" class="mt-8 grid grid-cols-6 gap-x-6 gap-y-7">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionStructure',
};
</script>
