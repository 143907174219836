import {
  PREFERENCES_CONSTANTS,
  DISALLOWED_PREFERENCES,
} from '@/constants/picklists/preferences';

export const isPreferenceAllowed = (preference) =>
  !DISALLOWED_PREFERENCES.includes(preference.value);

export const isNotCurrentPreference = (currentPreference) => (preference) =>
  preference.text !== currentPreference.text;

export const availableOptions = (currentPreference) =>
  PREFERENCES_CONSTANTS.filter(isPreferenceAllowed).filter(
    isNotCurrentPreference(currentPreference)
  );
