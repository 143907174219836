import { t } from '@/plugins/translations';

export const ASSIGNMENT_TYPES = {
  CURRICULAR: 'CURRICULAR',
  EXTRACURRICULAR: 'EXTRACURRICULAR',
};

export const ASSIGNMENT_TYPE_OPTIONS = Object.freeze([
  {
    key: ASSIGNMENT_TYPES.CURRICULAR,
    get text() {
      return t('common.labels.curricular');
    },
  },
  {
    key: ASSIGNMENT_TYPES.EXTRACURRICULAR,
    get text() {
      return t('common.labels.extracurricular');
    },
  },
]);
