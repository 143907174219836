<template>
  <div class="flex items-center">
    <deputation-tooltip
      :title="
        $t(
          'views.deputation.summarized-deputation-percentage.overall-deputation'
        )
      "
      :deputation-status="summaryOverallDeputationStatus"
      :planned="100"
      :actual="summaryOverallDeputationInPercent"
    >
      <DeputationPercentage
        :deputation-status="summaryOverallDeputationStatus"
        :percentage="summaryOverallDeputationInPercent"
        size="large"
      />
    </deputation-tooltip>

    <n-icon
      class="icon-2xl ml-1.5 cursor-pointer text-primary"
      icon="mdi-information-outline"
      @click="isOpen = true"
    />
    <n-dialog
      v-model:is-open="isOpen"
      :subject="
        $t('views.deputation.summarized-deputation-percentage.depuation-state')
      "
    >
      <template #default>
        <Instructions />
      </template>
      <template #actions>
        <n-button
          class="m-auto"
          variant="primary"
          :disabled="!isOpen"
          @click="isOpen = false"
        >
          {{ $t('views.deputation.summarized-deputation-percentage.accepted') }}
        </n-button>
      </template>
    </n-dialog>
  </div>
  <div class="pt-2 text-base text-blue-600">
    {{
      $t('views.deputation.summarized-deputation-percentage.budget-assigned')
    }}
  </div>
</template>
<script>
import DeputationTooltip from '@/components/deputation/Common/DeputationTooltip.vue';
import Instructions from '@/components/deputation/Common/Instructions.vue';
import DeputationPercentage from '@/components/deputation/Common/DeputationPercentage.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'SummarizedDeputationPercentage',
  components: { DeputationTooltip, Instructions, DeputationPercentage },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters('deputation', [
      'summaryOverallDeputationInPercent',
      'summaryOverallDeputationStatus',
    ]),
  },
};
</script>
