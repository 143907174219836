<template>
  <view-wrapper>
    <n-toolbar>
      <n-button
        variant="quaternary"
        data-test="profile-submit"
        :disabled="!saveButtonEnabled"
        @click="submitForm"
      >
        <n-icon icon="mdi-content-save-outline" class="mr-2" />
        {{ $t('common.labels.save') }}
      </n-button>
    </n-toolbar>
    <ErrorBanner />
    <section-structure>
      <template #headline>
        <div class="flex gap-1">
          <h3 class="font-bold uppercase text-blue-600 text-xl">
            {{ $t('views.manage-university-capabilities.headline') }}
          </h3>
          <n-tooltip
            :content="
              $t('views.manage-university-capabilities.headline-tooltip')
            "
          >
            <n-icon
              :icon="'mdi-information-outline'"
              class="icon-2xl text-gray-800"
            />
          </n-tooltip>
        </div>
      </template>
      <template #default>
        <div class="col-span-12">
          <div class="xl:grid xl:grid-cols-2">
            <div class="col-span-1 mt-3">
              <PresetSelector ref="capabilityPresets" :presets="presets" />
            </div>
            <div class="col-span-2 mt-9">
              <n-button @click="resetCapabilities()">
                {{
                  $t('views.manage-university-capabilities.reset-capabilities')
                }}
              </n-button>
              <AssignCapabilities class="mt-9" />
            </div>
          </div>
        </div>
      </template>
    </section-structure>
  </view-wrapper>
</template>

<script>
import ErrorBanner from '@/components/common/ErrorBanner.vue';
import AssignCapabilities from '@/components/university-capabilities/AssignCapabilities.vue';
import PresetSelector from '@/components/university-capabilities/PresetSelector.vue';
import { UNIVERSITY_CAPABILITIES_BY_PRESETS } from '@/constants/universityCapabilitiesByPresets';
import ViewWrapper from '@/components/common/ViewWrapper.vue';

import { createNamespacedHelpers, mapActions } from 'vuex';
import SectionStructure from '@/components/common/SectionStructure.vue';

const { mapActions: mapCapabilitiesActions, mapGetters: mapCapabilityGetters } =
  createNamespacedHelpers('capabilities');

export default {
  name: 'ManageUniversityCapabilities',
  components: {
    SectionStructure,
    ViewWrapper,
    ErrorBanner,
    AssignCapabilities,
    PresetSelector,
  },
  props: {},
  data: function () {
    return {
      presets: UNIVERSITY_CAPABILITIES_BY_PRESETS,
    };
  },

  computed: {
    ...mapCapabilityGetters([
      'haveCapabilitiesChanged',
      'modifiedCapabilityKeys',
    ]),
    saveButtonEnabled() {
      return this.haveCapabilitiesChanged;
    },
  },
  methods: {
    ...mapActions({
      updateSnackbar: 'snackbar/update',
    }),
    ...mapCapabilitiesActions(['saveCapabilities', 'setInitialCapabilities']),
    resetCapabilities() {
      this.$refs.capabilityPresets.resetSelectedPreset();

      this.setInitialCapabilities();
    },
    async submitForm() {
      try {
        if (this.haveCapabilitiesChanged) {
          await this.saveCapabilities();
        }

        await this.updateSnackbar({
          message: 'The profile has been successfully updated',
        });
      } catch (error) {
        await this.updateSnackbar({
          message: 'An error occurred while updating the profile',
          type: 'error',
        });
      }
    },
  },
};
</script>
