export default {
  props: {
    profiles: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    isValidSearchQuery: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    teachingLevels: {
      type: Array,
      required: true,
    },
    employmentType: {
      type: Array,
      required: true,
    },
    brands: {
      type: Array,
      required: true,
    },
    units: {
      type: Array,
      required: true,
    },
    planningStatus: {
      type: Array,
      required: true,
    },
    preferences: {
      type: Array,
      required: true,
    },
  },
};
