<template>
  <td>
    <p
      v-if="assignment?.capability"
      class="flex gap-3 items-center text-base font-bold"
    >
      <n-icon :icon="getIconByKey(assignment?.capability)" />
      {{ getTextByKey(assignment) }}
    </p>
    <p
      v-else-if="assignment.assignmentCategory"
      class="flex gap-3 items-center text-base font-bold"
    >
      <n-icon icon="mdi-briefcase-variant-outline" />
      {{ convertTranslatedString(assignment.assignmentCategory?.title) }}
    </p>
  </td>
  <td>{{ getTypeTextByKey(assignment?.assignmentType) ?? '-' }}</td>

  <td>
    <div class="col-span-2 flex items-center gap-1">
      <n-icon icon="mdi-calendar-blank-outline" />
      <span class="text-gray-800 text-base">{{
        formatDate(assignment.assignmentStartDate)
      }}</span>
      <n-icon icon="mdi-arrow-right-thin" class="icon-md" />
      <span v-if="assignment.assignmentEndDate" class="text-gray-800 text-base">
        {{ formatDate(assignment.assignmentEndDate) }}
      </span>
      <n-icon v-else icon="mdi-infinity" class="text-gray-800" />
    </div>
  </td>

  <td>
    <p>
      <span class="font-bold">{{ assignment.weeklyScheduledHours }}</span>
      {{
        $t(
          'views.deputation.assignment-table-row.scheduled_weekly_hours_options',
          assignment.weeklyScheduledHours
        )
      }}
    </p>
  </td>
  <td>
    <assignment-status-chip :state="assignment.state" />
  </td>
  <td>
    <n-tooltip
      :content="
        !isManualAssignment(assignment) &&
        $t(
          'views.deputation.assignment-table-row.tooltip.assignment-uneditable-here',
          assignment.dailyScheduledHours
        )
      "
    >
      <n-button
        variant="quaternary"
        :disabled="!isManualAssignment(assignment)"
        @click="$emit('deleteAssignment', assignment.id)"
      >
        <n-icon
          :class="
            isManualAssignment(assignment) ? 'text-black' : 'text-gray-600'
          "
          icon="mdi-delete-outline"
        />
      </n-button>
    </n-tooltip>
  </td>
</template>

<script>
import { formatDate } from '@/utils/assignment-dates-formatter';
import {
  CAPABILITIES,
  ASSIGNMENT_TYPE_OPTIONS as ASSIGNMENT_TYPES,
  getIconByKey,
  getTextByKey,
} from '@/constants/picklists';
import AssignmentStatusChip from '@/components/deputation/Assignments/AssignmentStatusChip.vue';
import { convertTranslatedString } from '@/utils/translated-string';
import { getTextByCapability } from '@/constants/picklists/capabilities';

export default {
  name: 'AssignmentTableRow',
  components: { AssignmentStatusChip },
  props: {
    assignment: {
      type: Object,
      required: true,
      validator(obj) {
        return [
          'assignmentStartDate',
          'assignmentEndDate',
          'referenceType',
          'referenceSubType',
          'state',
        ].every((key) => Object.prototype.hasOwnProperty.call(obj, key));
      },
    },
  },
  emits: ['deleteAssignment'],
  methods: {
    convertTranslatedString,
    getTextByKey(assignment) {
      return getTextByCapability({
        capability: assignment?.capability,
        assignmentCategoryTitle: assignment.assignmentCategory?.title,
      });
    },
    getTypeTextByKey(key) {
      return getTextByKey(ASSIGNMENT_TYPES, key);
    },
    getIconByKey(key) {
      return getIconByKey(CAPABILITIES, key);
    },
    isManualAssignment() {
      return this.assignment.referenceType === 'ACADEMIC-STAFF';
    },
    formatDate,
  },
};
</script>
