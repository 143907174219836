<template>
  <div>
    <contacts-box
      :contacts="emails"
      :contact-types="contactTypes"
      :actions="actions"
      :tags="tags"
      :disabled="disabled"
      :section-title="$t('views.profiles-emails.section-title')"
      :add-button-label="$t('views.profiles-emails.add-button-label')"
      :empty-text="$t('views.profiles-emails.empty-text')"
      data-test="profile-emails"
      @click-create="clickCreate"
      @click-update="clickUpdate"
      @click-delete="clickDelete"
      @click-set-as-primary="setAsPrimary"
    >
      <template #data="{ contact }">
        <p class="mt-1">
          {{ contact.email }}
        </p>
      </template>
    </contacts-box>
    <portal to="email-create">
      <email-form
        :model-value="{ primary: !emails.length }"
        :title="$t('views.profiles.email.modal-title-add')"
        @submit="submitCreate"
        @close="close"
      />
    </portal>
    <portal to="email-update">
      <template #default="{ email }">
        <email-form
          :model-value="email"
          :title="$t('views.profiles.email.modal-title-add')"
          @submit="submitUpdate"
          @close="close"
        />
      </template>
    </portal>
  </div>
</template>

<script>
import { t } from '@/plugins/translations';
import ContactsBox from '@/components/profiles/ContactsBox';
import EmailForm from '@/components/profiles/Emails/EmailForm';
import { EMAIL_TYPES } from '@/constants/picklists';

export default {
  name: 'Emails',
  components: { ContactsBox, EmailForm },
  props: {
    emails: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update-contact',
    'create-contact',
    'set-as-primary-contact',
    'open-contact-delete',
    'open-contact-update',
    'open-contact-create',
    'close',
  ],
  computed: {
    contactTypes() {
      return EMAIL_TYPES;
    },
  },
  methods: {
    tags(email) {
      return email.primary ? ['Korrespondenz'] : [];
    },
    actions(email) {
      return [
        {
          id: 'click-update',
          name: 'click-update',
          get text() {
            return t('common.labels.edit');
          },
          disabled: false,
        },
        {
          id: 'click-delete',
          name: 'click-delete',
          get text() {
            return t('common.labels.delete');
          },
          disabled: email.primary || this.emails.length <= 1,
        },
        {
          id: 'click-set-as-primary',
          name: 'click-set-as-primary',
          get text() {
            return t('views.profiles.set-as-correspondence');
          },
          disabled: !!email.primary,
        },
      ];
    },
    close() {
      this.$emit('close');
    },
    clickCreate() {
      this.$emit('open-contact-create', 'emails');
    },
    clickUpdate(payload) {
      this.$emit('open-contact-update', 'emails', payload);
    },
    clickDelete(payload) {
      this.$emit('open-contact-delete', 'emails', payload);
    },
    setAsPrimary(payload) {
      this.$emit('set-as-primary-contact', 'emails', payload);
    },
    submitCreate(payload) {
      this.$emit('create-contact', 'emails', payload);
    },
    submitUpdate(payload) {
      this.$emit('update-contact', 'emails', payload);
    },
  },
};
</script>
