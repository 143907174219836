<template>
  <view-wrapper>
    <LocationToolbar
      :has-locations="hasAssignedLocations"
      @open-assign-location-modal="openUnitModal"
      @open-preferences-modal="openPreferencesModal"
      @open-edit-main-location-model="openEditMainLocationModel"
    />
    <ErrorBanner />
    <div class="text-2xl font-bold text-blue-600 uppercase mt-10 mb-4">
      {{ $t('views.location.campus-management.main-unit-and-campus') }}
    </div>
    <p
      v-if="!hasNoMainLocationAssigned"
      class="text-base font-normal text-blue-600"
    >
      {{
        $t('views.location.campus-management.main-unit-and-campus-description')
      }}
    </p>
    <empty-state v-if="hasNoMainLocationAssigned">
      <template #subheading>
        {{
          $t(
            'views.location.campus-management.main-unit-and-campus-empty-state-title'
          )
        }}
      </template>
      <template #description>
        {{
          $t(
            'views.location.campus-management.main-unit-and-campus-empty-state-description'
          )
        }}
      </template>
    </empty-state>
    <div v-else class="flex w-6/12 gap-6 mt-10">
      <div class="relative w-full">
        <n-select
          :model-value="assignedMainUnit"
          class="grow"
          :disabled="true"
          :label="$t('common.labels.unit')"
        />

        <loading-spinner
          v-if="isLoadingMainLocation"
          :spinner-text="
            $t(
              'views.location.campus-management.main-unit-and-campus-syncing-text'
            )
          "
        />
      </div>
      <div class="relative w-full">
        <n-select
          :model-value="assignedMainCampus"
          class="grow"
          :disabled="true"
          :label="$t('common.labels.campus')"
        />

        <loading-spinner
          v-if="isLoadingMainLocation"
          :spinner-text="
            $t(
              'views.location.campus-management.main-unit-and-campus-syncing-text'
            )
          "
        />
      </div>
    </div>
    <div class="text-2xl font-bold text-blue-600 uppercase mt-16 mb-4">
      {{ $t('views.location.campus-management.assigned-units-and-campuses') }}
    </div>
    <empty-state v-if="!hasAssignedLocations" class="mt-16">
      <template #subheading>{{
        $t('views.location.campus-management.empty-subheading-unit')
      }}</template>
      <template #description>
        {{ $t('views.location.campus-management.empty-description-unit') }}
      </template>
      <template #actions>
        <n-tooltip :content="assignUnitTooltipText">
          <n-button
            class="mt-6"
            variant="primary"
            :disabled="!hasPermissionToAssignLocations"
            @click="openUnitModal"
          >
            <n-icon icon="plus" class="mr-2" />
            {{ $t('views.location.assign-unit') }}
          </n-button>
        </n-tooltip>
      </template>
    </empty-state>
    <section-structure v-else>
      <template #default>
        <div
          v-if="isLoading"
          class="col-span-full flex flex-col items-center mt-12"
        >
          <n-simple-loader />
        </div>
        <div
          v-else
          class="col-span-full grid gap-4 h-full"
          :class="gridContainerClass"
        >
          <location-grid-item
            v-for="item in assignedUnitsAndCampuses"
            :key="item.unit"
            :unit="item.unit"
            :campuses="item.campuses"
            @open-delete-confirmation="
              (unitName) => {
                openDeleteConfirmationModal(unitName);
              }
            "
          />
          <location-empty-grid-item
            v-if="canAddMoreUnits"
            @show-assign-unit-modal="openUnitModal"
          />
        </div>
      </template>
    </section-structure>
    <div class="text-2xl font-bold text-blue-600 uppercase mt-16 mb-4">
      {{ $t('views.location.campus-management.campus-preferences') }}
    </div>
    <p
      class="text-base font-normal text-blue-600"
      :class="updateMetadataAvailable ? 'mb-6' : 'mb-10'"
    >
      {{
        $t('views.location.campus-management.campus-preferences-description')
      }}
    </p>
    <location-preferences-last-edited
      v-if="updateMetadataAvailable"
      class="mb-10"
      :updated-by="lastUpdatedPreferencesMetadata.lastUpdatedBy"
      :updated-at="lastUpdatedPreferencesMetadata.lastUpdatedAt"
    />
    <assigned-location-preferences-table
      v-if="assignedLocationPreferences.length > 0"
      :locations="assignedLocationPreferences"
      :is-loading="isLoadingPreferences || isSavingPreferences"
    />
    <empty-state v-else class="mt-16">
      <template #subheading>
        {{
          $t(
            'views.location.campus-management.empty-subheading-campuspreference'
          )
        }}
      </template>
      <template #description>
        {{
          $t(
            'views.location.campus-management.empty-description-campuspreference'
          )
        }}
      </template>
      <template #actions>
        <n-tooltip :content="preferencesButtonTooltipText">
          <n-button
            class="mt-6"
            variant="primary"
            :disabled="preferencesButtonDisabled"
            @click="openPreferencesModal"
          >
            <n-icon icon="pencil-outline" class="mr-2" />
            {{ $t('views.location.edit-campuspreferences') }}
          </n-button>
        </n-tooltip>
      </template>
    </empty-state>
    <assign-unit-and-campus-modal
      :is-open="unitModalOpen"
      :selected-unit="selectedUnit"
      :possible-campuses="possibleCampuses"
      :possible-units="possibleUnits"
      :is-loading="isLoadingPossibleCampuses"
      @update-selected-unit="updateSelectedUnitAndGetPossibleCampuses"
      @close="closeUnitModal"
      @save="assignLocationToProfile"
    />
    <update-main-location-modal
      :is-open="mainLocationModalOpen"
      :assigned-main-campus="assignedMainCampus"
      :assigned-main-unit="assignedMainUnit"
      @close="closeEditMainLocationModal"
    />
    <delete-location-confirmation-modal
      :is-open="deleteConfirmationOpen"
      :unit="selectedUnitToDelete"
      @close="closeDeleteConfirmationModal"
      @on-delete-confirmed="onDeleteConfirmed"
    />
    <location-preferences-modal
      ref="locationPreferencesModal"
      @preferences-updated="handlePreferencesUpdated"
    />
  </view-wrapper>
</template>

<script>
import SectionStructure from '@/components/common/SectionStructure.vue';
import ErrorBanner from '@/components/common/ErrorBanner.vue';
import ViewWrapper from '@/components/common/ViewWrapper.vue';
import LocationEmptyGridItem from '@/components/location/LocationEmptyGridItem.vue';
import LocationGridItem from '@/components/location/LocationGridItem.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DeleteLocationConfirmationModal from '@/components/location/DeleteLocationConfirmationModal.vue';
import AssignUnitAndCampusModal from '@/components/location/AssignUnitAndCampusModal.vue';
import EmptyState from '@/components/common/EmptyState.vue';
import LocationToolbar from '@/components/location/LocationToolbar.vue';
import LocationPreferencesLastEdited from '@/components/location/LocationPreferencesLastEdited.vue';
import { hasPermissionFor } from '@/utils/permissionUtil';
import LocationPreferencesModal from '@/components/location/LocationPreferencesModal.vue';
import AssignedLocationPreferencesTable from '@/components/location/AssignedLocationPreferencesTable.vue';
import UpdateMainLocationModal from '@/components/location/UpdateMainLocationModal.vue';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';

export default {
  name: 'LocationManagement',
  components: {
    LoadingSpinner,
    UpdateMainLocationModal,
    AssignedLocationPreferencesTable,
    LocationPreferencesLastEdited,
    LocationPreferencesModal,
    LocationToolbar,
    AssignUnitAndCampusModal,
    DeleteLocationConfirmationModal,
    LocationGridItem,
    LocationEmptyGridItem,
    SectionStructure,
    ErrorBanner,
    ViewWrapper,
    EmptyState,
  },
  data() {
    return {
      syncingInterval: null,
      syncingTimeout: 5,
    };
  },
  computed: {
    ...mapGetters('location', [
      'profileId',
      'possibleUnits',
      'assignedUnitsAndCampuses',
      'selectedUnit',
      'possibleCampuses',
      'isLoading',
      'selectedUnitToDelete',
      'deleteConfirmationOpen',
      'unitModalOpen',
      'isLoadingPossibleCampuses',
    ]),
    ...mapGetters('locationPreferences', [
      'assignedLocationPreferences',
      'assignedPagination',
      'lastUpdatedPreferencesMetadata',
      'isMetadataLoaded',
      'isLoadingPreferences',
      'isSavingPreferences',
    ]),
    ...mapGetters('mainLocation', [
      'mainLocationModalOpen',
      'assignedMainUnit',
      'assignedMainCampus',
      'isLoadingMainLocation',
    ]),
    routeQuery() {
      return this.$route.query;
    },
    threeRowGrid() {
      const availableUnits = this.canAddMoreUnits ? 1 : 0;

      return (this.assignedUnitsAndCampuses.length + availableUnits) % 3 === 0;
    },
    gridContainerClass() {
      return this.threeRowGrid ? 'lg:grid-cols-3' : 'lg:grid-cols-2';
    },
    canAddMoreUnits() {
      return this.possibleUnits.length !== 0;
    },
    hasAssignedLocations() {
      return this.assignedUnitsAndCampuses.length > 0;
    },
    updateMetadataAvailable() {
      return !!(
        this.lastUpdatedPreferencesMetadata?.lastUpdatedAt &&
        this.lastUpdatedPreferencesMetadata?.lastUpdatedBy
      );
    },
    hasPermissionToAssignLocations() {
      return hasPermissionFor('academic-staff', ['u']);
    },
    preferencesButtonDisabled() {
      return !this.hasPermissionToAssignLocations || !this.hasAssignedLocations;
    },
    preferencesButtonTooltipText() {
      if (!this.hasPermissionToAssignLocations) {
        return 'Sie haben keine Berechtigung, Campuspräferenzen zu bearbeiten';
      } else if (!this.hasAssignedLocations) {
        return 'Campuspräferenzen können erst angegeben werden, sobald Unit & Campus Zuordnungen gesetzt wurden.';
      }
      return '';
    },
    assignUnitTooltipText() {
      return !this.hasPermissionToAssignLocations
        ? 'Sie haben keine Berechtigung, Units & Campus hinzuzufügen'
        : '';
    },
    hasNoMainLocationAssigned() {
      return this.assignedMainUnit === null && this.assignedMainCampus === null;
    },
  },
  watch: {
    routeQuery: {
      deep: true,
      immediate: true,
      handler: 'handleRouteQuery',
    },
  },
  methods: {
    ...mapMutations('location', ['setCurrentUnit']),
    ...mapActions('location', [
      'fetchAndStorePossibleUnits',
      'fetchAndStoreAssignedUnitsAndCampuses',
      'fetchPossibleCampusesAndOpenModal',
      'deleteAssignedCampusByProfileIdAndUnitName',
      'openDeleteConfirmationModal',
      'closeDeleteConfirmationModal',
      'assignLocationToProfile',
      'openUnitModal',
      'closeUnitModal',
      'updateSelectedUnitAndGetPossibleCampuses',
    ]),
    ...mapActions('locationPreferences', [
      'getAssignedLocations',
      'getLastUpdatedPreferencesMetadata',
    ]),
    ...mapActions('mainLocation', [
      'openMainLocationModal',
      'closeMainLocationModal',
    ]),
    async onDeleteConfirmed(unitName) {
      await this.deleteAssignedCampusByProfileIdAndUnitName(unitName);
    },
    async openPreferencesModal() {
      await this.$refs.locationPreferencesModal.openModal(this.profileId);
    },
    openEditMainLocationModel() {
      this.openMainLocationModal();
    },
    closeEditMainLocationModal() {
      this.closeMainLocationModal();
    },
    async handlePreferencesUpdated() {
      await this.reloadAssignedCampuses();
      await this.getLastUpdatedPreferencesMetadata(this.profileId);
    },
    async reloadAssignedCampuses() {
      await this.getAssignedLocations({
        limit: this.assignedPagination.limit,
        offset: 0,
      });
    },
    async handleRouteQuery(query) {
      const { limit, offset } = query;

      if (!limit || !offset) {
        this.$router.replace({
          ...this.$route,
          query: {
            limit: this.assignedPagination.limit,
            offset: this.assignedPagination.offset,
          },
        });

        return;
      }

      await this.getLastUpdatedPreferencesMetadata(this.profileId);

      await this.getAssignedLocations({
        ...query,
      });
    },
  },
};
</script>
