import { Api } from '@/api';
import { t } from '@/plugins/translations';

const DEFAULT_PAGINATION = {
  limit: 4,
  offset: 0,
};

const initialState = () => ({
  assignedCapabilities: [],
  pagination: null,
  isSaving: false,
  isLoading: false,
  selectedStudyPrograms: [],
  searchTerm: '',
});

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    isSaving: (state) => state.isSaving,
    isLoading: (state) => state.isLoading,
    assignedCapabilities: (state) => state.assignedCapabilities,
    pagination: (state) => state.pagination,
    searchParams: (state) => ({
      studyPrograms: state.selectedStudyPrograms,
      searchTerm: state.searchTerm,
      amount: state.pagination?.limit || DEFAULT_PAGINATION.limit,
      startFrom: state.pagination?.offset || DEFAULT_PAGINATION.offset,
    }),
    searchTerm: (state) => state.searchTerm,
    selectedStudyPrograms: (state) => state.selectedStudyPrograms,
    currentProfileId: (state, getters, rootState, rootGetters) =>
      rootGetters['puffer/currentProfile']?.id,
    appliedSearchFilters: (state) => {
      const hasSearchTerm =
        state.searchTerm !== '' && state.searchTerm !== null;
      const hasSelectedStudyPrograms = state.selectedStudyPrograms.length > 0;

      const filters = [];

      if (hasSearchTerm) {
        filters.push({
          label: t('common.labels.name'),
          value: state.searchTerm,
          key: 'searchTerm',
        });
      }

      if (hasSelectedStudyPrograms) {
        filters.push({
          label: t('common.labels.study-program'),
          value: state.selectedStudyPrograms,
          key: 'studyProgramsFilter',
        });
      }

      return filters;
    },
  },
  actions: {
    searchAssignedCapabilities: async ({ commit, dispatch, getters }) => {
      commit('isLoading', true);

      try {
        const { searchParams, currentProfileId } = getters;

        const response = await Api.courseCapabilities.searchCapabilities(
          currentProfileId,
          searchParams
        );

        commit('setAssignedCapabilities', response.data);
        commit('setPagination', response.pagination);
      } catch (error) {
        await dispatch('errors/pushError', error, { root: true });
      } finally {
        commit('isLoading', false);
      }
    },
    savePreferenceUpdates: async (
      { commit, dispatch, getters },
      preferences
    ) => {
      commit('setSavingStatus', true);

      try {
        const mappedPreferences = mapPreferences(preferences);

        const { currentProfileId } = getters;

        await Api.courseCapabilities.setPreferences(currentProfileId, {
          preferences: mappedPreferences,
        });
        await dispatch(
          'snackbar/update',
          { message: 'Kurspräferenzen wurden gespeichert' },
          {
            root: true,
          }
        );
      } catch (error) {
        await dispatch('errors/pushError', error, { root: true });
      } finally {
        commit('setSavingStatus', false);
      }
    },
    async updateSearchTerm({ commit, dispatch }, searchTerm) {
      commit('setSearchTerm', searchTerm);

      await dispatch('searchAssignedCapabilities');
    },
    async updateStudyProgramsFilter({ commit, dispatch }, studyPrograms) {
      commit('setSelectedStudyPrograms', studyPrograms);

      await dispatch('searchAssignedCapabilities');
    },
    async updatePagination({ commit, dispatch }, pagination) {
      commit('setPagination', pagination);

      await dispatch('searchAssignedCapabilities');
    },
    async resetModal({ commit }) {
      commit('setAssignedCapabilities', []);
      commit('setPagination', DEFAULT_PAGINATION);
      commit('setSearchTerm', '');
      commit('setSelectedStudyPrograms', []);
    },
    async resetSearch({ commit, dispatch }) {
      commit('setSelectedStudyPrograms', []);
      commit('setPagination', DEFAULT_PAGINATION);

      await dispatch('searchAssignedCapabilities');
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setSavingStatus(state, isSaving) {
      state.isSaving = isSaving;
    },
    isLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setAssignedCapabilities(state, assignedCapabilities) {
      state.assignedCapabilities = assignedCapabilities;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setSearchTerm(state, searchTerm) {
      state.searchTerm = searchTerm;
    },
    setSelectedStudyPrograms(state, studyPrograms) {
      state.selectedStudyPrograms = studyPrograms;
    },
  },
};

function mapPreferences(preferences = []) {
  return preferences.map((preference) => ({
    id: preference.capabilityId,
    preference: preference.preference.value,
  }));
}
