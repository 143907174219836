import { Api } from '@/api';
import { store } from '@/store';
import { hasPermissionFor } from '@/utils/permissionUtil';

const isContactInStore = store.getters['profile/isContact'];
const getContactFromStore = store.getters['profile/getContact'];

const getProfileInStore = store.getters['puffer/currentProfile'];

export const createContactGuard = async (to, from, next) => {
  const contactId = to.query.contactId;

  // This only triggers, when the page is refreshed (!from.name = true) and we are on the profile create page
  if (
    (!from.name && to.name.includes('profile-create')) ||
    !isContactInStore(contactId)
  ) {
    return next({ name: 'profile-create' });
  } else {
    const contact = getContactFromStore(contactId);

    to.params.contactId = contactId;
    await store.dispatch('profile/updateContact', contact);

    return next();
  }
};

export const createContactFieldGuard = async (to, from, next) => {
  await injectProfileData(to);

  next();
};

export const updateContactGuard = async (to, _from, next) => {
  await injectProfileData(to);

  if (!store.getters['puffer/currentContact']) {
    next({ name: 'profile-update', params: to.params });
  } else {
    next();
  }
};
const profileGuardWrapper = async (to, _from, next, action) => {
  try {
    await action(to);
  } catch (error) {
    if (error.statusCode === 404) {
      to.params.errorType = 'notFound';
      to.meta.headline = 'Profil nicht gefunden';
    }
    if (error.statusCode === 403) {
      to.params.errorType = 'forbidden';
      to.meta.headline = 'Kein Zugriff';
    }
    to.params.error = new Error(error.message);
  }
  next();
};

export const profileGuard = async (to, _from, next) => {
  await profileGuardWrapper(to, _from, next, injectProfileData);
};

export const deputationGuard = async (to, _from, next) => {
  await profileGuardWrapper(to, _from, next, injectDeputationData);
};

export const locationGuard = async (to, _from, next) => {
  await profileGuardWrapper(to, _from, next, injectLocationData);
};

export const capabilitiesGuard = async (to, _from, next) => {
  await profileGuardWrapper(to, _from, next, injectCapabilitiesData);
};

export const courseCapabilitiesGuard = async (to, _from, next) => {
  await profileGuardWrapper(to, _from, next, injectCourseCapabilitiesData);
};

export const beforeEnterPermissionGuard = (resourcePath, actions, next) => {
  if (!hasPermissionFor(resourcePath, actions)) {
    return next({ name: 'forbidden' });
  }

  return next();
};

export const resetStateOnProfileChange = async () => {
  await store.dispatch('capabilities/resetState');
  await store.dispatch('overallCapacity/resetState');
  await store.dispatch('courseCapabilities/resetState');
  await store.dispatch('profile/resetState');
  await store.dispatch('assignments/resetState');
  await store.dispatch('deputation/resetState');
  await store.dispatch('location/resetState');
};

const initializeDeputationStore = async (profileId) => {
  await store.dispatch('deputation/setProfile', profileId);
  await store.dispatch('deputation/fetchDeputationAvailable');
};

const injectMainLocationData = async (profileId) => {
  await store.dispatch('mainLocation/setProfileId', profileId);
  await store.dispatch('mainLocation/fetchMainLocationOfProfile', profileId);
  await store.dispatch('mainLocation/fetchPossibleUnits', profileId);
};

const initializeLocationStore = async (profileId) => {
  await store.dispatch('location/setProfileId', profileId);
  await store.dispatch('location/fetchAndStorePossibleUnits', profileId);
  await store.dispatch(
    'location/fetchAndStoreAssignedUnitsAndCampuses',
    profileId
  );
};

const initializeCampusPreferencesStore = async (profileId) => {
  await store.dispatch('locationPreferences/setProfileId', profileId);
  await store.dispatch('locationPreferences/getAssignedLocations', {});
  await store.dispatch(
    'locationPreferences/getLastUpdatedPreferencesMetadata',
    profileId
  );
};

const initializeCapabilitiesStore = async (profileId, data) => {
  await Promise.all([
    store.dispatch('capabilities/updateCurrentlySelectedProfileId', profileId),
    store.dispatch('overallCapacity/setCurrentProfile', data),
  ]);
};

const initializeCourseCapabilitiesStore = async (profileId) => {
  await store.dispatch('courseCapabilities/updateCurrentProfileId', profileId);
};

const loadProfileData = async (profileId) => {
  const { request } = Api.profiles.getById(profileId);

  const { data } = await request;

  return data;
};
const initializeProfileBuffer = async (data) => {
  await store.dispatch('puffer/updateStoreProfile', data);
};

const initializeContactBuffer = async (to, data) => {
  const { addresses, emails, phones } = data;

  await store.dispatch(
    'puffer/updateStoreContact',
    [...addresses, ...emails, ...phones].find(
      (item) => item.id === to.params.contactId
    )
  );
};

const injectDeputationData = async (to) => {
  const profileId = to.params.profileId;

  const data = await injectProfileData(to);

  await Promise.all([
    initializeCapabilitiesStore(profileId, data),
    initializeDeputationStore(profileId),
  ]);
};

const injectLocationData = async (to) => {
  const profileId = to.params.profileId;
  await injectProfileData(to);
  await injectMainLocationData(profileId);
  await initializeLocationStore(profileId);
  await initializeCampusPreferencesStore(profileId);
};

const injectCourseCapabilitiesData = async (to) => {
  const profileId = to.params.profileId;
  await injectProfileData(to);

  await initializeCourseCapabilitiesStore(profileId);
};
const injectCapabilitiesData = async (to) => {
  const profileId = to.params.profileId;

  const data = await injectProfileData(to);

  await initializeCapabilitiesStore(profileId, data);
};

const injectProfileData = async (to) => {
  if (!(!getProfileInStore || !Object.keys(getProfileInStore).length)) {
    return;
  }

  const data = await loadProfileData(to.params.profileId);

  await initializeProfileBuffer(data);

  if (to.params.contactId) {
    await initializeContactBuffer(to, data);
  }

  return data;
};
