import { ApiClient } from '@/api/ApiClient';
import { PROFILES_BASE_ROUTE } from '@/api/profiles';

const ASSIGNMENTS_ROUTE = 'assignments';

export const assignments = {
  create: (profileId, data) =>
    ApiClient.instance.post(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${ASSIGNMENTS_ROUTE}`,
      data
    ),
  getByProfileId: (profileId, payload) =>
    ApiClient.instance.get(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${ASSIGNMENTS_ROUTE}`,
      {
        params: { ...payload },
      }
    ),
  getAssignmentCategories: () =>
    ApiClient.instance.get(`/${ASSIGNMENTS_ROUTE}/assignment-categories`),
  delete: (profileId, assignmentId) =>
    ApiClient.instance.delete(
      `/${PROFILES_BASE_ROUTE}/${profileId}/${ASSIGNMENTS_ROUTE}/${assignmentId}`
    ),
};
