import { ContactsResourceFactory } from '@/api/ApiResourceFactory';

const _emails = ContactsResourceFactory('emails');

export const emailTransform = ({ primary = false, type, email }) => ({
  primary,
  type,
  email,
});

export const emails = {
  ..._emails,
  updateById: (id, payload) => _emails.updateById(id, emailTransform(payload)),
};
