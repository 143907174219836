<template>
  <n-menu>
    <template #activator="{ toggle: openMenu, isOpen: isMenuOpen }">
      <n-chip :color="model.chipColor" :is-open="isMenuOpen" @click="openMenu">
        {{ model.text }}
      </n-chip>
    </template>
    <template #default="{ close: closeMenu }">
      <n-menu-list class="-ml-3 px-3 py-2 space-y-1">
        <li v-for="option in options" :key="option.value">
          <n-chip
            :color="option.chipColor"
            @click="selectOption(option, closeMenu)"
          >
            {{ option.text }}
          </n-chip>
        </li>
      </n-menu-list>
    </template>
  </n-menu>
</template>
<script>
export default {
  name: 'ChipDropdown',
  props: {
    options: {
      type: Array, // <{ chipColor: string, value: string, text: string }[]>
      required: true,
    },
    modelValue: {
      type: Object, // <{ chipColor: string, value: string, text: string }>
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    selectOption(option, closeMenu) {
      this.model = option;
      closeMenu();
    },
  },
};
</script>
