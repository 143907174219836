<template>
  <n-modal
    :is-open="isModalOpen"
    :title="$t('views.location.campus-preferences-modal.title')"
    class="relative"
    :large-deprecated="true"
    @close="closeModal"
  >
    <div class="mb-9">
      <location-preferences-hint />
    </div>
    <div class="mb-9">
      <n-input
        v-model="searchTerm"
        icon="magnify"
        :label="$t('views.location.campus-preferences-modal.search-for-campus')"
        @update:model-value="handleSearch"
      />
    </div>
    <div class="mb-9">
      <SearchFilter :filters="searchFilters" @reset="resetSearchFilters" />
    </div>
    <div class="mb-9">
      <select-location-preferences-table
        :is-loading="isLoadingAssignableLocationPreferences"
        :locations="assignableLocationPreferences"
        :main-location="assignedMainLocation"
        @preferences-changed="updatePreferences"
      />
    </div>
    <div class="mb-9">
      <n-pagination
        v-model="localPagination"
        :limit-options="[]"
        @update:model-value="updatePaginationParams"
      />
    </div>

    <div class="flex justify-end">
      <n-button
        variant="primary"
        :disabled="isSaveDisabled"
        @click="handleSavePreferences"
      >
        {{
          $t('views.location.campus-preferences-modal.save-campus-preferences')
        }}
      </n-button>
    </div>
  </n-modal>
</template>
<script>
import { t } from '@/plugins/translations';
import SearchFilter from '@/components/common/SearchFilter.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { debounce } from '@/utils/debounce';
import LocationPreferencesHint from '@/components/location/LocationPreferencesHint.vue';
import SelectLocationPreferencesTable from '@/components/location/SelectLocationPreferencesTable.vue';

export default {
  name: 'LocationPreferencesModal',
  components: {
    SelectLocationPreferencesTable,
    LocationPreferencesHint,
    SearchFilter,
  },
  emits: ['preferences-updated'],
  data() {
    return {
      isModalOpen: false,
      selectedProfileId: null,
      searchFilters: [],
      selectedPreferences: [],
      localPagination: {},
      searchTerm: null,
      queryParams: { searchTerm: '' },
    };
  },
  computed: {
    ...mapGetters('locationPreferences', [
      'assignableLocationPreferences',
      'isSavingPreferences',
      'isLoadingAssignableLocationPreferences',
      'assignablePagination',
      'assignedLocationPreferences',
    ]),
    ...mapState('mainLocation', ['assignedMainUnit', 'assignedMainCampus']),
    assignedMainLocation() {
      return this.assignedMainUnit
        ? {
            unit: this.assignedMainUnit,
            campus: this.assignedMainCampus,
          }
        : null;
    },
    isSaveDisabled() {
      return (
        this.isSavingPreferences ||
        this.isLoadingAssignableLocationPreferences ||
        (!this.hasChanged && this.profileHasLocationsPreferences)
      );
    },
    hasChanged() {
      return this.selectedPreferences.length > 0;
    },
    profileHasLocationsPreferences() {
      return this.assignedLocationPreferences.length > 0;
    },
  },
  watch: {
    assignablePagination: {
      deep: true,
      handler(newVal) {
        this.localPagination = newVal;
      },
    },
    queryParams: {
      deep: true,
      handler(value) {
        this.setSearchFilters(value);
        this.setDefaultSearchTerm();

        const { limit, offset, searchTerm } = value;

        this.fetchCampusesForAssignments({
          limit,
          offset,
          searchTerm,
        });
      },
    },
  },
  methods: {
    ...mapActions('locationPreferences', {
      fetchLocations: 'searchAssignableLocations',
      saveLocations: 'savePreferenceUpdates',
    }),
    async openModal(selectedProfileId) {
      this.isModalOpen = true;

      this.selectedProfileId = selectedProfileId;

      this.selectedPreferences = [];

      this.resetSearchFilters();

      await this.fetchCampusesForAssignments({
        limit: 4,
        offset: 0,
      });
    },
    closeModal() {
      this.isModalOpen = false;
    },
    setSearchFilters(queryParams) {
      this.searchFilters = queryParams.searchTerm
        ? [
            {
              value: queryParams.searchTerm,
              get label() {
                return t('common.labels.search');
              },
            },
          ]
        : [];
    },
    setDefaultSearchTerm: function () {
      if (this.queryParams.searchTerm) {
        return;
      }

      this.searchTerm = '';
    },
    resetSearchFilters() {
      Object.assign(this.queryParams, { searchTerm: '', offset: 0 });
    },
    updatePaginationParams({ limit, offset }) {
      Object.assign(this.queryParams, { limit, offset });
    },
    handleSearch: debounce(function (searchTerm) {
      Object.assign(this.queryParams, { searchTerm: searchTerm, offset: 0 });
    }, 500),
    updatePreferences(newPreferences) {
      this.selectedPreferences = newPreferences;
    },
    async handleSavePreferences() {
      await this.saveLocations({
        profileId: this.selectedProfileId,
        preferences: this.selectedPreferences,
      });

      this.closeModal();

      this.$emit('preferences-updated');
    },
    fetchCampusesForAssignments(params) {
      if (this.isLoadingAssignableLocationPreferences) {
        return;
      }

      this.fetchLocations({
        ...params,
        includeUnknown: true,
      });
    },
  },
};
</script>
