<template>
  <validation-form v-slot="{ meta }" :initial-values="initialValues">
    <div class="flex flex-col gap-y-8">
      <div class="w-1/2">
        <validation-provider
          v-slot="{ errors, field }"
          vid="emailType"
          name="emailType"
          :label="$t('views.profiles.emails.email-form.email-type')"
          rules="required"
        >
          <n-select
            id="email-form__type"
            v-model="type"
            :errors="errors"
            v-bind="field"
            :options="EMAIL_TYPES"
            :label="$t('views.profiles.emails.email-form.email-type')"
            option-label="text"
            track-by="key"
            mapped
            data-test="email-form-type"
            required
          />
        </validation-provider>
      </div>
      <div class="col-span-full relative">
        <validation-provider
          v-slot="{ errors, field }"
          name="email"
          :label="$t('common.labels.email')"
          :rules="`required|max:254|${isTypeIU ? 'iu_email' : 'email'}`"
        >
          <n-input
            id="email-form__email"
            v-model="email"
            name="email"
            :errors="errors"
            v-bind="field"
            :disabled="!type"
            :label="$t('common.labels.email')"
            required
          />
        </validation-provider>
        <div v-if="isTypeIU" class="iu-email-domain">@iu.org</div>
      </div>
      <div class="col-span-full">
        <div class="text-caption italic text-gray-700">
          * {{ $t('common.labels.required') }}
        </div>
      </div>
      <div v-if="modelValue.updatedAt" class="col-span-full">
        <p class="italic text-gray-700">
          Zuletzt geändert am
          {{ formatTimeStampToLocale(value.updatedAt) }} von
          {{ value.updatedBy }}
        </p>
      </div>
      <div class="col-span-full flex h-9">
        <n-button
          :disabled="!meta.valid || !meta.dirty || !hasChanged"
          variant="primary"
          class="mt-18 ml-auto"
          data-test="email-form-submit"
          @click="submit"
        >
          <n-icon icon="mdi-content-save-outline" class="mr-2" />
          {{ $t('common.labels.save') }}
        </n-button>
      </div>
    </div>
  </validation-form>
</template>

<script>
import { formatTimeStampToLocale } from '@/utils/formatters';
import { EMAIL_TYPES } from '@/constants/picklists';
import { IU_DOMAIN } from '@/plugins/veeValidate/rules';

export default {
  name: 'EmailForm',
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'submit'],
  data() {
    return {
      email: '',
      type: null,
    };
  },
  computed: {
    EMAIL_TYPES() {
      return EMAIL_TYPES;
    },
    isTypeIU() {
      return this.type === 'iu';
    },
    hasChanged() {
      return (
        this.modelValue.email !== this.email ||
        this.modelValue.type !== this.type
      );
    },
    initialValues() {
      if (!this.modelValue || Object.keys(this.modelValue).length <= 1) {
        return {
          emailType: '',
          email: '',
        };
      }

      return {
        emailType: this.modelValue.type,
        email: this.modelValue.email,
      };
    },
  },
  mounted() {
    const { email, type } = this.modelValue;
    this.type = type;

    if (email && this.isTypeIU) {
      this.email = email.replace(`@${IU_DOMAIN}`, '');
    } else {
      this.email = email;
    }
  },
  methods: {
    formatTimeStampToLocale,
    submit() {
      this.$emit('submit', {
        ...this.modelValue,
        type: this.type,
        email: this.isTypeIU ? `${this.email}@${IU_DOMAIN}` : this.email,
      });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style>
.iu-email-domain {
  position: absolute;
  height: 100%;
  max-height: 42px;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 16px;
  line-height: 50%;
  border-left: 1px solid #757b7b;
}
</style>
