import { Api } from '@/api';
import { locale } from '@/plugins/translations';

export default {
  namespaced: true,
  state: {
    studyPrograms: [],
  },
  getters: {
    studyPrograms(state) {
      return state.studyPrograms;
    },
  },
  actions: {
    async getStudyPrograms({ commit }) {
      const selectedLocale = locale.value;

      const { data } =
        await Api.courseCapabilities.getStudyPrograms(selectedLocale);

      commit('studyPrograms', data);
    },
  },
  mutations: {
    studyPrograms(state, studyPrograms) {
      state.studyPrograms = studyPrograms;
    },
  },
};
