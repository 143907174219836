<template>
  <n-modal
    :is-open="isOpen"
    :title="$t('common.labels.confirmation-required')"
    @close="$emit('close')"
  >
    <template #default>
      <p class="text-base">
        {{
          $t('views.location.delete-campus-confirmation-modal.note', { unit })
        }}
      </p>
    </template>
    <template #actions>
      <n-button variant="secondary" @click="$emit('close')">
        {{ $t('common.labels.abort') }}
      </n-button>
      <n-button
        class="danger"
        variant="primary"
        @click="$emit('onDeleteConfirmed', unit)"
      >
        {{
          $t(
            'views.location.delete-campus-confirmation-modal.delete-assignment'
          )
        }}
      </n-button>
    </template>
  </n-modal>
</template>

<script>
export default {
  name: 'DeleteLocationConfirmationModal',
  props: {
    unit: {
      type: [String, null],
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'onDeleteConfirmed'],
};
</script>
