<template>
  <deputation-section>
    <template #title>
      <div class="flex justify-between items-center gap-2">
        <span class="heading">Details</span>
        <deputation-detail-view-selector
          :view="selectedDetailView"
          @update:selected-view="onSelectGroupType"
        >
        </deputation-detail-view-selector>
      </div>
    </template>
    <template #content>
      <div v-if="detailLoading" class="flex flex-col items-center mt-12">
        <n-simple-loader />
        <p class="text-cta">Deputationsdaten werden geladen...</p>
      </div>
      <DeputationDetailGrid
        v-else
        :deputation-data="detailViewData"
        :group-type="selectedDetailView"
      />
    </template>
  </deputation-section>
</template>
<script>
import DeputationDetailGrid from '@/components/deputation/Deputation/Detail/DeputationDetailGrid.vue';
import { DeputationGroupType } from '@/constants/deputationGroupType';
import DeputationSection from '@/components/deputation/Common/DeputationSection.vue';
import DeputationDetailViewSelector from '@/components/deputation/Deputation/Detail/DeputationDetailViewSelector.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DeputationDetailSection',
  components: {
    DeputationDetailViewSelector,
    DeputationSection,
    DeputationDetailGrid,
  },
  computed: {
    ...mapGetters('deputation', [
      'detailViewData',
      'selectedDetailView',
      'detailLoading',
    ]),
    DeputationGroupType() {
      return DeputationGroupType;
    },
  },
  mounted() {
    this.fetchDetailData();
  },
  methods: {
    ...mapActions('deputation', ['setSelectedDetailView', 'fetchDetailData']),
    onSelectGroupType(groupType) {
      this.setSelectedDetailView(groupType);
    },
  },
};
</script>
